import React from "react";
import { Link } from "react-router-dom";

export default function StyledLink({
  className = "link--default",
  children,
  to,
}) {
  return (
    <Link to={to} className={`styled-link ${className}`}>
      {children}
    </Link>
  );
}
