import flattenDeep from "lodash/flattenDeep";
import slugify from "slugify";
import { CATEGORIES, REGIONS } from "./consts";
import FarmScreen from "../screens/farm";
import HomeScreen from "../screens/home";
import RegionScreen from "../screens/region";
import Map from "../screens/map";
import CookiesPolicyScreen from "../screens/cookies-policy";

const productsRoutes = Object.keys(REGIONS).map((key) => {
  return CATEGORIES.map((category) => {
    return {
      path: `/${slugify(REGIONS[key])}/ingredient/${category.id}`,
      component: FarmScreen,
      exact: true,
    };
  });
});

export default [
  {
    path: "/",
    component: HomeScreen,
    exact: true,
  },
  {
    path: "/cookies-policy",
    component: CookiesPolicyScreen,
    exact: true,
  },
  {
    path: "/map",
    component: Map,
    exact: true,
  },
  ...flattenDeep(productsRoutes),
  ...Object.keys(REGIONS).map((key) => {
    return {
      path: `/map/${slugify(REGIONS[key])}`,
      component: RegionScreen,
      exact: true,
    };
  }),
];
