import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import "./style/globals.scss";
import App from "./components/app";
import AppContextProvider from "./components/app-context-provider";

ReactDOM.render(
  <StrictMode>
    <AppContextProvider>
      <App />
    </AppContextProvider>
  </StrictMode>,
  document.getElementById("root")
);
