import React from "react";
import { Link } from "react-router-dom";
import { CATEGORIES, REGIONS } from "../config/consts";
import slugify from "slugify";

export default function RegionCategories({ categories, region = "" }) {
  return (
    <div className="categories">
      <div className="categories-inner">
        {categories?.map((_id, key) => {
          const category = CATEGORIES.find(({ id }) => id === _id);
          return (
            <Link
              to={`/${slugify(REGIONS[region])}/ingredient/${category.id}`}
              key={`KEY__${category.id}__${key}`}
              className={`category category-${category.id} active`}
            >
              <div className="button">
                <img
                  src={`/assets/category-${category.id}.svg`}
                  alt={category.label}
                />
              </div>
              <label>{category.label}</label>
            </Link>
          );
        }) ?? null}
      </div>
    </div>
  );
}
