import slugify from "slugify";
import { REGIONS } from "./consts";

const sectionFactory = (props) => {
  return {
    imageStyle: props.imageStyle ?? {},
    imageSrc: props.imageSrc || "/assets/background-placeholder.jpg",
    imageCaption: props.imageCaption,
    imageAsBackground:
      props.imageAsBackground === undefined ? true : props.imageAsBackground,
    content: props.content,
    sectionClassModifier: props.sectionClassModifier || "section--default",
  };
};

const regionFactory = (props) => {
  return {
    markerLocation: props.markerLocation ?? null,
    title: props.title,
    slug: `/map/${slugify(props.categoryName)}`,
    group: props.group || null,
    product: props.product || null,
    header: {
      title: props.header.title,
      description: props.header.description,
    },
    sections: props.sections.map(sectionFactory),
  };
};

export default [
  regionFactory({
    title:
      "Scotland:<br/><strong>Meet one of the farmers in your region that produces ingredients that go into some of McDonald’s favourites across the UK</strong>",
    product: "beef",
    group: 1,
    categoryName: "Scotland",
    header: {
      title: "What goes into our food?",
      description: `Nothing but quality ingredients. We’re proud to work with farmers right here in Scotland to source some of the quality produce that goes into McDonald’s delicious menu up and down the country.`,
    },
    banner: {
      title: "",
    },
    sections: [
      {
        imageStyle: {
          backgroundPosition: "50% 0%",
          minHeight: "670px",
        },
        imageSrc: "/assets/region-section-images/scott-aitken.jpg",
        imageAsBackground: true,
        content: `<p>Scott from West Lothian is one of the suppliers of the 100% British and Irish beef that goes into our iconic burgers.</p><p>Producing the beef that goes into our burgers requires hard work, know-how and an eye for detail.</p><p>That’s why we’re proud to work with over 20,000 British and Irish farmers who supply the beef on our UK menu.</p>`,
      },
      {
        imageStyle: {
          backgroundSize: "auto 90%",
        },
        imageSrc: `/assets/red_tractor_logo.jpg`,
        imageAsBackground: true,
        content: `<p>All our burgers are made from whole cuts of British and Irish beef from the forequarter and flank, and each farm we work with has been approved by a nationally recognised farm assurance scheme, such as Red Tractor.</p><p>Scott is responsible for running the farm alongside his brother, John, and father, Billy. The family-run business in West Lothian has been supplying McDonald’s for over 20 years and Scott has been a McDonald’s Flagship Farmer since 2013. Our Flagship Farmers Programme provides a way for farmers, producers and growers to share their knowledge and experiences in sustainability with other farmers around the world.</p>`,
      },
      {
        imageStyle: {
          backgroundPosition: "50% 12%",
          minHeight: "400px",
        },
        imageSrc: "/assets/more-lettuce.jpg",
        imageAsBackground: true,
        sectionClassModifier: "section--yellow",
        content: `<p>Scotland is also home to many other farmers and suppliers who provide the ingredients for some of our most loved McDonald’s menu items across the UK including:</p>`,
      },
    ],
  }),
  regionFactory({
    title: `Scotland:<br/><strong>Meet one of the farmers in your region that produces ingredients that go into some of McDonald’s favourites across the UK</strong>`,
    product: "pork",
    group: 2,
    categoryName: "Scotland",
    header: {
      title: "What goes into our food?",
      description: `Nothing but quality ingredients. We’re proud to work with farmers right here in Scotland to source some of the quality produce that goes into McDonald’s delicious menu.`,
    },
    banner: {
      title: "",
    },
    sections: [
      {
        imageSrc: "/assets/region-section-images/pork-section-0.jpg",
        imageAsBackground: true,
        content: `<p>Neill runs a farm in the Aberdeen and Elgin region, and is one of the suppliers of the 100% RSPCA Assured pork for the sausage patties in our McMuffin®.</p><p>We only serve 100% outdoor-bred RSPCA Assured pork across our menu (we were the first UK restaurant to do so!) and our farmers ensure that all their pigs have straw-bedded pens.</p>`,
      },
      {
        imageStyle: {
          minHeight: "500px",
        },
        imageSrc: `/assets/_BRP5506.jpg`,
        imageAsBackground: true,
        sectionClassModifier: "section--yellow section--column",
        content: `<p>Scotland is also home to many other farmers and suppliers who provide the ingredients for some of our most loved McDonald’s menu items including:</p>`,
      },
    ],
  }),
  regionFactory({
    title: `The North East:<br/><strong>Meet one of the farmers in your region that produces ingredients that go into some of the McDonald’s favourites across the UK</strong>`,
    categoryName: "The North East",
    product: "potatoes",
    group: 3,
    header: {
      description: `What goes into our food? Nothing but quality ingredients. We’re proud to work with farmers right here in the North East to source some of the quality produce that goes into McDonald’s delicious menu across the UK.`,
    },
    banner: {
      title: "Lorem ipsum",
    },
    sections: [
      {
        imageStyle: {
          backgroundPosition: "50% 0%",
        },
        imageSrc:
          "/assets/region-section-images/north-east-potatoes-section-0.jpg",
        content: `<p>Richard is based in the North East and is one of the suppliers of the potatoes for our world-famous Fries eaten at McDonald’s restaurants across the UK.</p><p>Richard’s farm is located in the North East and he has been working with McDonald’s to supply potatoes for over 40 years.</p><p>Richard is the third generation to run the family farm, and is committed to ensuring his potato enterprise is as sustainable as possible.</p>`,
      },
      {
        imageSrc:
          "/assets/region-section-images/north-east-potatoes-section-1.jpg",
        content: `<p>Using energy efficient potato storage is just one of the ways in which he does this, and his work saw him recognised as McDonald’s Outstanding Farmer of the Year in 2019.</p><p>On the farm, Richard grows Shepody, Innovator, Russet Burbank and Pentland Dell potatoes - four of the MacFry varieties which make our world-famous Fries.</p>`,
      },
      {
        sectionClassModifier: "section--yellow",
        imageAsBackground: true,
        imageSrc: "/assets/potatoes.jpg",
        content: `<p>The North East is also home to many other farmers and suppliers who provide the ingredients for some of our most loved menu items, including:</p>`,
      },
    ],
  }),
  regionFactory({
    title:
      "The North West: <br/><strong>Meet one of the farmers in your region that produces ingredients that go into some of the McDonald’s favourites across the UK</strong>",
    product: "eggs",
    categoryName: REGIONS.nw,
    group: 4,
    header: {
      title: "What goes into our food?",
      description: `We’re proud to work with farmers right here in the North West to source some of the quality produce that goes into McDonald’s delicious menu.`,
    },
    sections: [
      {
        imageSrc: "/assets/region-section-images/north-west-eggs-section-0.jpg",
        content: `<p>David and Helen are based in the North West and supply free-range eggs cracked every morning in some of McDonald’s restaurants up and down the UK.</p><p>David and Helen run The Lakes Free Range Egg Company in Cumbria. They are one of our longest standing egg suppliers and their hens have been laying free-range eggs for McDonald’s for over 17 years.</p>`,
      },
      {
        imageSrc: "/assets/region-section-images/north-west-eggs-section-1.jpg",
        content: `<p>At McDonald’s we only source our free-range eggs from UK farms that are RSPCA Assured approved and meet the Lion Egg Quality standards.</p><p>As well as their own farm, David and Helen work with other farmers to supply eggs to McDonald’s restaurants across the UK.</p>`,
      },
      {
        imageStyle: {
          backgroundPosition: "50% 40%",
        },
        imageSrc: "/assets/region-section-images/north-west-eggs-section-2.jpg",
        sectionClassModifier: "section--yellow",
        content: `<p>The North West is also home to many other farmers and suppliers who provide the ingredients for some of our most loved menu items, including:</p>`,
      },
    ],
  }),
  regionFactory({
    title: `The Midlands:<br/><strong>Meet one of the farmers in your region that produces ingredients that go into some of the McDonald’s favourites across the UK</strong>`,
    categoryName: "The Midlands",
    product: "potatoes",
    group: 5,
    header: {
      title: "What goes into our food?",
      description: `We’re proud to work with farmers right here in the Midlands to source some of the quality produce that goes into McDonald’s delicious menu up and down the country.`,
    },
    sections: [
      {
        imageSrc: "/assets/region-section-images/the-midlands-section-0.jpg",
        content: `<p>James is based in Staffordshire in the Midlands and  is one of the suppliers of the potatoes for our world-famous Fries eaten at McDonald’s restaurants across the UK.</p><p>James has been supplying the potatoes for our delicious Fries for over 34 years!</p><p>James is the fourth generation of his farming family, and has taken the reins at the farm which uses 292 acres to grow Ivory Russet and Russet Burbank potatoes - two of the MacFry varieties.</p>`,
      },
      {
        imageSrc: "/assets/region-section-images/the-midlands-section-1.jpg",
        sectionClassModifier: "section--yellow",
        content: `<p>The Midlands is also home to many other farmers and suppliers who provide the ingredients for some of our most loved menu items including:</p>`,
      },
    ],
  }),
  regionFactory({
    title:
      "The East of England:<br/><strong>Meet our local McDonald’s farmer</strong>",
    categoryName: "The East",
    product: "eggs",
    header: {
      title: "What goes into our food?",
      description:
        "We’re proud to work with<br/>farmers right here in the East of England</br>to source some of the quality, local produce</br> that goes into McDonald’s delicious menu.<br/>",
    },
    banner: {
      title: "Lorem ipsum",
    },
    sections: [
      {
        imageSrc: "/assets/region-section-images/the-east-section-0.jpg",
        content:
          "<p>Andrew is based in Norfolk and supplies the potatoes for our world-famous Fries to McDonald’s restaurants across the UK.</p> <p>Andrew runs an estate set along the Norfolk–Suffolk border, which covers a whopping 10,000 hectares of land in the heart of East Anglia.</p><p>Andrew is one of our Flagship Farmers, and has been working with McDonald’s to supply potatoes for over 30 years. Our Flagship Farmers Programme provides a way for farmers, producers and growers to share their knowledge and experiences in sustainability with other farmers around the world.</p><p>On the farm, Andrew grows Shepody and Russet Burbank potatoes - two of the MacFry varieties which make our world-famous Fries.</p>",
      },
      {
        imageSrc: "/assets/region-section-images/the-east-section-1.jpg",
        sectionClassModifier: "section--yellow",
        content: `<p>The East of England is also home to many other farmers and suppliers who provide the ingredients for some of our most loved menu items including:</p>`,
      },
    ],
  }),
  regionFactory({
    markerLocation: [44, 67],
    title: `The East of England:<br/><strong>Meet one of the farmers in your region that produces ingredients that go into some of the McDonald’s favourites across the UK</strong>`,
    categoryName: "The East",
    product: "potatoes",
    group: 6,
    header: {
      title: "What goes into our food?",
      description: `We’re proud to work with farmers right here in the East of England to source some of the quality produce that goes into McDonald’s delicious menu.`,
    },
    banner: {
      title: "Lorem ipsum",
    },
    sections: [
      {
        imageStyle: {
          backgroundPosition: "50% 0%",
        },
        imageSrc: "/assets/region-section-images/the-east-section-0.jpg",
        content: `<p>Andrew is based in Suffolk and is one of the suppliers of the potatoes for our world-famous Fries eaten at McDonald’s restaurants across the UK.</p><p>Andrew is Farms Director at Elveden Estate, which covers a whopping 10,000 hectares of land in the heart of East Anglia.</p><p>Andrew is a Flagship Farmer, and has been working with McDonald’s to supply potatoes for over 30 years. Our Flagship Farmers Programme provides a way for farmers, producers and growers to share their knowledge and experiences in sustainability with other farmers around the world.</p><p>On the farm, Andrew grows Shepody and Russet Burbank potatoes - two of the MacFry varieties which make our world-famous Fries.</p>`,
      },
      {
        imageSrc: "/assets/region-section-images/the-east-section-1.jpg",
        sectionClassModifier: "section--yellow",
        content: `<p>The East of England is also home to many other farmers and suppliers who provide the ingredients for some of our most loved menu items including:</p>`,
      },
    ],
  }),
  regionFactory({
    title: `Wales: <br/><strong>Meet one of the farmers in your region that produces ingredients that go into some of the McDonald’s favourites across the UK</strong>`,
    categoryName: "Wales",
    product: "beef",
    group: 8,
    header: {
      title: "What goes into our food?",
      description: `Nothing but quality ingredients. We’re proud to work with farmers right here in Wales to source some of the quality produce that goes into McDonald’s delicious menu.`,
    },
    banner: {
      title: "Lorem ipsum",
    },
    sections: [
      {
        imageStyle: {
          backgroundPosition: "50% 0%",
        },
        imageSrc: "/assets/region-section-images/wales-beef-section-0.jpg",
        content: `<p>Rowan’s farm is based in Powys and is one of the suppliers of the 100% British and Irish beef that goes into our iconic burgers.</p><p>Producing the beef that goes into our burgers requires hard work, know-how and an eye for detail. That’s why we’re proud to work with over 20,000 British and Irish farmers who supply the beef on our UK menu.</p>`,
      },
      {
        imageStyle: {
          backgroundSize: "auto 90%",
        },
        imageSrc: "/assets/red_tractor_logo.jpg",
        content: `<p>All our burgers are made from whole cuts of British and Irish beef from the forequarter and flank, and each farm we work with has been approved by a nationally recognised farm assurance scheme, such as Red Tractor.</p><p>Rowan runs a family farm business which has over 40 years of experience in the beef industry. Covering 685 acres, the farm is considered a centre of excellence when it comes to beef sustainability, with a focus on animal welfare and reducing its environmental footprint.</p>`,
      },
      {
        imageStyle: {
          backgroundPosition: "50% 80%",
        },
        imageSrc: "/assets/more-lettuce.jpg",
        sectionClassModifier: "section--yellow",
        content: `<p>Wales is also home to many other farmers and suppliers who provide the ingredients for some of our most loved McDonald’s menu items including:</p>`,
      },
    ],
  }),
  regionFactory({
    title: `The South West:<br/><strong>Meet one of the farmers in your region that produces ingredients that go into some of the McDonald’s favourites across the UK</strong>`,
    categoryName: "The South West",
    group: 7,
    product: "milk",
    header: {
      title: "What goes into our food?",
      description: `Nothing but quality ingredients. We’re proud to work with farmers right here in the South West to source some of the quality produce that goes into McDonald’s delicious menu.`,
    },
    sections: [
      {
        imageSrc: "/assets/region-section-images/south-west-section-0.jpg",
        content: `<p>Mike is based in the South West of England and is one of the suppliers of our semi-skimmed organic milk that is used in every frothy coffee, satisfying tea, warming porridge and Happy Meal® milk bottle.</p><p>Mike’s farm is based in Dorset. He began his farming career milking cows alongside his dad and brother at the age of 16, before taking over his first farm in 1989. Today, the family-run business is responsible for five separate organic dairy farms in the South West.</p>`,
      },
      {
        imageSrc: "/assets/Mike_T.jpg",
        content: `<p>Mike is a Flagship Farmer – a programme which provides a way for farmers, producers and growers to share their knowledge and experiences in sustainability with other farmers around the world.</p><p>We know the quality of our milk is important, which is why we only use semi-skimmed organic British milk in our coffee, tea , porridge and Happy Meal milk bottles. As the second biggest organic milk buyer in the UK, we care about the welfare of the dairy cows that our organic milk comes from, so every drop is sourced from Red Tractor Assured UK dairies – just like Mike’s.</p>`,
      },
      {
        imageSrc: "/assets/more-lettuce.jpg",
        sectionClassModifier: "section--yellow",
        content: `<p>The South West is also home to many other farmers and suppliers who provide the ingredients for some of our most loved menu items including:</p>`,
      },
    ],
  }),
  regionFactory({
    title: `Northern Ireland: <br/><strong>Meet one of the farmers in your region that produces ingredients that go into some of the McDonald’s favourites across the UK</strong>`,
    categoryName: "Northern Ireland",
    group: 9,
    product: "beef",
    header: {
      title: "What goes into our food?",
      description: `Nothing but quality ingredients. We’re proud to work with farmers right here in Northern Ireland to source some of the quality produce that goes into McDonald’s delicious menu.`,
    },
    sections: [
      {
        imageStyle: {
          backgroundPosition: "50% 0%",
        },
        imageSrc: `/assets/region-section-images/northen-ireland-beef-section-0.jpg`,
        imageCaption: `*Image captured during Ellen’s Progressive Young Farmer Placement while visiting FAI Farms`,
        content: `<p>Ellen, with her family in County Down, are one of the suppliers of the 100% British and Irish beef that goes into our iconic burgers.</p><p>Producing the beef that goes into our burgers requires hard work, know-how and an eye for detail.</p><p>That’s why we’re proud to work with over 20,000 British and Irish farmers who supply the beef on our UK menu.</p>`,
      },
      {
        imageStyle: {
          backgroundSize: "auto 90%",
        },
        imageSrc: `/assets/red_tractor_logo.jpg`,
        content: `<p>All our burgers are made from whole cuts of British and Irish beef from the forequarter and flank, and each farm we work with has been approved by a nationally recognised farm assurance scheme, such as Red Tractor.</p><p>Ellen has been supporting her dad on the farm since for years and currently works there part-time with him and her sister.</p>`,
      },
      {
        imageSrc: `/assets/region-section-images/northen-ireland-beef-section-2.jpg`,
        imageCaption: `*Image captured during Ellen’s Progressive Young Farmer Placement while visiting FAI Farms`,
        content: `<p>2019. The Programme involves spending a year with McDonald’s tracing every step of our supply chain to help young people take their first step towards a career in farming.</p><p>Mentored by an experienced farmer, our Progressive Young Farmers get an in-depth experience in sectors such as beef, pork, potatoes, dairy and poultry farming – as well as an understanding of the whole agricultural supply chain.</p>`,
      },
      {
        imageStyle: {
          backgroundPosition: "50% 80%",
        },
        imageSrc: "/assets/more-lettuce.jpg",
        sectionClassModifier: "section--yellow",
        content: `<p>Northern Ireland is also home to many other farmers and suppliers who provide the ingredients which make up some of our most loved menu items including:</p>`,
      },
    ],
  }),
  regionFactory({
    title: `Republic of Ireland: <br/><strong>Meet one of the farmers in your region that produces ingredients that go into some of the McDonald’s favourites across the UK & Ireland</strong>`,
    categoryName: "Republic of Ireland",
    product: "beef",
    group: 10,
    header: {
      title: "What goes into our food?",
      description: `Nothing but quality ingredients. We’re proud to work with farmers right here in the Republic of Ireland to source some of the quality produce that goes into McDonald’s delicious menu.`,
    },
    sections: [
      {
        imageStyle: {
          backgroundPosition: "50% 0%",
        },
        imageSrc:
          "/assets/region-section-images/republic-of-ireland-beef-section-0.jpg",
        content: `<p>John from County Waterford is one of the suppliers of the 100% British and Irish beef that goes into our iconic burgers.</p><p>Producing the beef that goes into our burgers requires hard work, know-how and an eye for detail.</p><p>That’s why we’re proud to work with over 20,000 British and Irish farmers who supply the beef on our UK menu. All our burgers are made from whole cuts of British and Irish beef from the forequarter and flank, and each farm we work with has been approved by a nationally recognised farm assurance scheme, such as Bord Bia.</p>`,
      },
      {
        imageSrc:
          "/assets/region-section-images/republic-of-ireland-beef-section-1.jpg",
        content: `<p>The farm has been in the family for five generations, with John and his wife Catherine now running the business, with help from their son.</p><p>John has been running his family’s farm for 30 years and supplying the quality Irish beef to McDonald’s since 2012!</p><p>John and Catherine’s farm is a member of the Bord Bia Beef Quality Assurance Scheme which means they focus on food safety, animal health and welfare and care for the environment.</p><p>It’s also a member of the Rural Environmental Protection Scheme (REPS) which ensures it is rooted in sustainable farming methods.</p>`,
      },
      {
        imageStyle: {
          backgroundSize: "contain",
          backgroundColor: "#ffffff",
          minHeight: "300px",
        },
        imageSrc: `/assets/region-section-images/republic-of-ireland-beef-section-2.jpg`,
        sectionClassModifier: "section--yellow",
      },
    ],
  }),
  // @note removed by client
  // regionFactory({
  //   title: "Republic of Ireland: Meet our local McDonald’s farmer",
  //   categoryName: "Republic of Ireland",
  //   product: "beef",
  //   group: 11,
  //   header: {
  //     title: "What goes into our food?",
  //     description:
  //       "Nothing but quality ingredients. We’re proud<br/> to work with farmers right here in the Republic of Ireland<br/> to source some of the quality, local produce<br/> that goes into McDonald’s delicious menu.<br/>",
  //   },
  //   sections: [
  //     {
  //       imageStyle: {
  //         backgroundPosition: "0% 0%",
  //       },
  //       imageSrc: "/assets/region-section-images/ie-john.jpg",
  //       content: `<strong>Eoghan from County Kerry who supplies the semi-skimmed organic milk that is used in every frothy coffee, satisfying tea, warming porridge and Happy Meal milk bottle.</strong> <p>Eoghan’s farm is located in County Kerry in the West of Ireland. He supplies McDonald’s restaurants across the UK and Ireland with the milk used for our teas, coffees, Happy Meal milk bottles and porridge.</p><p>Today the farm has 70 hectares of land in total – more than 130 football fields!</p>`,
  //     },
  //     {
  //       imageSrc: "/assets/region-section-images/ie-cows.jpg",
  //       content: `<p>Like many of our Flagship Farmers, Eoghan is an active member of the sustainable farming community and his farm is certified under the Bord Bia Sustainable Dairy Assurance Scheme.</p> <p>Eoghan’s farm produces high quality milk thanks to its care for the environment and its high animal welfare standards.  Eoghan also carefully manages his breeding programme to synchronise calving and grass growth, which allows the herd to produce milk mainly from a diet of grass.</p> <p>Eoghan has won the ICBF HerdPlus Best Dairy Herd for over 100 cows in 2019, the award recognises dairy farmers for best-in-class management and treatment of their herds.</p>`,
  //     },
  //     {
  //       imageStyle: {
  //         backgroundPosition: "50% 80%",
  //       },
  //       imageSrc: "/assets/region-section-images/ie-cow-field.jpg",
  //       sectionClassModifier: "section--yellow",
  //       content: `<p>The Republic of Ireland is a brilliant place for sourcing the beef used in our much-loved burgers and the semi-skimmed organic milk that is used in every frothy coffee, satisfying tea, warming porridge and Happy Meal milk bottle! So much so, that we work with many suppliers across the region to source the finest quality ingredients for your McDonald’s favourites.</p>`,
  //     },
  //   ],
  // }),
  regionFactory({
    title:
      "Republic of Ireland:<br/><strong>Meet our local McDonald’s farmer</strong>",
    categoryName: "Republic of Ireland",
    product: "milk",
    header: {
      title: "What goes into our food?",
      description:
        "Nothing but quality ingredients. We’re proud<br/>to work with farmers right here in the Republic of Ireland<br/>to source some of the quality, local produce<br/>that goes into McDonald’s delicious menu.<br/>",
    },
    sections: [
      {
        imageSrc:
          "/assets/region-section-images/republic-of-ireland-milk-section-0.jpg",
        content:
          "<strong>Eoghan from County Kerry who supplies the semi-skimmed organic milk that is used in every frothy coffee, satisfying tea, warming porridge and Happy Meal milk bottle.</strong><p>Eoghan’s farm is located in County Kerry in the West of Ireland. He supplies McDonald’s restaurants across the UK and Ireland with the milk used for our teas, coffees, Happy Meal milk bottles and porridge.</p><p>Today the farm has 70 hectares of land in total – more than 130 football fields!</p>",
      },
      {
        imageSrc:
          "/assets/region-section-images/republic-of-ireland-milk-section-1.jpg",
        content:
          "<p>Like many of our Flagship Farmers, Eoghan is an active member of the sustainable farming community and his farm is certified under the Bord Bia Sustainable Dairy Assurance Scheme.</p><p>Eoghan’s farm produces high quality milk thanks to its care for the environment and its high animal welfare standards.  Eoghan also carefully manages his breeding programme to synchronise calving and grass growth, which allows the herd to produce milk mainly from a diet of grass.</p><p>Eoghan has won the ICBF HerdPlus Best Dairy Herd for over 100 cows in 2019, the award recognises dairy farmers for best-in-class management and treatment of their herds.</p>",
      },
      {
        imageSrc:
          "/assets/region-section-images/republic-of-ireland-milk-section-2.jpg",
        sectionClassModifier: "section--yellow",
        content:
          "<p>The Republic of Ireland is a brilliant place for sourcing the beef used in our much-loved burgers and the semi-skimmed organic milk that is used in every frothy coffee, satisfying tea, warming porridge and Happy Meal milk bottle! So much so, that we work with many suppliers across the region to source the finest quality ingredients for your McDonald’s favourites.</p>",
      },
    ],
  }),
  regionFactory({
    title: `The South East: <br/><strong>Meet one of the farmers in your region that produces ingredients that go into some of the McDonald’s favourites across the UK</strong>`,
    categoryName: "The South East",
    group: 12,
    product: "beef",
    header: {
      title: "What goes into our food?",
      description: `Nothing but quality ingredients. We’re proud to work with farmers right here in the South East to source some of the quality produce that goes into McDonald’s delicious menu.`,
    },
    banner: {
      title: "Lorem ipsum",
    },
    sections: [
      {
        imageSrc: "/assets/region-section-images/the-south-east-section-0.jpg",
        content: `<p>Helen from Buckinghamshire is one of the suppliers of the 100% British and Irish beef that goes into our iconic burgers.</p><p>Producing the beef that goes into our burgers requires hard work, know-how and an eye for detail.</p>`,
      },
      {
        imageSrc: "/assets/region-section-images/the-south-east-section-1.jpg",
        content: `<p>That’s why we’re proud to work with over 20,000 British and Irish farmers who supply the beef on our UK menu. All our burgers are made from whole cuts of British and Irish beef from the forequarter and flank, and each farm we work with has been approved by a nationally recognised farm assurance scheme, such as Red Tractor.<p><p>Helen is a third-generation farmer and has spent the last six years working on the farm with her dad Roger in Buckinghamshire.</p>`,
      },
      {
        imageStyle: {
          minHeight: "300px",
          backgroundPosition: "50% 80%",
        },
        imageSrc: "/assets/more-lettuce.jpg",
        sectionClassModifier: "section--yellow",
        content: `<p>The South East of England is also home to many other farmers and suppliers who provide the ingredients for some of our most loved menu items including:</p>`,
      },
    ],
  }),
  regionFactory({
    title: `The South East: <br/><strong>Meet one of the farmers in your region that produces ingredients that go into some of the McDonald’s favourites across the UK</strong>`,
    categoryName: "The South East",
    group: 13,
    product: "lettuce",
    header: {
      title: "What goes into our food?",
      description: `Nothing but quality ingredients. We’re proud to work with farmers right here in the South East to source some of the quality produce that goes into McDonald’s delicious menu.`,
    },
    sections: [
      {
        imageSrc: `/assets/region-section-images/the-south-east-lettuce-section-0.png`,
        content: `<p>Stephen from Kent is one of the suppliers of our lettuce (where possible and when in season) found across our menu.</p><p>Stephen has been growing the lettuce that goes into our Big Flavour Wraps, freshly prepared salads and legendary Big Mac® for over a decade. He is the fourth generation of his family on the farm.</p>`,
      },
      {
        imageSrc:
          "/assets/region-section-images/the-south-east-lettuce-section-1.jpg",
        content: `<p>Where possible and when in season, our lettuce is sourced from the UK. Stephen has been growing Iceberg, Batavia and Apollo lettuce for McDonald’s for over 10 years. He is passionate about sustainable farming and is committed to reducing his farm’s carbon footprint by carefully managing its soil and water usage.</p>`,
      },
      {
        imageSrc: `/assets/more-lettuce.jpg`,
        sectionClassModifier: "section--yellow",
        content: `<p>The South East of England is also home to many other farmers and suppliers who provide the ingredients for some of our most loved menu items including:</p>`,
      },
    ],
  }),
];
