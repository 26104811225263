import React, { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import clone from "lodash";

export default function ScrollAnimation({
  children,
  className,
  delay,
  animClassName,
  viewPort,
  idSelector,
}) {
  const timeout = useRef(0);
  const collections = useRef([]);
  const windowOffsetBottom = useRef(0);

  const getOffsetTop = (element) => {
    let offsetTop = 0;
    while (element) {
      offsetTop += element.offsetTop;
      element = element.offsetParent;
    }
    return offsetTop;
  };

  const animate = useCallback(() => {
    const screen = document.querySelector(".screen");
    windowOffsetBottom.current = screen.clientHeight + screen.scrollTop;
    collections.current.forEach((elem, index) => {
      let elemOffsetBottom = viewPort + getOffsetTop(elem);
      if (
        elem.classList.contains("Animation--Done") ||
        elem.classList.contains(animClassName)
      ) {
        return;
      }
      if (getOffsetTop(elem) < windowOffsetBottom.current) {
        if (
          elemOffsetBottom - window.scrollY > 0 ||
          windowOffsetBottom.current > elemOffsetBottom
        ) {
          if (elem.classList.contains(animClassName)) {
            return;
          }
          timeout.current = setTimeout(
            () => {
              elem.classList.add(animClassName);
            },
            index === 0 ? 0 : delay * index
          );
          elem.addEventListener("animationend", (e) => {
            // collections.splice(0, index);
            e.currentTarget.removeAttribute("id");
            // e.currentTarget.classList.remove(animClassName);
            elem.classList.add("Animation--Done");
            clearTimeout(timeout.current);
          });
        }
      }
    });
  }, [timeout, animClassName, delay, viewPort]);

  const getElements = useCallback(() => {
    collections.current = clone(document.querySelectorAll(idSelector));
    animate();
  }, [collections, animate, idSelector]);

  useEffect(() => {
    getElements();
    document
      .querySelector(".app")
      .addEventListener("scroll", animate.bind(this), true);
  }, [getElements, animate]);

  useEffect(() => {
    return () => clearTimeout(timeout.current);
  }, [timeout]);

  return <div className={className}>{children}</div>;
}

ScrollAnimation.propTypes = {
  delay: PropTypes.number,
  animClassName: PropTypes.string,
  viewPort: PropTypes.number,
  idSelector: PropTypes.string,
};

ScrollAnimation.defaultProps = {
  delay: 100,
  viewPort: 80,
  animClassName: "fade-in-on-scroll",
  idSelector: "#fade-in-on-scroll",
};
