import React, { useContext } from "react";
import { useHistory } from "react-router";
import Screen from "../components/screen";
import { AppContext } from "../config/context";
import Button from "../components/button";
import StyledLink from "../components/styled-link";
import ScrollAnimation from "../components/ScrollAnimation";

export default function FarmScreen() {
  const history = useHistory();
  const { farm, findPageContent } = useContext(AppContext);
  const product = findPageContent();

  return (
    <Screen name="farm">
      <div className="container">
        <div className="content">
          <div className="info">
            <StyledLink to="/map">Back to Map</StyledLink>
          </div>
          <div className="divider" />
          <div className="hero" />
          {product?.header && (
            <>
              <ScrollAnimation className="header">
                <div className="header__icon-wrapper">
                  <div className="header__icon">
                    <img
                      id="fade-in-on-scroll"
                      className="header__icon-image"
                      src={product?.icon}
                      alt="UK map"
                    />
                  </div>
                </div>
                <h1 id="fade-in-on-scroll" className="header-title">
                  {product?.title}
                </h1>
              </ScrollAnimation>
              <ScrollAnimation className="intro">
                <div className="intro-content">
                  <h1
                    id="fade-in-on-scroll"
                    dangerouslySetInnerHTML={{ __html: product?.header?.title }}
                  />
                  <p
                    id="fade-in-on-scroll"
                    dangerouslySetInnerHTML={{
                      __html: product?.header?.description,
                    }}
                  />
                </div>
              </ScrollAnimation>
            </>
          )}
          {product?.sections && (
            <ScrollAnimation delay={50} className="farm-sections">
              {product.sections.map(
                (
                  {
                    imageSrc,
                    imageStyle,
                    content,
                    imageAsBackground,
                    sectionClassModifier,
                  },
                  key
                ) => {
                  return (
                    <div
                      key={key}
                      className={`farm-sections__section ${
                        sectionClassModifier ? sectionClassModifier : ""
                      }`}
                    >
                      <div
                        id="fade-in-on-scroll"
                        className="farm-sections__section-image"
                        style={{
                          ...imageStyle,
                          backgroundImage: `url(${
                            imageAsBackground ? imageSrc : null
                          })`,
                        }}
                      >
                        {!imageAsBackground && <img src={imageSrc} alt="" />}
                      </div>
                      <div className="farm-sections__section__content">
                        <div
                          id="fade-in-on-scroll"
                          className="farm-sections__section__content-text"
                          dangerouslySetInnerHTML={{ __html: content }}
                        />
                      </div>
                    </div>
                  );
                }
              )}
            </ScrollAnimation>
          )}
          {product?.footer && (
            <ScrollAnimation delay={10} className="farm-footer">
              <div className="farm-footer__content">
                <h1 id="fade-in-on-scroll">{product.footer.title}</h1>
                <div className="farm-footer__content-regions">
                  {product.footer?.regions &&
                    product.footer?.regions.map((lists, key) => {
                      return (
                        <ul key={`region__list__key__${key}`}>
                          {lists.map((region, key) => (
                            <li
                              id="fade-in-on-scroll"
                              key={`region__list__item__key__${key}`}
                            >
                              {region}
                            </li>
                          ))}
                        </ul>
                      );
                    })}
                </div>
              </div>
              <div className="farm-footer-map">
                <img
                  id="fade-in-on-scroll"
                  src={product.footer.mapSrc}
                  alt=""
                />
              </div>
              {product?.contact && (
                <div className="farm-footer__contact">
                  <div className="farm-footer__contact-image">
                    <img
                      id="fade-in-on-scroll"
                      src={product?.contact?.imageSrc}
                      alt=""
                    />
                  </div>
                  <div className="farm-footer__contact-details">
                    <h2 id="fade-in-on-scroll">{product?.contact?.title}</h2>
                    <Button
                      onClick={() => {
                        if (product?.contact?.linkHref) {
                          history.push(product?.contact?.linkHref);
                        }
                      }}
                    >
                      {product?.contact?.linkText}
                    </Button>
                  </div>
                </div>
              )}
            </ScrollAnimation>
          )}
          {farm?.video && (
            <div className="video">
              <video src={farm?.video} controls />
            </div>
          )}
          {farm?.dyk && (
            <div className="dyk">
              <div className="dyk-title">
                <h3 className="subtitle">Did you know?</h3>
                <p>{farm?.dyk}</p>
              </div>
            </div>
          )}
          <div className="divider" />
          <div className="farm-nav">
            <StyledLink className="link--gray" to="/map">
              Back to Map
            </StyledLink>
          </div>
        </div>
        <div className="legal-disclaimer">
          *Ingredients are sourced from more than 23,000 farmers, growers and
          suppliers across the UK and Ireland and subsequently distributed
          across the UK to make McDonald’s menu items served nationwide.
        </div>
      </div>
    </Screen>
  );
}
