import slugify from "slugify";
import { REGIONS } from "./consts";

let lastId = 0;

function createMarker(props) {
  const slug = slugify(
    REGIONS[props[props.regionSlug ? "regionSlug" : "productRegion"]]
  );
  return {
    id: ++lastId,
    zIndex: 1,
    ...props,
    slug,
  };
}

export default [
  /**
   * Regional farms
   */

  // Scotland
  createMarker({
    group: 1,
    produce: ["beef", "eggs", "pork", "potatoes"],
    category: "beef",
    region: "",
    regionSlug: "sco",
    regionId: "sco",
    position: [0.44, 0.2],
  }),
  createMarker({
    group: 1,
    produce: ["beef", "eggs", "pork", "potatoes"],
    category: "eggs",
    region: "",
    regionSlug: "sco",
    regionId: "sco",
    position: [0.47, 0.35],
  }),
  createMarker({
    group: 2,
    produce: ["beef", "eggs", "pork", "potatoes"],
    category: "pork",
    region: "",
    regionSlug: "sco",
    regionId: "sco",
    position: [0.63, 0.3],
  }),
  createMarker({
    group: 2,
    produce: ["beef", "eggs", "pork", "potatoes"],
    category: "potatoes",
    region: "",
    regionSlug: "sco",
    regionId: "sco",
    position: [0.57, 0.22],
  }),

  // North East
  createMarker({
    group: 3,
    produce: ["beef", "milk", "eggs", "pork", "potatoes"],
    category: "potatoes",
    region: "",
    regionSlug: "ne",
    regionId: "ne",
    position: [0.72, 0.49],
  }),
  createMarker({
    group: 3,
    produce: ["potatoes"],
    category: "beef",
    region: "",
    regionSlug: "ne",
    regionId: "ne",
    position: [0.68, 0.43],
  }),
  createMarker({
    group: 3,
    produce: ["potatoes"],
    category: "milk",
    region: "",
    regionSlug: "ne",
    regionId: "ne",
    position: [0.65, 0.47],
  }),
  createMarker({
    group: 3,
    produce: ["potatoes"],
    category: "eggs",
    region: "",
    regionSlug: "ne",
    regionId: "ne",
    position: [0.72, 0.46],
  }),
  createMarker({
    group: 3,
    produce: ["potatoes"],
    category: "lettuce",
    region: "",
    regionSlug: "ne",
    regionId: "ne",
    position: [0.69, 0.51],
  }),
  createMarker({
    group: 3,
    produce: ["potatoes"],
    category: "pork",
    region: "",
    regionSlug: "ne",
    regionId: "ne",
    position: [0.75, 0.52],
    zIndex: 2,
  }),

  // North West
  createMarker({
    group: 4,
    produce: ["beef", "milk", "eggs", "pork", "potatoes"],
    category: "eggs",
    region: "",
    regionSlug: "nw",
    regionId: "nw",
    position: [0.66, 0.64],
  }),
  createMarker({
    group: 4,
    produce: ["beef", "milk", "eggs", "pork", "potatoes"],
    category: "pork",
    region: "",
    regionSlug: "nw",
    regionId: "nw",
    position: [0.57, 0.53],
  }),
  createMarker({
    group: 4,
    produce: ["beef", "milk", "eggs", "pork", "potatoes"],
    category: "beef",
    region: "",
    regionSlug: "nw",
    regionId: "nw",
    position: [0.6, 0.56],
  }),
  createMarker({
    group: 4,
    produce: ["beef", "milk", "eggs", "pork", "potatoes"],
    category: "milk",
    region: "",
    regionSlug: "nw",
    regionId: "nw",
    position: [0.65, 0.6],
  }),
  createMarker({
    group: 4,
    produce: ["beef", "milk", "eggs", "pork", "potatoes"],
    category: "potatoes",
    region: "",
    regionSlug: "nw",
    regionId: "nw",
    position: [0.62, 0.64],
  }),

  // Midlands
  createMarker({
    group: 5,
    produce: ["beef", "milk", "eggs", "pork", "potatoes", "lettuce"],
    category: "potatoes",
    region: "",
    regionSlug: "midlands",
    regionId: "midlands",
    position: [0.8, 0.66],
  }),
  createMarker({
    group: 5,
    produce: ["beef", "milk", "eggs", "pork", "potatoes", "lettuce"],
    category: "pork",
    region: "",
    regionSlug: "midlands",
    regionId: "midlands",
    position: [0.66, 0.7],
  }),
  createMarker({
    group: 5,
    produce: ["beef", "milk", "eggs", "pork", "potatoes", "lettuce"],
    category: "beef",
    region: "",
    regionSlug: "midlands",
    regionId: "midlands",
    position: [0.72, 0.72],
  }),
  createMarker({
    group: 5,
    produce: ["beef", "milk", "eggs", "pork", "potatoes", "lettuce"],
    category: "eggs",
    region: "",
    regionSlug: "midlands",
    regionId: "midlands",
    position: [0.67, 0.78],
  }),
  createMarker({
    group: 5,
    produce: ["beef", "milk", "eggs", "pork", "potatoes", "lettuce"],
    category: "lettuce",
    region: "",
    regionSlug: "midlands",
    regionId: "midlands",
    position: [0.78, 0.77],
  }),
  createMarker({
    group: 5,
    produce: ["beef", "milk", "eggs", "pork", "potatoes", "lettuce"],
    category: "milk",
    region: "",
    regionSlug: "midlands",
    regionId: "midlands",
    position: [0.85, 0.72],
  }),

  // East of England
  createMarker({
    group: 6,
    produce: ["beef", "milk", "eggs", "pork", "potatoes", "lettuce"],
    category: "potatoes",
    region: "",
    regionSlug: "east",
    regionId: "east",
    position: [0.9, 0.84],
    zIndex: 2,
  }),
  createMarker({
    group: 6,
    produce: ["beef", "milk", "eggs", "pork", "potatoes", "lettuce"],
    category: "milk",
    region: "",
    regionSlug: "east",
    regionId: "east",
    position: [0.9, 0.75],
  }),
  createMarker({
    group: 6,
    produce: ["beef", "milk", "eggs", "pork", "potatoes", "lettuce"],
    category: "beef",
    region: "",
    regionSlug: "east",
    regionId: "east",
    position: [0.96, 0.73],
  }),
  createMarker({
    group: 6,
    produce: ["beef", "milk", "eggs", "pork", "potatoes", "lettuce"],
    category: "pork",
    region: "",
    regionSlug: "east",
    regionId: "east",
    position: [0.85, 0.78],
  }),
  createMarker({
    group: 6,
    produce: ["beef", "milk", "eggs", "pork", "potatoes", "lettuce"],
    category: "eggs",
    region: "",
    regionSlug: "east",
    regionId: "east",
    position: [0.95, 0.8],
    zIndex: 2,
  }),
  createMarker({
    group: 6,
    produce: ["beef", "milk", "eggs", "pork", "potatoes", "lettuce"],
    category: "lettuce",
    region: "",
    regionSlug: "east",
    regionId: "east",
    position: [0.9, 0.8],
  }),

  // South West
  createMarker({
    group: 7,
    produce: ["beef", "milk", "eggs", "pork", "potatoes"],
    category: "milk",
    region: "",
    regionSlug: "sw",
    regionId: "sw",
    position: [0.51, 0.92],
  }),
  createMarker({
    group: 7,
    produce: ["beef", "milk", "eggs", "pork", "potatoes"],
    category: "eggs",
    region: "",
    regionSlug: "sw",
    regionId: "sw",
    position: [0.44, 0.98],
  }),
  createMarker({
    group: 7,
    produce: ["beef", "milk", "eggs", "pork", "potatoes"],
    category: "beef",
    region: "",
    regionSlug: "sw",
    regionId: "sw",
    position: [0.61, 0.92],
  }),
  createMarker({
    group: 7,
    produce: ["beef", "milk", "eggs", "pork", "potatoes"],
    category: "pork",
    region: "",
    regionSlug: "sw",
    regionId: "sw",
    position: [0.69, 0.9],
  }),
  createMarker({
    group: 7,
    produce: ["beef", "milk", "eggs", "pork", "potatoes"],
    category: "potatoes",
    region: "",
    regionSlug: "sw",
    regionId: "sw",
    position: [0.66, 0.85],
  }),

  // Wales
  createMarker({
    group: 8,
    produce: ["beef", "milk", "eggs", "pork"],
    category: "beef",
    region: "",
    regionSlug: "wales",
    regionId: "wales",
    position: [0.48, 0.8],
  }),
  createMarker({
    group: 8,
    produce: ["beef", "milk", "eggs", "pork"],
    category: "milk",
    region: "",
    regionSlug: "wales",
    regionId: "wales",
    position: [0.55, 0.75],
  }),
  createMarker({
    group: 8,
    produce: ["beef", "milk", "eggs", "pork"],
    category: "eggs",
    region: "",
    regionSlug: "wales",
    regionId: "wales",
    position: [0.58, 0.82],
  }),
  createMarker({
    group: 8,
    produce: ["beef", "milk", "eggs", "pork"],
    category: "pork",
    region: "",
    regionSlug: "wales",
    regionId: "wales",
    position: [0.58, 0.68],
  }),

  // Northern Ireland
  createMarker({
    group: 9,
    produce: ["beef", "eggs"],
    category: "beef",
    region: "",
    regionSlug: "ni",
    regionId: "ni",
    position: [0.33, 0.52],
  }),
  createMarker({
    group: 9,
    produce: ["beef", "eggs"],
    category: "eggs",
    region: "",
    regionSlug: "ni",
    regionId: "ni",
    position: [0.25, 0.55],
  }),

  // Republic of Ireland
  createMarker({
    group: 10,
    produce: ["beef"],
    category: "beef",
    region: "",
    regionSlug: "ie",
    regionId: "ie",
    position: [0.25, 0.71],
  }),
  // @note removed by client
  // createMarker({
  //   group: 11,
  //   produce: ["beef", "milk"],
  //   category: "milk",
  //   region: "",
  //   regionSlug: "ie",
  //   regionId: "ie",
  //   position: [0.12, 0.81],
  // }),

  // South East
  createMarker({
    group: 13,
    produce: ["beef", "milk", "pork", "potatoes", "lettuce"],
    category: "lettuce",
    region: "",
    regionSlug: "se",
    regionId: "se",
    position: [0.9, 0.9],
  }),
  createMarker({
    group: 12,
    produce: ["beef", "milk", "pork", "potatoes", "lettuce"],
    category: "beef",
    region: "",
    regionSlug: "se",
    regionId: "se",
    position: [0.83, 0.91],
  }),
  createMarker({
    group: 12,
    produce: ["beef", "milk", "pork", "potatoes", "lettuce"],
    category: "potatoes",
    region: "",
    regionSlug: "se",
    regionId: "se",
    position: [0.8, 0.83],
  }),
  createMarker({
    group: 12,
    produce: ["beef", "milk", "pork", "potatoes", "lettuce"],
    category: "pork",
    region: "",
    regionSlug: "se",
    regionId: "se",
    position: [0.76, 0.9],
  }),
  createMarker({
    group: 12,
    produce: ["beef", "milk", "pork", "potatoes", "lettuce"],
    category: "milk",
    region: "",
    regionSlug: "se",
    regionId: "se",
    position: [0.96, 0.88],
  }),
];
