import React, { useCallback, useContext } from "react";
import Screen from "../components/screen";
import StyledLink from "../components/styled-link";
import { Link } from "react-router-dom";
import { AppContext } from "../config/context";

const COOKIE_INFO = {
  header: ["Cookie name", "Purpose", "Duration"],
  body: [
    {
      name: "_ga",
      purpose:
        '<strong>Analytics</strong>: A Google Analytics cookie. It registers a unique ID that is used to generate statistical data on the use of our Site. More information about Google Analytics can be found here:<br/> <a href="http://www.google.com/intl/en/analytics/privacyoverview.html">http://www.google.com/intl/en/analytics/privacyoverview.html</a>',
      duration: "Two years",
    },
    {
      name: "_gat",
      purpose:
        '<strong>Analytics</strong>: A Google Analytics cookie. It is used to throttle the request rate on our Site.<br/><p>More information about Google Analytics can be found here:<br/><a href="http://www.google.com/intl/en/analytics/privacyoverview.html">http://www.google.com/intl/en/analytics/privacyoverview.html</a></p>',
      duration: "1 minute.",
    },
    {
      name: "_gid",
      purpose:
        '<strong>Analytics</strong>: A Google Analytics cookie. It registers a unique ID that is used to generate statistical data on the use of our Site.<br/><p>More information about Google Analytics can be found here:<a href="http://www.google.com/intl/en/analytics/privacyoverview.html">http://www.google.com/intl/en/analytics/privacyoverview.html</a></p>',
      duration: "24 hours.",
    },
  ],
};

export default function CookiesPolicyScreen() {
  const { setOpenCookieSettings } = useContext(AppContext);
  const openCookieSettings = useCallback(() => {
    setOpenCookieSettings(true);
  }, [setOpenCookieSettings]);

  return (
    <Screen name="cookies-policy">
      <div className="container">
        <br />
        <br />
        <StyledLink className="link--gray" to="/map">
          Back to Map
        </StyledLink>
        <p>
          <strong>MapMyMcDonald’s Cookie Policy</strong>
        </p>
        <p>
          <strong>
            Last Updated <em>30th September 2020</em>
          </strong>
        </p>
        <p>
          <strong>What is this policy about?</strong>
        </p>
        <p>
          This Cookie Policy explains how we use “cookies” and similar
          technologies (such as “web beacons”, “pixels” or “tags”) on McDonald’s
          website at{" "}
          <a href="http://www.mapmymcdonalds.co.uk">www.mapmymcdonalds.co.uk</a>{" "}
          (“<strong>Site</strong>”). It is designed to help you make informed
          decisions when using our Site. Please take a moment to read and
          understand this Cookie Policy. This Cookie Policy should also be read
          in conjunction with our{" "}
          <a href="https://www.mcdonalds.com/gb/en-gb/privacy-policy.html">
            Privacy Statement
          </a>{" "}
          and our{" "}
          <a href="https://www.mcdonalds.com/gb/en-gb/terms-and-conditions.html">
            Terms and Conditions
          </a>
          .
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong>Contents</strong>
        </p>
        <ol>
          <li>
            <a href="#what-are-cookies" style={{ textDecoration: "none" }}>
              <strong>What are cookies?</strong>
            </a>
          </li>
          <li>
            <a
              href="#how-long-do-cookies-last"
              style={{ textDecoration: "none" }}
            >
              <strong>How long do cookies last?</strong>
            </a>
          </li>
          <li>
            <a
              href="#which-cookies-are-used-on-our-site"
              style={{ textDecoration: "none" }}
            >
              <strong>Which cookies are used on our Site?</strong>
            </a>
          </li>
          <li>
            <a
              href="#is-consent-needed-to-use-cookies"
              style={{ textDecoration: "none" }}
            >
              <strong>Is consent needed to use cookies?</strong>
            </a>
          </li>
          <li>
            <a
              href="#how-do-i-refuse-block-or-delete-cookies"
              style={{ textDecoration: "none" }}
            >
              <strong>How do I refuse, block or delete cookies?</strong>
            </a>
          </li>
          <li>
            <a
              href="#what-about-google-chrome-cookies"
              style={{ textDecoration: "none" }}
            >
              <strong>What about Google Chrome cookies?</strong>
            </a>
          </li>
          <li>
            <a
              href="#does-this-policy-change"
              style={{ textDecoration: "none" }}
            >
              <strong>Does this policy change?</strong>
            </a>
          </li>
          <li>
            <a
              href="#have-any-feedback-for-us"
              style={{ textDecoration: "none" }}
            >
              <strong>Have any feedback for us?</strong>
            </a>
          </li>
        </ol>
        <p id="what-are-cookies">
          <br />
        </p>
        <span className="heading">
          1. <strong>What are cookies?</strong>
        </span>
        <p>
          Cookies are very small text files or pieces of code, which often
          include a unique identifier. When you visit a website, the website
          sends information to your computer or mobile device and creates a text
          file that is then stored on your computer or mobile device.
          Information gathered through cookies may include the date and time of
          visits and show how people use the particular website or mobile
          application, usually so the website operator can make improvements.
          Amongst other things, cookies are also used to help you access a
          website and its content as efficiently as possible (by remembering you
          from your last visit) and to make sure you are presented with
          advertising that is relevant to you.
        </p>
        <p>
          Similar to cookies are technologies known as “web beacons”, “pixels or
          “tags”. These technologies do a similar job to cookies, allowing
          website operators to count page views and understand how visitors
          interact with and respond to certain content.
        </p>
        <p>
          For the purposes of the remainder of this Cookie Policy, we refer to
          cookies and the other technologies described above as “cookies”.
        </p>
        <p>
          We use “first party cookies” and “third party cookies”. First party
          cookies are cookies placed by us. Third party cookies are cookies
          placed by others. Information collected by third party cookies will be
          shared with the relevant third party. Please refer to the relevant
          third party’s privacy notice for more details about the information
          they collect and how they use it.
        </p>
        <p>
          Please note that our Site may link to third party websites which are
          not operated by us. Where you follow links from our Site to the
          website or digital property of a third party, that website or digital
          property may place different cookies on your device. You should check
          the relevant privacy notice and/or cookie notice for more information
          about how that third party uses cookies.
        </p>
        <p>
          You should be aware that applications you use to access our Site, such
          as your web browser, may also place cookies on your device when
          visiting our Site, or other websites. An example of this would be
          where you sign in to Google Chrome using a Google Account (see Section
          6 below). We have no control over these third party cookies, so you
          will need to manage these cookies in the settings of the relevant
          applications.
        </p>
        <p>
          For more general information on cookies and how they work, please
          visit <a href="http://www.allaboutcookies.org/">All About Cookies</a>.
        </p>
        <p id="how-long-do-cookies-last">
          <br />
        </p>
        <span className="heading">
          2. <strong>How long do cookies last?</strong>
        </span>
        <p>
          The lifespan of a cookie depends on its purpose and how you interact
          with it:
        </p>
        <p>
          <em>Session Cookies</em>– these are temporary cookies that are placed
          on your device during your browsing session and then automatically
          erased when you close your browser.
        </p>
        <p>
          <em>Persistent Cookies</em>– these are designed to last for a
          pre-defined period of time, which varies depending on the nature of
          the cookie. Persistent cookies stay in your browser until they reach
          their individual expiry date or you manually delete them (see Section
          5 below).
        </p>
        <p id="which-cookies-are-used-on-our-site">
          <br />
        </p>
        <span className="heading">
          3. <strong>Which cookies are used on our Site?</strong>
        </span>
        <p>We use the following types of cookies on the Site:</p>
        <p>
          <em>Essential Cookies/Strictly Necessary Cookies</em>– These cookies
          are those cookies which a website could not operate without. These
          include cookies such as login cookies and shopping cart cookies.
        </p>
        <p>
          <em>Functional Cookies</em>– These cookies recognise you when you
          return to a website, remember your preferences and provide enhanced,
          more personalised features.
        </p>
        <p>
          <em>Analytics/Performance Cookies</em>– Performance cookies, along
          with other information, allow websites to calculate the aggregate
          number of people using a website and which features of a website are
          the most popular. This information is generally used to improve the
          website and the way visitors are able to move around it.
        </p>
        {/*table here*/}
        <div className="table-wrapper">
          <p>
            <em>
              The table below sets out more information about the individual
              cookies we use on the Site and the purposes for which they are
              used
            </em>
          </p>
          <table border="0" cellSpacing="0">
            <thead className="table-wrapper__table-head">
              <tr>
                {COOKIE_INFO.header.map((name, key) => {
                  return (
                    <th className="cookie-detail__table-th" key={key}>
                      {name}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={3}>
                  Third Party Cookies
                </td>
              </tr>
              {COOKIE_INFO.body.map(({ name, purpose, duration }, key) => {
                return (
                  <tr key={key}>
                    <td>{name}</td>
                    <td dangerouslySetInnerHTML={{ __html: purpose }} />
                    <td>{duration}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <p id="is-consent-needed-to-use-cookies">
          <br />
        </p>
        <span className="heading">
          4. <strong>Is consent needed to use cookies?</strong>
        </span>
        <p>
          <em>Essential/Strictly Necessary Cookies –</em>
          We do not need your consent to store and/or access essential cookies
          on your device, although you can still block or delete them (see
          Section 5 below).
        </p>
        <p>
          <em>Other (“non-essential”) Cookies –</em>
          We may only store and/or access non-essential cookies on your device
          with your permission. You are not obliged to give consent to our use
          of non-essential cookies. If you do give your consent and then change
          your mind, you can still delete any cookies that have already been
          placed (see Section 5 below).
        </p>
        <p id="how-do-i-refuse-block-or-delete-cookies">
          <br />
        </p>
        <span className="heading">
          5. <strong>How do I refuse, block or delete cookies?</strong>
        </span>
        <p>
          There are various ways that you can manage your cookie preferences but
          please be aware that in order to use some parts of our Site you will
          need to allow certain essential or functional cookies. If you block or
          subsequently delete those cookies, some aspects of our Site may not
          work properly and you may not be able to access all or part of our
          Site.
        </p>
        <p>
          Your options for managing your cookie preferences are set out below:
        </p>
        <p>
          <em>Through the Preference Centre on our Site</em>
        </p>
        <p>
          When you access our Site, we will ask you to provide consent for our
          use of non-essential cookies. We may repeat this request on subsequent
          visits to our Site, for example if you delete cookies from your
          browser or we need to request new consents from you.
        </p>
        <p>
          When we seek your consent, you will either be able to provide consent
          for all cookies that we would like to use, or you can tailor your
          cookie preferences (provide consent for some but not all non-essential
          cookies) using our Preference Centre.
        </p>
        <p>
          You can also change your preferences and/or withdraw your consent at
          any time by visiting our Preference Centre. However, please be aware
          that withdrawing your consent does not remove the cookie from your
          device and, in the case of Social Media Cookies and Targeting Cookies
          (see Section 3 above), the cookie may still be used by third parties
          to show you personalised content and/or advertisements. To delete the
          cookie from your device and prevent the data from the Cookie being
          used in this way, please see{" "}
          <em>Using your browser settings or other tools</em>
          <em> </em>below.
        </p>
        <p>
          Where cookies are essential to the operation of our Site, we can use
          these without your consent and they cannot be managed using our
          Preference Centre.
        </p>
        <p>
          <em>Using your browser settings or other tools</em>
        </p>
        <p>
          You can block the use of cookies generally (not just in respect of our
          Site) by activating the relevant settings in your browser. For more
          information on cookie management and blocking or deleting cookies for
          a wide variety of browsers, visit{" "}
          <a href="http://www.allaboutcookies.org">All About Cookies</a>.
        </p>
        <p>
          Some cookies are dropped by third parties who operate the browser or
          application through which you access the Site (such as Google Chrome –
          see Section 6 below). We do not have control of this and you will need
          to manage those cookie settings through the relevant third party
          application.
        </p>
        <p>
          If you do not want to be tracked by Google Analytics, you can install
          the{" "}
          <a href="https://tools.google.com/dlpage/gaoptout?hl=en">
            Google Analytics opt-out browser add-on
          </a>{" "}
          (but this will only work on certain browsers). For further information
          on Google Analytics and its use of cookies, please consult the{" "}
          <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">
            Google Analytics cookie policy
          </a>
          .
        </p>
        <p id="what-about-google-chrome-cookies">
          <br />
        </p>
        <span className="heading">
          6. <strong>What about Google Chrome cookies?</strong>
        </span>
        <p>
          Separately to our use of Google Analytics cookies, when you access the
          Site via Google applications such as Google Chrome, Google may collect
          user information for the purpose of Google serving personalised
          advertising across your devices. Google is able place these
          advertising cookies on the devices used by visitors to the Site
          because we use Google services to run the Site. However these cookies
          are dropped for Google’s own purposes and as such we do not have any
          control over how or when they are dropped. You will need to opt out of
          receiving these cookies via your Google settings. For further
          information about these cookies please see:{" "}
          <a href="https://policies.google.com/technologies/types?hl=en-US">
            https://policies.google.com/technologies/types?hl=en-US
          </a>
          . Please note that other browsers may use similar tools.
        </p>
        <p id="does-this-policy-change">
          <br />
        </p>
        <span className="heading">
          7. <strong>Does this policy change?</strong>
        </span>
        <p>
          We may update our Cookie Policy from time to time. This might be for a
          number of reasons, such as to reflect a change in the law or to
          accommodate a change in our business practices and the way we use
          cookies. For example, it is not currently our policy to enable
          purchases of merchandise online via our websites, but if this policy
          were to change we would wish to use cookies to keep track of your
          online purchases, to keep your purchases secure and to assist you with
          making them (for example using a shopping cart cookie). We recommend
          that you check here periodically for any changes to our Cookie Policy.
        </p>
        <p id="have-any-feedback-for-us">
          <br />
        </p>
        <span className="heading">
          8. <strong>Have any feedback for us?</strong>
        </span>
        <p>
          If you have any questions or feedback on our Cookie Policy, please
          contact us at{" "}
          <a href="mailto:customerfeedback@mcdonalds.co.uk.">
            customerfeedback@mcdonalds.co.uk.
          </a>
        </p>

        <div className="disclaimer">
          <div className="disclaimer-actions">
            <div onClick={openCookieSettings}>Cookie Settings</div>
            &nbsp;&nbsp; <Link to="/cookies-policy">Cookie Policy</Link>
            &nbsp;&nbsp;{" "}
            <a
              href="https://www.mcdonalds.com/gb/en-gb/privacy-policy.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Statement
            </a>
            &nbsp;&nbsp;{" "}
            <a
              href="https://www.mcdonalds.com/gb/en-gb/terms-and-conditions.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms & Conditions
            </a>
          </div>
        </div>
      </div>
    </Screen>
  );
}
