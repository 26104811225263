export const CATEGORIES = [
  {
    id: "all",
    label: "All",
  },
  {
    id: "eggs",
    label: "Eggs",
  },
  {
    id: "milk",
    label: "Milk",
  },
  {
    id: "beef",
    label: "Beef",
  },
  {
    id: "pork",
    label: "Pork",
  },
  {
    id: "potatoes",
    label: "Potatoes",
  },
  {
    id: "lettuce",
    label: "Lettuce",
  },
];

export const REGIONS = {
  ie: "Republic of Ireland",
  ni: "Northern Ireland",
  sco: "Scotland",
  se: "The South East",
  sw: "The South West",
  midlands: "The Midlands",
  york: "Yorkshire & Humber",
  ne: "The North East",
  nw: "The North West",
  wales: "Wales",
  east: "The East",
};

export const SELECTED_REGION_TEXT = {
  ie: `Select a pin to find out more about the ingredients McDonald’s sources from the Republic of Ireland that that help make some of the iconic favourites enjoyed in restaurants across the UK & Ireland`,
  ni: `Select a pin to find out more about the ingredients McDonald’s sources from Northern Ireland that help make some of the iconic favourites enjoyed in restaurants across the UK`,
  sco: `Select a pin to find out more about the ingredients McDonald’s sources from Scotland that help make some of the iconic favourites enjoyed in restaurants across the UK`,
  se: `Select a pin to find out more about the ingredients McDonald’s sources from  The South East that help make some of the iconic favourites enjoyed in restaurants across the UK`,
  sw: `Select a pin to find out more about the ingredients McDonald’s sources from  The South West that help make some of the iconic favourites enjoyed in restaurants across the UK`,
  midlands: `Select a pin to find out more about the ingredients McDonald’s sources from The Midlands that help make some of the iconic favourites enjoyed in restaurants across the UK`,
  york: ``,
  ne: `Select a pin to find out more about the ingredients McDonald’s sources from the North East that help make some of the iconic favourites enjoyed in restaurants across the UK`,
  nw: `Select a pin to find out more about the ingredients McDonald’s sources from  The North West that help make some of the iconic favourites enjoyed in restaurants across the UK`,
  wales: `Select a pin to find out more about the ingredients McDonald’s sources from  Wales that help make some of the iconic favourites enjoyed in restaurants across the UK`,
  east: `Select a pin to find out more about the ingredients McDonald’s sources from The East of England that help make some of the iconic favourites enjoyed in restaurants across the UK`,
};

export const MODE = process?.env?.NODE_ENV ?? "production";

export const MAP_ZOOM = 2.5;

export const REGION_HERO_TITLES = {
  ie: `Click the icon to the left to meet our local McDonald’s beef farmer.`,
  ni: `Click the icon to the left to meet our local McDonald’s beef farmer.`,
  sco: `Click the icons to the left to meet our local McDonald’s beef and pork farmers.`,
  se: `Click the icons to the left to meet our local McDonald’s beef and lettuce farmers.`,
  sw: `Click the icon to the left to meet our local McDonald’s milk farmer.`,
  midlands: `Click the icon to the left to meet our local McDonald’s potato farmer.`,
  ne: `Click the icon to the left  to meet our local McDonald’s potato farmer.`,
  nw: `Click the icon to the left to meet our local McDonald’s egg farmer.`,
  wales: `Click the icon to the left  to meet our local McDonald’s beef farmer.`,
  east: `Click the icon to the left to meet our local McDonald’s potato farmer.`,
};

export const REGION_MARKER_LOCATIONS = {
  ni: [27, 28],
  ie: [40, 24],
  sco: [10, 45],
  ne: [22, 54],
  nw: [27, 48],
  midlands: [40, 55],
  wales: [41, 47],
  sw: [52, 46],
  se: [50, 64],
  east: [42, 66],
};

export const LIVE = Date.now() >= new Date(2020, 9, 5).getTime(); // Live date
// export const LIVE = Date.now() >= new Date(2020, 9, 2, 15, 15).getTime(); // UAT test
