import React, { Fragment, useContext } from "react";
import Screen from "../components/screen";
import { AppContext } from "../config/context";
import StyledLink from "../components/styled-link";
import { useLocation } from "react-router";
import RegionCategories from "../components/region-categories";
import ScrollAnimation from "../components/ScrollAnimation";
import { REGION_MARKER_LOCATIONS } from "../config/consts";

export default function RegionScreen() {
  const { findPageContent } = useContext(AppContext);
  const location = useLocation();
  const search = location.search.split("=");
  const product = findPageContent(
    search[search.length - 1],
    `${location.pathname}`
  );
  if (!product) {
    return "Not Found";
  }
  const markerLocation = product?.markerLocation ??
    REGION_MARKER_LOCATIONS[product.region] ?? [0, 0];
  return (
    <Screen name="region">
      <div className="container">
        <div className="content">
          <div className="info">
            <StyledLink to="/map">Back to Map</StyledLink>
          </div>
          <div className="divider" />
          {product?.header && (
            <>
              <ScrollAnimation className="header">
                <h1
                  className="header-title"
                  id="fade-in-on-scroll"
                  dangerouslySetInnerHTML={{ __html: product?.title }}
                />
                <div className="header__icon-placeholder" />
                <div className="header__icon-wrapper">
                  <div
                    className={`header__icon ${product.regionId}-${product.product}`}
                  >
                    <img
                      className="header__icon-image"
                      src="/assets/regions/map.svg"
                      alt=""
                    />
                    <div
                      className="header__icon-marker"
                      style={{
                        top: `${markerLocation[0] ?? 0}%`,
                        left: `${markerLocation[1] ?? 0}%`,
                      }}
                    >
                      <div className="inner">
                        <div className="produce">
                          <img
                            src={`/assets/category-${product.product}.svg`}
                            alt=""
                          />
                        </div>
                        <img
                          className="marker"
                          src="/assets/marker.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </ScrollAnimation>
              <ScrollAnimation className="intro">
                <div className="intro-content">
                  <h1
                    id="fade-in-on-scroll"
                    dangerouslySetInnerHTML={{ __html: product?.header?.title }}
                  />
                  <p
                    id="fade-in-on-scroll"
                    dangerouslySetInnerHTML={{
                      __html: product?.header?.description,
                    }}
                  />
                </div>
              </ScrollAnimation>
            </>
          )}
          {product?.sections && (
            <ScrollAnimation delay={50} className="farm-sections">
              {product.sections.map(
                (
                  {
                    imageStyle,
                    imageSrc,
                    imageCaption,
                    content,
                    imageAsBackground,
                    sectionClassModifier,
                  },
                  key
                ) => {
                  return (
                    <Fragment key={key}>
                      <div
                        className={`farm-sections__section ${
                          sectionClassModifier ? sectionClassModifier : ""
                        }`}
                      >
                        <div
                          id="fade-in-on-scroll"
                          className="farm-sections__section-image"
                          style={{
                            ...imageStyle,
                            backgroundImage: `url(${
                              imageAsBackground ? imageSrc : null
                            })`,
                          }}
                        >
                          {!imageAsBackground && <img src={imageSrc} alt="" />}
                          {imageCaption && (
                            <div
                              className="image-caption"
                              dangerouslySetInnerHTML={{ __html: imageCaption }}
                            />
                          )}
                        </div>
                        <div className="farm-sections__section__content">
                          <div
                            id="fade-in-on-scroll"
                            className="farm-sections__section__content-text"
                            dangerouslySetInnerHTML={{ __html: content }}
                          />
                        </div>
                      </div>
                      {key === product.sections.length - 1 && (
                        <RegionCategories
                          region={product.region}
                          categories={product.produce}
                          className="region-categories"
                        />
                      )}
                    </Fragment>
                  );
                }
              )}
            </ScrollAnimation>
          )}
          <div className="divider" />
          <div className="region-nav">
            <StyledLink className="link--gray" to="/map">
              Back to Map
            </StyledLink>
          </div>
        </div>
        <div className="legal-disclaimer">
          *Ingredients are sourced from more than 23,000 farmers, growers and
          suppliers across the UK and Ireland and subsequently distributed
          across the UK to make McDonald’s menu items served nationwide.
        </div>
      </div>
    </Screen>
  );
}
