import { REGIONS } from "./consts";
import flattenDeep from "lodash/flattenDeep";
import slugify from "slugify";

const sectionFactory = (props) => {
  return {
    imageStyle: props.imageStyle ?? {},
    imageSrc: props.imageSrc,
    imageAsBackground:
      props.imageAsBackground === undefined ? true : props.imageAsBackground,
    content: props.content,
    sectionClassModifier: props.sectionClassModifier || "section--default",
  };
};

const productPageFactory = (props) => {
  return Object.keys(REGIONS).map((key) => {
    return {
      slug: `/${slugify(REGIONS[key])}/ingredient/${props.category}`,
      classNameModifier: props.classNameModifier || null,
      category: props.category || null,
      title: props.title,
      icon: `/assets/products/category-${props.category}.svg`,
      header: {
        title: props.header?.title,
        description: props.header?.description,
      },
      sections: props.sections.map(sectionFactory),
      contact: props.contact
        ? {
            imageSrc: props.contact?.imageSrc,
            title: props.contact?.title,
            linkHref: props.contact?.linkHref,
            linkText: props.contact?.linkText,
          }
        : null,
      footer: props.footer,
    };
  });
};

export default flattenDeep([
  productPageFactory({
    category: "eggs",
    title: "Free-range Eggs",
    icon: "/assets/category-eggs.svg",
    header: {
      title: `What’s cracking?`,
      description: `Our <em>egg-cellent</em> RSPCA Assured free-range eggs cracked in our restaurants every morning, that’s what!`,
    },
    sections: [
      {
        imageSrc: "/assets/20180910MCD1759.jpg",
        sectionClassModifier: "section--gray background-yellow",
        content: `<h1>20 Years</h1><p>In our restaurants across the UK, all the eggs we crack for our breakfast menu are free-range, and we’ve been using these for over 20 years.</p>`,
      },
      {
        imageSrc: "/assets/20180910MCD2000.jpg",
        sectionClassModifier: "section--gray",
        content: `<p>We’ve been recognised for our free-range eggs and are proud to have won a Good Farm Animal Welfare Award for them in 2015.</p>`,
      },
      {
        imageSrc: "/assets/rspca-certification.jpg",
        imageAsBackground: false,
        content: `<p>All our free-range eggs come from UK farms that are RSPCA Assured and we’re proud to work with farmers that are committed to looking after their hens.</p>`,
      },
      {
        imageSrc: "/assets/20180910MCD1800.jpg",
        sectionClassModifier: "section--green",
        content: `<p>We are committed to looking after our hens, which is why over the last 10 years, we’ve planted over half a million trees across the UK to provide cover for them. We know that chickens are naturally concerned about overhead predators, and trees create protection for them, which helps improve hen wellbeing and egg quality. Through the Range Enrichment Programme, all our egg farmers have planted a minimum of 20% tree cover on each farm.</p>`,
      },
    ],
    footer: {
      title: `We’re proud to say that the UK and Ireland is home to many farmers that supply our free-range eggs – from top to bottom and everywhere in between!`,
      mapSrc: "/assets/egs-map.svg",
      regions: [
        ["South West", "South East", "North East", "North West", "Midlands"],
        ["East of England", "Scotland", "Wales", "Northern Ireland"],
      ],
    },
    contact: {
      imageSrc: "/assets/biggest-balls-supplier.png",
      title: "Meet McDonald’s longest standing egg supplier in the UK",
      linkHref: "/map/The-North-West?group=4",
      linkText: "Find Out More",
    },
  }),
  productPageFactory({
    title: "Beef",
    category: "beef",
    icon: "/assets/category-beef.svg",
    header: {
      title: `Have you <em>herd</em> about <br/>our British and Irish beef?`,
    },
    sections: [
      {
        sectionClassModifier: "section--gray text--black",
        content:
          "<p>We use</p><h1>100% British and Irish</h1><p> beef in our iconic burgers, which comes from whole cuts of forequarter and flank.</p>",
        imageSrc: "/assets/cows.jpg",
      },
      {
        imageSrc: "/assets/side-by-side-logos.jpg",
        sectionClassModifier: "section--yellow description--white",
        content: `<p>Over 20,000 British and Irish farmers supply the quality beef for our restaurants, and all of their farms are accredited by a nationally recognised farm assurance scheme, such as Red Tractor or Bord Bia.</p>`,
      },
      {
        imageSrc: "/assets/land-with-cows.jpg",
        content: `<p>We are deeply committed to the health and welfare of the cattle within our supply chain, as well as using our scale for good to help improve beef sustainability.</p><p>As a founding member of the European Roundtable for Beef Sustainability (ERBS), we are working with both farmers and industry experts towards eight key sustainability goals.</p>`,
      },
      {
        content: `<p>We’re proud to have been recognised for the work we’ve done already – such as being shortlisted in the BBC Future Food Awards for our Beef Carbon project in 2016, which looked at ways to reduce the carbon footprint within our beef supply chain, however we know there is more we can do.</p>`,
        sectionClassModifier: "section--green",
        imageSrc: "/assets/more-lettuce.jpg",
      },
    ],
    footer: {
      title: `Over the years, we’ve established relationships with a huge network of trusted beef farmers across the UK and Ireland!`,
      regions: [
        ["South East", "South West", "North East", "North West", "Midlands"],
        [
          "East of England",
          "Scotland",
          "Wales",
          "Northern Ireland",
          "Republic of Ireland",
        ],
      ],
      mapSrc: "/assets/beef-map.svg",
    },
  }),
  productPageFactory({
    title: "Organic Milk",
    category: "milk",
    icon: "/assets/category-milk.svg",
    header: {
      title: `We want to tell you what makes<br/>our milk <em>udderly</em> great!`,
    },
    sections: [
      {
        imageStyle: {
          backgroundPosition: "50% 40%",
        },
        sectionClassModifier: "section--gray text--black",
        content: `<p>The delicious semi-skimmed milk we use in our teas, coffees, porridge and Happy Meal® milk bottles is organic.</p>`,
        imageSrc: "/assets/20180911MCD2899.jpg",
      },
      {
        imageSrc: "/assets/red-tractor-certification-standards.jpg",
        sectionClassModifier: "section--yellow description--white",
        content:
          "<p>We care about the welfare of the dairy cows that our organic milk  comes from, which is why all our organic milk is sourced from Red Tractor Assured UK dairies.</p>",
      },
      {
        imageSrc: "/assets/Arla046.jpg",
        content:
          "<p>We are proud to have been commended by Compassion in World Farming as a result of our five-year commitment to ensure the welfare of the cows supplying our milk.</p>",
      },
      {
        content:
          "<p>We also work hard to make sure the organic milk we use is sustainably sourced. We have invested three quarters of a million pounds to support British dairy farms through a capital grant scheme. This work with Arla and our Sustainable Dairy Supply Group, received a Judges’ Special Award at the Industry Cream Awards last year. The grants enabled farmers to further improve animal health and welfare and reduce the carbon footprint of organic milk production.</p>",
        sectionClassModifier: "section--green",
        imageSrc: "/assets/cows-gang.jpg",
      },
    ],
    footer: {
      title: `We use a lot of organic milk supplied by farmers up and down the country! Our organic milk is sourced from:`,
      regions: [
        ["South East", "South West", "North East", "North West"],
        ["Midlands", "East of England", "Wales"],
      ],
      mapSrc: "/assets/milk-map.svg",
    },
  }),
  productPageFactory({
    title: "Potatoes",
    category: "potatoes",
    icon: "/assets/category-milk.svg",
    header: {
      description: `Like our Fries? Find out more about the potatoes that they are made from!`,
    },
    sections: [
      {
        sectionClassModifier: "section--gray text--black",
        content: `<p>What would we be without our world-famous McDonald’s Fries? We know how much you love them, which makes potatoes one of the most important ingredients needed for our menu!</p>`,
        imageSrc: "/assets/potatoes.jpg",
      },
      {
        imageSrc: "/assets/potato-farm-tractor.jpg",
        sectionClassModifier: "section--yellow description--white title--white",
        content: `<p>We work closely with the farmers who grow our MacFry varieties (Pentland Dell, Russet Burbank, Innovator, Shepody and Ivory Russet potatoes), which make our delicious Fries and all of the farms that supply our potatoes are Red Tractor certified.</p>`,
      },
      {
        imageSrc: "/assets/box-of-potatoes.jpg",
        content: `<p>We care so much about the quality and sustainability of our potatoes and together with our potato supplier McCain, we’ve launched the Sustainable MacFries Fund that will provide £1 million in grants to British potato growers who produce the varieties that make our world-famous Fries. This aims to help increase the resilience within the potato supply base by enabling MacFry farmers to use new technology to improve the understanding of soil quality and optimise water management.</p>`,
      },
    ],
    footer: {
      title: `From the south to the north, it takes an army to source our world-famous McDonald’s Fries!`,
      regions: [
        [
          "South East",
          "South West",
          "North West",
          "Midlands",
          "East of England",
          "Scotland (seed potatoes only)",
        ],
      ],
      mapSrc: "/assets/potato-map.svg",
    },
  }),

  productPageFactory({
    title: "RSPCA Assured Pork",
    category: "pork",
    icon: "/assets/category-pork.svg",
    header: {
      title: "Have you tried our <br/>RSPCA Assured Pork?",
    },
    sections: [
      {
        sectionClassModifier: "section--gray text--black",
        content: `<p>We only serve 100% RSPCA Assured pork across our menu and were the first UK restaurant to do so.</p>`,
        imageSrc: "/assets/region-section-images/Copy_of_Pork_13.jpg",
        imageStyle: {
          backgroundPosition: "100% 85%",
        },
      },
      {
        imageSrc: "/assets/pork-land.jpg",
        sectionClassModifier: "section--yellow description--white",
        content: `<p>Every farmer supplying McDonald’s ensures that all their pigs have bedded pens and plenty of space for them to move around.</p>`,
      },
      {
        imageSrc: "/assets/20180911MCD2690.jpg",
        content: `<p>We’re proud that our work has been recognised, such as in 2017 when we won an RSPCA Assured Award for Commitment to Farm Animal Welfare.</p>`,
      },
    ],
    footer: {
      title: `Our RSPCA Assured pork comes from every region in England, Wales and Scotland.`,
      regions: [
        ["South East", "South West", "North East", "North West"],
        ["Midlands", "East of England", "Scotland", "Wales"],
      ],
      mapSrc: "/assets/pork-map.svg",
    },
  }),
  productPageFactory({
    title: "Lettuce",
    category: "lettuce",
    icon: "/assets/category-lettuce.svg",
    header: {
      title: "<em>Lettuce</em> tell you a story…",
    },
    sections: [
      {
        sectionClassModifier: "section--gray text--black",
        content: `<p>Choice is really important to us at McDonald’s, whether you’re enjoying one of our Big Flavour Wraps, freshly prepared salads or legendary Big Mac®.</p>`,
        imageSrc: "/assets/lettuce-field.jpg",
      },
      {
        imageSrc: "/assets/lettuce-field-2.jpg",
        sectionClassModifier: "section--yellow description--white",
        content: `<p>The farmers which supply McDonald’s grow Iceberg, Batavia and Apollo lettuce which are found in products across our menu.</p>`,
      },
      {
        imageSrc: "/assets/lettuce.jpg",
        content: `<p>Where possible and when in season, our lettuce is sourced from the UK. The English growing season runs from May to the end of October, with places like East Anglia and Sussex providing the perfect growing conditions.</p>`,
      },
      {
        imageSrc: "/assets/more-lettuce.jpg",
        sectionClassModifier: "section--yellow description--white",
        content: `<p>At McDonald’s, we encourage good environmental practice to produce items throughout our menu, such as recycling the water used to irrigate crops during the summer months, and by using waste lettuce as compost.</p>`,
      },
    ],
    footer: {
      title: `Our network of lettuce suppliers produce leaves from four locations across the UK.`,
      regions: [
        ["South East", "North East"],
        ["Midlands", "East of England"],
      ],
      mapSrc: "/assets/lettuce-map.svg",
    },
  }),
]);
