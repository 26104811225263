import React, { useCallback, useContext, useEffect, useState } from "react";
import capitalize from "lodash/capitalize";
import {
  CATEGORIES,
  REGIONS,
  REGION_HERO_TITLES,
  SELECTED_REGION_TEXT,
} from "../config/consts";
import Screen from "../components/screen";
import Map from "../components/map";
import Categories from "../components/categories";
import Title from "../components/title";
import { AppContext } from "../config/context";
import { Link } from "react-router-dom";
import { filterFarms } from "../map-utils";
import farms from "../config/farms";
import Button from "../components/button";

export default function MapScreen() {
  const {
    scale,
    setContextScale,
    selectedRegion,
    setSelectedRegion,
  } = useContext(AppContext);
  const [selectedCategories, setSelectedCategories] = useState(() => [
    CATEGORIES[0].id,
  ]);
  const [markers, setMarkers] = useState(() =>
    filterFarms(farms, selectedCategories, selectedRegion)
  );

  const toggleMap = useCallback(() => {
    setContextScale(1);
    setSelectedRegion("");
  }, [setSelectedRegion, setContextScale]);

  useEffect(() => {
    setMarkers(filterFarms(farms, selectedCategories, selectedRegion));
  }, [selectedCategories, selectedRegion]);

  const lastSelectedCategory =
    selectedCategories[selectedCategories.length - 1] ?? null;

  const showHero =
    selectedRegion === "" &&
    lastSelectedCategory &&
    lastSelectedCategory !== "all";

  function uniqueGroups(markers) {
    const groups = {};
    markers.forEach((marker) => {
      if (!groups.hasOwnProperty(marker.group)) {
        groups[marker.group] = marker;
      }
    });
    return Object.values(groups);
  }

  const markerLinks = uniqueGroups(filterFarms(farms, ["all"], selectedRegion));

  return (
    <Screen name="map">
      <div className="map-container">
        <div
          className={`map-aside ${
            selectedRegion !== "" ? "region-selected" : ""
          }`}
        >
          <Title>
            {REGIONS[selectedRegion] ?? "Select Region or Click a Pin"}
          </Title>
          {selectedRegion === "" && (
            <p className="map-aside-info">
              Click on the map to find out more about the ingredients sourced
              from your region that help make some of the McDonald’s favourites
              for our restaurants across the UK*
              <br />
              <br />
              OR
              <br />
              <br />
              Click on a pin now to meet a farmer from your region that supplies
              McDonald's*
            </p>
          )}
          {selectedRegion !== "" && (
            <p className="map-aside-info" style={{ color: "#000" }}>
              {SELECTED_REGION_TEXT[selectedRegion]}
            </p>
          )}
          {showHero && (
            <div className="map-aside-hero">
              <div
                className="hero"
                style={{
                  backgroundImage: `url(/assets/category-heroes/${lastSelectedCategory}.jpg)`,
                }}
              />
              <p>
                Read more about our{" "}
                {capitalize(
                  CATEGORIES.find((c) => c.id === lastSelectedCategory)
                    ?.label ?? ""
                )}
              </p>
              <Link
                to={`/Scotland/ingredient/${lastSelectedCategory}`}
                className="button"
              >
                View
              </Link>
            </div>
          )}
          {selectedRegion !== "" && markerLinks.length > 0 && (
            <div className="map-aside-links">
              <div className="links">
                {markerLinks.map((marker, index) => {
                  return (
                    <Link
                      to={`/map/${marker.slug}?group=${marker.group}`}
                      className="button"
                      key={index}
                    >
                      <img
                        src={`assets/category-${marker.category}.svg`}
                        alt={marker.category}
                      />
                    </Link>
                  );
                })}
              </div>
              <p>{REGION_HERO_TITLES[selectedRegion]}</p>
            </div>
          )}
          {scale !== 1 && (
            <Button className="map-aside-button" onClick={toggleMap}>
              <img src="/assets/back-arrow.svg" alt="<" /> Click back to the map
            </Button>
          )}
          <img src="/assets/compass.svg" alt="Compass" className="compass" />
        </div>
        <Map
          markers={markers}
          setMarkers={setMarkers}
          onChange={setSelectedRegion}
          showHero={showHero}
        />
      </div>
      <Categories
        onChange={setSelectedCategories}
        selected={selectedCategories}
        showDisclaimer={true}
      />
    </Screen>
  );
}
