export function getClassNames(region, selectedRegion, debugMarker) {
  let activeClassName = region === selectedRegion ? "active" : "";
  if (debugMarker && debugMarker?.regionId === region) {
    activeClassName = "active";
  }
  return `map-svg-region ${activeClassName}`;
}

export function getOrigin(selectedRegion) {
  switch (selectedRegion) {
    case "sw":
      return [65, 100];
    case "se":
      return [100, 100];
    case "east":
      return [105, 90];
    case "midlands":
      return [90, 80];
    case "york":
      return [90, 55];
    case "ne":
      return [85, 50];
    case "nw":
      return [85, 70];
    case "wales":
      return [70, 85];
    case "sco":
      return [50, 15];
    case "ni":
      return [20, 55];
    case "ie":
      return [0, 76];
    default:
      return [0, 0];
  }
}

export function filterFarms(farms, categories, selectedRegion) {
  return farms.filter((marker) => {
    const inCategory =
      categories.includes(marker.category) || categories.includes("all");
    if (selectedRegion === "") {
      return inCategory;
    }
    return marker.regionId === selectedRegion && inCategory;
  });
}

export function getMarkerPosition(marker) {
  const [x, y] = marker.position;
  return {
    x: x * 100,
    y: y * 100,
  };
}

export function getLink(marker, selectedRegion, showHero = false) {
  if (showHero) {
    return `/${marker.slug}/ingredient/${marker.category}`;
  }
  if (selectedRegion === "") {
    return `/map/${marker.slug}?group=${marker.group}`;
  }
  return `/${marker.slug}/ingredient/${marker.category}`;
}
