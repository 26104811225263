import React, { useCallback, useState } from "react";
import { AppContext } from "../config/context";
import { MODE } from "../config/consts";
import products from "../config/products";
import regions from "../config/regions";
import farms from "../config/farms";

export default function AppContextProvider({ children }) {
  const [screen, setScreen] = useState(
    MODE === "development" ? "home" : "home"
  );
  const [farm, setFarm] = useState(MODE === "development" ? null : null);
  const [scale, setScale] = useState(1);
  const [openCookieSettings, setOpenCookieSettings] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState("");
  const setContextScale = useCallback(
    (s) => {
      setScale(s);
    },
    [setScale]
  );

  const findPageContent = (groupId = null, farmUrl) => {
    const location = window.location.pathname;
    const pages = [...regions, ...products];
    const page = pages.find(({ slug, group }) => {
      if (groupId) {
        return slug === location && group === parseInt(groupId);
      }
      return slug === location;
    });
    if (!page) {
      return null;
    }

    const farm = farms.find(({ slug }) => {
      return `/map/${slug}` === farmUrl;
    });
    page.produce = farm?.produce ?? null;
    page.region = farm?.regionSlug ?? null;
    return page;
  };
  return (
    <AppContext.Provider
      value={{
        screen,
        setScreen,
        farm,
        setFarm,
        findPageContent,
        scale,
        setContextScale,
        selectedRegion,
        setSelectedRegion,
        openCookieSettings,
        setOpenCookieSettings,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
