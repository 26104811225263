import React from "react";
import { LIVE } from "../config/consts";
import Screen from "../components/screen";
import StyledLink from "../components/styled-link";

export default function HomeScreen() {
  return (
    <Screen name="home">
      <div className="mask" />
      <div className="container">
        <div className="intro">
          <div className="intro-inner">
            <h3>Map My McDonald's</h3>
            <p>
              {LIVE
                ? "Click here to find out more about the quality ingredients sourced from across the UK & Ireland that help make some of McDonald’s favourites"
                : "Coming soon"}
            </p>
            {LIVE && <StyledLink to="/map">Explore Map</StyledLink>}
          </div>
          <img src="/assets/marker.svg" alt="Marker" className="marker" />
        </div>
      </div>
    </Screen>
  );
}
