import React from "react";

export default function Screen({ name, children }) {
  return (
    <div className={`screen screen--${name}`}>
      <div className="logo">
        <img src="/assets/logo.svg" alt="logo" />
      </div>
      {children}
    </div>
  );
}
