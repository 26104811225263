import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import routes from "../config/routes";
import CookieNotice from "./cookie-notice";

export default function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <CookieNotice />
        {routes.map(({ path, component, exact = true }, key) => {
          return (
            <Route key={key} exact={exact} path={path} component={component} />
          );
        })}
      </BrowserRouter>
    </div>
  );
}
