import React, { useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import { CATEGORIES } from "../config/consts";
import Button from "./button";
import { AppContext } from "../config/context";

export default function Categories({
  onChange,
  className,
  selected = [],
  showDisclaimer = false,
}) {
  const { setOpenCookieSettings } = useContext(AppContext);
  const updateSelected = useCallback(
    (category) => {
      return () => {
        if (!onChange) {
          return;
        }
        const allId = CATEGORIES[0].id;
        let newSelected = [...selected];
        const index = newSelected.indexOf(category.id);
        if (category.id === allId) {
          return onChange(index > -1 ? [] : [allId]);
        }
        if (index === -1) {
          newSelected = [...newSelected, category.id];
        } else {
          newSelected = newSelected.filter((filter) => filter !== category.id);
        }
        onChange(newSelected.filter((s) => s !== allId));
      };
    },
    [selected, onChange]
  );

  const openCookieSettings = useCallback(() => {
    setOpenCookieSettings(true);
  }, [setOpenCookieSettings]);

  return (
    <div className={`categories ${className ? className : ""}`}>
      <div className="categories-inner">
        {CATEGORIES.map((category) => {
          const activeClassName = selected.includes(category.id)
            ? "active"
            : "";
          return (
            <div
              key={category.id}
              className={`category category-${category.id} ${activeClassName}`}
            >
              <div>
                <Button onClick={updateSelected(category)}>
                  <img
                    src={`/assets/category-${category.id}.svg`}
                    alt={category.label}
                  />
                </Button>
              </div>
              <label>{category.label}</label>
              {category.id === "all" && <div className="divider" />}
            </div>
          );
        })}
      </div>
      {showDisclaimer && (
        <div className="disclaimer">
          <p>
            Disclaimer: The pin locations are a visual representation only. The
            pin locations represent the region as opposed to the actual location
            of the farms.
            <br />
            *Ingredients are sourced locally from more than 23,000 farmers,
            growers and suppliers across the UK and Ireland, and subsequently
            distributed across the UK to make McDonald’s menu items served
            nationwide.
          </p>
          <div className="disclaimer-actions">
            <div onClick={openCookieSettings}>Cookie Settings</div> &nbsp;&nbsp;{" "}
            <Link to="/cookies-policy">Cookie Policy</Link>
            &nbsp;&nbsp;{" "}
            <a
              href="https://www.mcdonalds.com/gb/en-gb/privacy-policy.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Statement
            </a>
            &nbsp;&nbsp;{" "}
            <a
              href="https://www.mcdonalds.com/gb/en-gb/terms-and-conditions.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms & Conditions
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
