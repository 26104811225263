import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import clamp from "lodash/clamp";
import {
  getClassNames,
  getMarkerPosition,
  getOrigin,
  getLink,
} from "../map-utils";
import { AppContext } from "../config/context";
import { MAP_ZOOM } from "../config/consts";

const normal = (value, x1, y1, x2, y2) =>
  ((value - x1) * (y2 - x2)) / (y1 - x1) + x2;

const round = (n) => Math.round((n + Number.EPSILON) * 100) / 100;

// const DEBUG_PINS = (process?.env?.NODE_ENV ?? "production") === "development";
const DEBUG_PINS = false;

export default function Map({
  onChange,
  setMarkers,
  showHero = false,
  markers = [],
}) {
  const {
    scale,
    setContextScale,
    selectedRegion,
    setSelectedRegion,
  } = useContext(AppContext);
  const map = useRef();
  const origin = useRef([0, 0]);
  const [debugId, setDebugId] = useState(null);

  const selectRegion = useCallback(
    (region) => {
      return () => {
        setSelectedRegion((selectedRegion) =>
          selectedRegion === region ? "" : region
        );
      };
    },
    [setSelectedRegion]
  );

  // Zoom map out when clicking on a non-region
  const onMapClick = useCallback(
    (event) => {
      if (event.target.nodeName === "svg") {
        setSelectedRegion("");
      }
    },
    [setSelectedRegion]
  );

  const onClick = useCallback((event) => {
    if (!DEBUG_PINS) return;
    event.preventDefault();
  }, []);

  const onMouseDown = useCallback(
    (id) => {
      return () => {
        if (!DEBUG_PINS) return;
        setDebugId(id);
      };
    },
    [setDebugId]
  );

  const onMouseUp = useCallback(() => {
    setDebugId(null);
  }, [setDebugId]);

  const onMouseMove = useCallback(
    (event) => {
      if (!DEBUG_PINS || debugId === null) return;
      event.preventDefault();
      const rect = map.current.getBoundingClientRect();
      const x = clamp(event.pageX - rect.left, 0, rect.width);
      const y = clamp(event.pageY - rect.top, 0, rect.height);
      const newPosition = [
        round(normal(x, 0, rect.width, 0, 1)),
        round(normal(y, 0, rect.height, 0, 1)),
      ];
      setMarkers((markers) =>
        markers.map((marker) => {
          if (marker.id === debugId) {
            marker.position = newPosition;
          }
          return marker;
        })
      );
    },
    [debugId, map, setMarkers]
  );

  const onOutsideMapClick = useCallback(
    (event) => {
      if (["DIV", "P"].includes(event.target.nodeName)) {
        setSelectedRegion("");
      }
    },
    [setSelectedRegion]
  );

  useEffect(() => {
    if (onChange) {
      onChange(selectedRegion);
    }
  }, [selectedRegion, onChange]);

  useEffect(() => {
    const [x, y] = getOrigin(selectedRegion);
    if (x + y === 0) {
      return;
    }
    origin.current = [x, y];
  }, [selectedRegion]);

  useEffect(() => {
    setContextScale(selectedRegion === "" ? 1 : MAP_ZOOM);
  }, [selectedRegion, setContextScale]);

  useEffect(() => {
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
    window.addEventListener("click", onOutsideMapClick);
    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
      window.removeEventListener("click", onOutsideMapClick);
    };
  }, [onMouseMove, onMouseUp, onOutsideMapClick]);

  const [oX, oY] = origin.current;

  return (
    <div className="map" onClick={onMapClick}>
      <div className="map-plane">
        <div
          className="map-plane-inner"
          style={{
            transformOrigin: `${oX}% ${oY}%`,
            transform: `scale(${scale})`,
          }}
        >
          <div className="map-markers">
            {markers.map((marker) => {
              const { x, y } = getMarkerPosition(marker);
              return (
                <Link
                  key={marker.id}
                  className="marker"
                  to={getLink(marker, selectedRegion, showHero)}
                  style={{
                    left: `${x}%`,
                    top: `${y}%`,
                    transform:
                      scale === 1
                        ? undefined
                        : `scale(${1 / scale}) translate(-50%, -50%)`,
                    zIndex: marker.id === debugId ? 1000 : marker.zIndex,
                  }}
                  onMouseDown={onMouseDown(marker.id)}
                  onClick={onClick}
                >
                  <div className="marker-inner">
                    {DEBUG_PINS && (
                      <div className="marker-debug">
                        {marker.position.join(",")}
                      </div>
                    )}
                    <img
                      src="/assets/map-marker.svg"
                      alt="Marker"
                      className="marker-pin"
                    />
                    <img
                      src={`/assets/category-${marker.category}.svg`}
                      alt={marker.category}
                      className="marker-icon"
                    />
                  </div>
                </Link>
              );
            })}
          </div>
          <svg
            viewBox="0 0 769.9 1006.7"
            enableBackground="0 0 769.9 1006.7"
            className="map-svg"
            ref={map}
          >
            <g
              id="region-anglesey"
              className={getClassNames(
                "anglesey",
                selectedRegion,
                markers.find((marker) => marker.id === debugId)
              )}
            >
              <path
                d="M368.6,659.7l2.9,0.3l0.9,0.5l1,1l2.4-0.4l2,0.2l1.4,2.9l-0.8,1.5l1.2,1.8l2.7,0.3l0.1,2.6l1.2,2.5l-0.2,1.9
		l4.5-1.1c0,0,1.3-1.4,1.6-1.4s4.5,0.9,4.5,0.9l0.5,0.1v2l-2.3,1.5l-3.8,4.3l-3,3.1l-2.8,1.9l-0.6,1.7l0.3,1.9c0,0-0.9,2.2-1.4,2.8
		c-0.2,0.2-4.7,0.7-4.7,0.7l-0.7,1.2l-1.7,0.6l-2-1.6l-2-1.5l-1.7-1.6l-2-1l-1-1.8l-1.4,0.3l-1.2-1.5l-0.1-2.7c0,0-1.7-2.7-2.2-2.8
		c-0.5-0.1-0.7-0.9-0.7-0.9l-1.2-2.5l-0.9-0.9l1.8-2.5l-0.3-2.2l-1.8-1.2l1.4-2.9l0.7-2.5l-1.2-2.6l1.5-0.5l3,0L368.6,659.7z"
              />
              <polygon
                points="353.9,675.8 356.1,676.1 357.5,677 358.8,679 359.1,679.8 359.8,680.8 361.9,683.2 360.5,684.6
		358.4,685 356.5,682 355.6,679.9 352.7,680.7 350.9,680.1 350.8,678.7 349.9,677.5 350.9,676.1 352.7,675.3 	"
              />
            </g>
            <g
              id="region-iom"
              className={getClassNames(
                "iom",
                selectedRegion,
                markers.find((marker) => marker.id === debugId)
              )}
            >
              <path
                d="M372.5,563v3l-0.9,3.1l0.7,1.4l2.5,0.9l-1.6,2.5l-0.8,1.5l-1.4,1.8l0.8,1.7l-1.9,2h-1.6v1.5l-1.4,1.2l-1.1,0.4
		l-0.5,1.3l-2.4,1l-0.5,0.7l0.3,0.6c0,0-0.4,1.2-0.5,1.1c-0.1,0-0.6-0.2-0.6-0.2l0.2-1.4l-1,0.4l-0.3,0.6c0,0-0.8-1.6-1.3-1.6
		c-0.5,0-1.2,0-1.2,0s-0.2,0.9-0.2,1c-0.1,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-1.4,0.4-1.4,0.4l-0.5-0.7l1.1-1.4l-0.5-0.4l0.9-1.4l1-1.2
		l0-1.7l-0.4-1l0.8-1.6l0-1.7l0.9-1.1l1.9-0.4l2.4-3.2l1.7-3.2l1.2-3.4l3.6-1.9l1.8-0.3L372.5,563z"
              />
              <path d="M354.5,588.1c0,0,0.1,0.6,0,0.7c-0.1,0.1-0.7,0.3-0.7,0.3l-0.5-0.7L354.5,588.1z" />
            </g>
            <path
              id="region-nw"
              onClick={selectRegion("nw")}
              className={getClassNames(
                "nw",
                selectedRegion,
                markers.find((marker) => marker.id === debugId)
              )}
              d="M539.3,643.1l-1-0.5l-0.2-1.4l-1.9-1l-0.3-1.1l-2-1.5l-2-3.2l-0.9,0.1l-0.4-2.1l-0.7-0.5
	l0.3-1.9l-1.1-1.3l0.2-1.3l-2,0.6l-0.2,0.8h-2l-1-1.5l-0.6,0.8l-0.1-1.5l-1.1-0.7l-0.7-2.5l1.1-1.3l0.7-0.1l0.9-1.7l-0.3-3l0.7-0.8
	l-0.2-1.2l5.1-3.2v-2.2l-2.2-2.1l-0.5,0.2l0.5-0.8l-1.4-1.6l-0.6-2.6l-1.2-1.2l-1.9-0.1l-0.4-1l-0.8,0.2l0.2-1.1l-1-2.5l-1.9,0.9
	l-0.4-1.1l0.7-0.7l-0.6-0.4l-4,0.8l-1.5-2.1l-1.3,0.5l-0.8-0.6v-1.7l0.8,0.1l-0.2-0.8l-0.9-0.2l-0.3-2.3l-0.8-0.9l-3.2,1.2l-2.1-0.3
	l-0.7-1.1l0.4-2.6l-2.8-2.2l-0.9,0.1v-0.8l-0.8-0.2l-0.5-1.4l-1.2-0.6l0.2-3.2l1.7-0.5l0.3-1.3l3.1-3.5l1.2-2.6l0.1-0.8l0.7-0.7
	l-0.1,1.4l3.5,0.2l0.5-1.6l1.5-0.1v-0.9l0.6-0.2l0.9,1.3l1.4-0.4l0.6-3.1l-0.7-1l0.5-0.8l-0.4-2.6l0.8-1.4l-3.6-3.4l1.5-0.4l1.7-1.8
	l1.2-0.1l0.3-1.5l-0.9-0.7l-0.2-2.4l1-1.3l-0.1-0.8l1.3-0.2l0.2-0.7l1.2-0.5l3.8,0.3l0.7-1.4l0.5,0.4l0.7-1.5l-0.8-6.5l-0.6-0.4
	l-1,0.3l-0.8-2l-6-4.8l-1.3-3.8l2.3-2l-0.3-1.5l-0.7-0.6l-1.5-0.1l-1.5-1.7l-0.2-1.3l0.7-0.5l0.1-1.6l1-1.1l-0.4-0.6l0.6-3.5l0.9-1
	l-0.2-1.9l-0.7-0.4l-0.3-1.1l-1.3-0.2l-1.5-2.2l-1.1-0.5l-1.4-2.7l-0.7,1.5l-1.9,1.2l-0.5-0.2l-2.2,2.6l-2.2,0.5l-1.7-1.2l-1.5-3.2
	l0.6-0.8l-2-3.4l1.8-1.3l0.6-2.5l1.1-1.2l-0.8-0.4l-0.7-1.6l0.2-1.1l-1.7-0.6l-0.4-0.9l1.8-1.8l0.4-1.7l-0.4-0.1l0.1-1.4l2.9-1.8
	l1-0.4l0.3-0.1l1.4-0.3l-1.3-2.5l0.7-0.6l-0.6-0.8l0.9-0.8l-1-0.8l-1.9,0.9l-1.9-0.1l0.2-0.6l-0.6-0.8l-1-0.1l-0.6-0.9l-0.3-2.1
	l-2.1-0.3l-1.5-0.9l-1.3-2.1l-0.4-1.2l0.5-1l-1-1.4l-0.7,1.5l-1,0.5l-0.9,0.4l-3.6,3l-2.2,0.3l-0.5,0.6l0.3,0.8l-2,1.8l-1.7,1.5
	l-0.4,0.7l-0.3,1.1l-1.1-0.1l-0.6,0.8l-0.9,0.2l0.1,1.2l-1.9,1l-5-0.4l-0.5,0.6l1.7,1.3l-1.5,2.9l0.3,1.2l-0.9,0.7l0.1,0.6l-0.9,0.4
	v1.3l-3-0.3l-1.3,1.7l-0.9,0.9l-2.7-1.4l-6.9,0.1l-2.1,1.7l0.5,0.4l0.9-0.9l0.5,0.5l-2.2,4.7l-0.9,0.4l-2.3,3.5l-1.2,0.5l-2.1,2.9
	l-1.6,2l-0.1,0.5l0.6,0.8l0.9,0.5l0.2,0.4l-1.2,1.3l0.3,0.6l0.4-0.3h0.8l-0.1,2.5l-3.4,3.6l-1.3,3.3l-3.8,4.9l0.5,4.6l-0.7,2
	l-0.1,2.8l-1.4,3.8l-1.9,1.3l-0.1,0.7l1.2,2.3l1.4,1.1l2,3.5l4.5,5.6l1,1.8v1.2l2.5,3.2l0.9,3.1l0.1,3.1l5.1,8.5l0.7,1.9l-0.3,1.3
	l2,2.5l0.5,1.4l5.2,10h2.1l0.1-1.8l-2.6-1.4l-0.6-2.7l0.7-1l1.5-0.2l1.9,1.6l1.5-1.1l2-3.6l2.3-2.6l0.5-3.1l1.3-1.8l1.5,0.9l0.9,2.4
	l2.2,2.3l1.9-0.4l2.2-5l1.5-0.3l1.1,0.4l0.3,3.1l1.1,0l0.7,0.1l-0.3,0.2l-1.1-0.2l-0.6,0.3l0.9,1.8l2,0.9l0.3,4.7l-2.9,3l-2.2,0.9
	l-1.3,1l-0.4,0.5l-0.5,2.3l-0.2,0.5l1.5,0.3l0.2,3l1.4,3.4l-6.3,3l-3.1,0.6l-1.3,1.5l-0.7,1.7l0.6,2.3l-1.5,11.5l2.7,2.7l4.9,0.8
	l4.4-0.6l-0.8,1.3l-2.2,0.8l-2.8,2.5l-4.1,5.5l-0.8,2.9l-0.6,0.9l-1,0.3l-2.4,6.7l-0.1,0.6l-0.4,1.1l2.3,1.8l0.9,2.4l3.2,4l0.9,1.9
	l1.9,5.5l1.4,1.5l5.8,4l1.1,0.1l0.4-1l0.7,0.4l0.7-0.2l0.6-1.6l1.4-0.1l0.7-2.6h1.2l0.8-2.3l1.9,0.3l0.2,0.9l1.4,0.5l0.9-0.2l0.6-3
	l-0.4-0.6l0.6-0.7l-0.7-0.4l0.4-0.9l-0.8,0.2l0.3-1.5l2.4,0.9l0.7,1l1-0.8h0.9l-0.2-0.8l0.9,1.1l0.9-2l1.3-0.3l-0.2-0.7l3.5-1.4
	l0.5,0.5l-0.2,1.2l1.1,2.7l0.8,0.3l0.8,1.4l-0.3,1l1.3,0.4l1.7-0.1l0.1-0.5h2.3l1.6-2.5l1.3,0.6l1.4-0.6l0.1,0.5l1,0.9l1.3,0.9
	l0.7-0.7l-0.1,1.3l0.5,0.3l0.4-0.5v1.2l1.2-0.2l0.5-0.5v-1.3l1.3-0.9l0.3-1l0.8,0.4l0.1-0.5h0.5l-0.6,1.1l0.1,1l1.8-1l-0.5-0.7
	l0.3-0.8h1.4v-0.4l1.3,0.9l0.9-0.5l0.6,1.2l1.5-0.6l1,0.5l0.8-0.3l0.2,1l1.6,0.5h0.2l2.7-5.2l2.6-2l1.5-3l0.5-1l0.5-2L539.3,643.1z
	 M484.4,574.3l-1.6-1.1l0.4-0.3l1.8,1.2L484.4,574.3z M486.4,576.1l0.1-1.6l-0.8-0.4l0.6-0.2l0.9,0.5l-0.1,1.6l3,0.7l-0.1,0.2
	L486.4,576.1z M496.6,573.2h-3.1l-0.4-0.3l0.2-0.5l0.9,0.7h2.6L496.6,573.2z"
            />
            <path
              id="region-ne"
              onClick={selectRegion("ne")}
              className={getClassNames(
                "ne",
                selectedRegion,
                markers.find((marker) => marker.id === debugId)
              )}
              d="M584.7,523.5l-1.4-2.6l0.3-1.4l1.2,0.1l-0.2-1l-4.5-2.9l-3.8-5.7l-0.3-3.5l-0.8-2.2l0.3-0.5
	l-1.5-1.7l-1.1-4.9l0.7-0.9l-0.5-0.8l0.4-0.7l-0.4,0.5l-0.1-1l0.6,0.2l-0.7-0.4l-0.2-1.2l0.6-0.4l-0.4-0.3l0.5-0.2l0.2-1.4l-0.5-1.5
	l-2.7-2.5l-0.6-1.2l0.4-0.4l0.2-0.7l-0.3,0.2l-0.8-0.4l-0.8-1.7l0.3-0.5l-1.5-1.8l0.2-1.9l-0.5,0.1v-0.6l-0.3,0.2l-2-2.9l-0.3-1.3
	l0.6-0.3l-2.2-4.7l0.6-2.5l1-0.5l-0.2-0.9l-1.5-2l-0.3-1l0.4-0.6l-1.6-1.6l-1.1-2.7l-0.2-2.7l0.9-2l0.8-0.1l-0.6-0.7l0.6-0.6l-3-3
	l-0.9-2.6v-1.4l1.3-1.1l-0.1-0.9l1.1-0.3l-0.1-0.6l0.3,0.2l-0.7-1l0.5,0.1l-0.3-0.8l0.4-0.3l-0.2-0.7l-1.1-0.5l0.4-0.9l-0.4-1.2
	v-3.9l-1.5-0.8l0.5-1.2l-0.7-0.4l0.6-0.7l-0.8-0.2l0.4-0.6l-0.5,0.2l-0.8-1v-1.4l0.9-0.5l-0.6-0.2l0.2-0.5l-1.2-1.4l0.3-0.9
	l-1.6-0.9l-1.6-2.3l-2.8-1.1l-1.9-1.5l-1.8-3.5l0.9-0.5v-2.2l-1.9-0.3l-5.9-2l-3.5-2.8l-1.9-2.7l-0.1-1.5l-0.5-0.8l-0.1-0.5
	l-2.2-3.4l-2.2,1.4l-0.3,0.3l-1,1l0.2,2.9h-0.9l-0.6,0.5l0.2,1.1l-1.3,1.2l-1-0.2l-0.3,0.8v0.7l-1.8,0.8l0.8,1.6l-0.4,0.3l-1.2,1.1
	l-1.7,2.4l0.1,1l-0.8,0.2l-1.1,1.1l1,0.8l-0.4,0.5h-0.1l-1.5-0.5l-1.3,0.1l-1.2-0.5l-1.5,0.5l-0.7,1.2l0.7,0.7l1-0.2l-0.5,0.9
	l1.4,1.8l0.5,0.9l-0.3,1.6l3.1,3.1l0.9,3.2l-0.2,1.8l1.4,1.8l0.1,0.9l0.3,1.1l2.2,0.7l-1.5,0.8l-0.9,2.5l-1.5,0.9l-2-0.3l-0.9,0.8
	l-1.2,0.8l-0.7,0.7l-0.5,0.6l-1.3-0.1v0.4l-0.7,0.6l0.9,2l-0.6,0.6l-0.3,0.4l0.3,0.7l-3,2h-0.1l-0.6-0.4l-0.4-0.5h-1.5l-0.2-0.1
	l-0.8-0.2l-1.3,0.7L502,448l-2,3.2l-2.2,0.7l-0.7,1.5l-0.5,0.5l-0.9,0.7l-1.4,1l-1.2,2.3l-1.2,0.2l2.2,1.4l-1.2,0.3l-0.2,2.2
	l-1.2,0.5l-0.9-0.2l-0.8,1.7l-0.6,1.9l1,1.4l-0.5,1l0.4,1.2l1.3,2.1l1.5,0.9l2.1,0.3l0.3,2.1l0.6,0.9l1,0.1l0.6,0.8l-0.2,0.6
	l1.9,0.1l1.9-0.9l1,0.8l-0.9,0.9l0.6,0.8l-0.7,0.6l1.3,2.5l-1.4,0.3l-0.3,0.1l-1,0.4l-2.9,1.8l-0.1,1.4l0.4,0.1l-0.4,1.6l-1.8,1.8
	l0.4,0.9l1.7,0.6l-0.2,1.1l0.7,1.6l0.8,0.4l-1.1,1.2l-0.6,2.5l-1.8,1.3l2,3.4l-0.6,0.8l1.5,3.2l1.7,1.2l2.2-0.5l2.2-2.6l0.5,0.2
	l1.9-1.2l0.7-1.5l1.4,2.7l1.1,0.5l1.5,2.2l1.3,0.2l0.3,1.1l0.7,0.4l0.1,1.2l-0.9,1l-0.6,3.5l0.4,0.6l-1,1.1l-0.1,1.6l-0.7,0.5
	l0.2,1.3l1.5,1.7l1.5,0.1l0.7,0.6l0.3,1.5l-2.3,2l1.3,3.8l6,4.8l0.8,2l1-0.3l0.6,0.4l0.8,6.5l-0.7,1.5l0.7,0.3l2.7-0.7l4.6-2.3
	l-0.1,0.9l3,0.9l1.3,0.8l0.4,0.9l1.7-0.2l5.2-3.1l0.1-2.3l1.2-0.5l1.1,1.4l1.8,1.2l0.4-1.8l0.7-0.6l-0.2-2.7l2.9,0.5l0.8-1.6l2.3,1
	l1.5-0.3l0.2,1.1l1.3-0.1l0.1,0.7l-0.5,0.2l0.4,0.3l1.4-0.6l0.7,0.2l-0.1,0.8l1.4-0.2l-0.7,0.9l0.8-0.1l-0.2,0.9l1.8,1.3l0.6,1.5
	l0.8,0.2l0.8-0.2l-0.6-0.5l0.1-1l0.9,0.1l0.2,1l1.2-1.2l-0.4,1.2l1.5,0.2l0.5,1.3l-0.3,0.7l0.7,0.5l0.3-0.4l-0.5-0.8l0.6-0.4
	l-0.7-0.8l0.4-0.8l-0.3-1.6l-0.8-0.4l1-0.5l1.5,1.8l0.8-3.5l-1.8-1.4l0.5-1.1l-0.3-1.4l2.2-1l-0.3-1.2l0.6-2l-1-1l-0.9,0.9l-0.8-1.7
	l2.4-0.7l1.5-1.6l-0.1-0.8l0.6-0.5l2.5,0.9l0.3,0.5l0.4-1.4l0.8-0.1l1.2,1v0.8l2.7,0.5l1.4-0.6l1.5,0.8l1.4-1.1l1.8,0.2l0.7-2
	L584.7,523.5z M542,498.3L542,498.3l0,0.1L542,498.3z M542,495.5l0.2-0.5l1.7,1l-0.5,0.3L542,495.5z M543.2,491.7L543.2,491.7
	l0.3,1.6l0,0L543.2,491.7z M537.6,503v-0.2l0.1,0.1v0.2h-0.5L537.6,503z M541,501.7v-0.2l0-0.1L541,501.7L541,501.7z M569.4,485.1
	l-0.4-0.3l0.4-0.4l0.7,0.2L569.4,485.1z"
            />
            <path
              id="region-ni"
              onClick={selectRegion("ni")}
              className={getClassNames(
                "ni",
                selectedRegion,
                markers.find((marker) => marker.id === debugId)
              )}
              d="M251.1,458.4h0.7l0.8,1.2l2.5,0.3l0.4,0.5l2.7-1.5l1,0.3l2.6,1.5l1.2-0.1l3.4,2.8l3.6-1.1
	l1.4-1.4h1.1l1.4,1.8l3.3,1.3l0.9,2.5l1.1,0.7l0.5,2.7l-1,1.5l0.3,2.9l-1.1,2.1l-0.4,2.6l1.1,0.9l3-0.7l1.9,1.2l-0.9,4.8l-0.9,2
	l0.2,0.7l1.4,0.2l0.6,1.2h1.2l1,0.9l1.4,2.2l1.2,3.6l1.2,0.9h1l0.3,1.9l1.6,1.6l1,2.2l1-1.1l1.1-0.2l2.2,1.2l2.3,4.6l0.1,4.2
	l-1.3,2.4l-2.4,2.1l-5.9,2.2l-0.5,1l-2.9,2.4l-1.4,4l0.3,1.2l-0.3,1.5v0.7h0.9l1-0.7l0.4-1.8l1.7-0.6l3.4-2.9l3.6-1.8l1.7,1.2
	l2.2-0.3l1.4-0.3l0.6,0.8l0.9-0.8v-0.9l1.3,0.5l1.9-0.2l3.3,3.7v2.9l0.6,2.1l2.8,3.8l-0.4,2.9l0.8,1.2l0.5,2.9l1.9,1.3l-0.6,1
	l0.5,2.2l-0.8,0.5l-0.5,1.5l-0.9,0.1l-0.6,0.8l0.9,1.7l0.2,2.9l-0.6,0.5l-0.6-0.2l-0.6,0.8l-0.2,1.3l0.7,0.5l-1.1,1l0.2,1l-0.6,1.1
	l-0.7-1.4l-0.8,0.2l-0.2-2l-0.6-0.2l-0.6-1.9l-1.8-2.1l0.2-2.5l1.8-1.3l0.8-0.3l0.4-1.7l-0.7-0.8l0.4-0.8l-0.4-0.8l-0.1-0.9l0.2-1
	l-0.5-0.3l-0.9-4l-0.8-0.2l-0.4,0.2l-1-1v-0.8l-5.5-3.8l-1,1.6l0.1,2.4l-1.4,0.8l0.3,0.4l1.8,0.3l0.2,1.5l0.9-0.3l0.7,0.6l-1.1,0.7
	l0.1,1.8l0.4,0.2l0.9,0.7l0.5,1.3l0.4,1v0.6l0.3,0.7l-0.5,0.3l-0.3,0.7l0.8,0.7l-0.6,0.6l-0.5,1.6l0.5,1.5l-0.5,0.3l-0.2,1.7
	l-1.9,0.9l0.1,0.7l2,0.3l0.6-0.9l1.5-0.7l2.7-0.4l-0.3,1.2l0.5,0.7l0.3-1.1l0.8,0.7l0.6,5.4l0.2,0.7l-0.6,0.6l-0.9,2.4l-1.9,2.4
	l-0.6,0.3l-0.5,1.2l-0.5,0.2l-0.3-1l-0.4-0.3l-0.4-0.4l-0.5,0.6l0.5,1.5l-1.5,1.7l-1.2-2.4l-0.9-0.3l-1.8,0.2l-0.5-0.5l-1.7,0.7
	l-4,0.4l-0.8-1.5l1.1-0.2l0.4-2.1l-1.3,2.6l-1.2,0.9v1.2l0.4-1l1-0.6l0.4,1.3l-3.2,2.5l-0.6,1.2l1,1.9l0.2,2.3l-1.5,6.3l-3.6,3.1
	l-0.7,1.6l-1.8,0.2l-1.9,2.5l-2.5,1.6l-3-1.8l0.5-0.9l1.5,0.1l-1.3-1.6l-4.4-0.4l-1.2-0.8l-0.5-2.1l-0.8-0.6h-4l0,0l-1.2-1.1
	l-0.7,0.1l-1.5,1.9l-0.8-0.3l-0.3-2h-1.5v4.4l-1.1,0.4l-0.4,0.8l-1.6-0.3l-1.8,0.5l-1-1.5l-1.3-0.6l-0.3,0.7l0.5,0.6l-1.4,0.3
	l-0.8,1l-1.2-0.5l-1.6,0.7l-2.1-0.5l-0.3,1l-1.8,0.8l-1.5-2.4l-1-0.1l-0.2-0.8l0.6-0.7l-0.2-1.9l1-0.2l-1.1-2.8l0.6,0.1l1.2-2.7
	l0.1-1l-0.9-2.1l-1.1-1.7l-1.4-0.3l-0.5-0.6l-1.4,0.6l-0.4,1.4l-1.2-0.1l-0.9-1.6l-1.3-0.1l-1.6-1.6l-0.1-0.9l-0.8-0.2l-0.8-4
	l-0.6-0.5l-0.6,0.2l-1.5-1.6v-0.8l0.8,0.4l-0.1-0.7l0.5-0.4l-0.1-2l-1.7-4.1l-1.2-0.3l-0.3-2l-0.7-0.8l-0.6,0.2l-2.2-2.5l-1.4-1
	l-1,0.3l-0.2-0.7l-1.3-0.7l-1.8,0.9l-2,3.4l-1.5,1.2l0.5,1.3L211,556l-2.2,1.9l-2-0.4l0.6,1.7l-0.2,1.4l-0.9-0.1l-0.6,0.6l-0.3,1.3
	l2.2,1l-0.3,1.2h1.1l1.2,2.2l-0.9,0.9l0.6,0.6v1.7l-1.5,0.5l-1.1-0.7l-1,1l-1.5,0.4L204,572l-1.2,0.2l1,0.8l-1.4,0.4l0.1,1.8
	l0.8,0.9l-1,0.9l-0.6,1.5l0.2,1l-1,0.8l-0.1,0.7h-1.1l-0.6-1.1l1.4-0.6l-0.9-0.9l0.9-0.8l0.4-1.5l-0.7-0.5l-1.1,0.4l-1.9,2.5
	l0.6,0.8l0.6-0.1l0.7,1.5l-0.6,0.9l-0.4-0.9l-1.4,1l-0.9-1.8L194,581l-0.1-0.7l1.1-1.2l-3,0.3l-0.3-0.6l1.2-1.2l-1.2,0.1l0,0
	l-0.6-0.3v-1.3l-2.5-2.8l-0.8-3.5l-1.1-1.5l-0.2-1.2l-2.8-1.8h-1l-0.4,0.8l-1.6,0.8l2.7,2.8l1.4,0.2l0.8,3.5l1.9,1l-0.2,2.7l1.8,2
	l0,0l-0.8,1.9l-3.1-1l-1.1,1.4l-1.2-0.7l-0.8-1.5l-1.5-0.1l-1.3-1.1l-0.4-2l-2-1.8l-1.1,0.5l-0.7-2.6l-3.1,0.4l-0.9-0.9l-3.4,0.5
	l-0.6,0.5l-2.7-1.6l-0.3-4.8l-0.8-1.3l0.2-1.4l0.7-0.8l-1.4,0.1l-0.4-0.8l-0.7-0.2l-0.6,0.7l-0.8-1l-1.7-0.3l-3.2-4.5l0.1-0.8h-2.2
	l-2-1.6l-1.5-3.2l-4.5-4.1l1.1-1.3l-2.1-1.5l3.4-0.3l2.4-2l2.4,0.1l0.1-2l2.3-4.3l1.2,0.2l1.1-0.5l1.1,1.6l6-0.4l0.6,0.4l1.4-1
	l-0.4-1.3l2.1-1.8l0.1-0.9l2-0.6l0.9-1.3l2.9-1.1l0.4-0.9l-0.6-0.3l0.3-0.8l-0.4-0.7l-2,1.5l-1.3-0.6l-0.8,0.3l-2.6-2.4l-1.1,1.2
	l-1.5,0.3l-0.4-2.2l-2.6-0.9l-0.4-0.6l-0.4-1.3l0.9-1.4l-0.8-0.2l-0.5-1.2l2.5-0.1l1.3-2.2l1.7-1.5l0.8,0.2l1,1.6l3.5,1.4l0.6-1
	l3.7-1.7l2.4-2l1.4,0.8l1.7-0.1l0.8,0.1l1.9,0.2l0.6-1l-0.7-0.7l0.1-3.5l1.3-1.9l2.8-1.8l2.4-5.3l-0.6-2.4l0.3-4.2l0.8-0.8l2.5-0.4
	l-0.6-1.2l0.3-2.2l-0.4-0.8l1-1.5l-0.7-1.2l0.7-1.6l2.8-3.2l1.8,0.5l1.2-1.1l0.9,0.6l0.8-1.5l0.9-0.3l0,0l0.4,2.5l1.1-0.5l0.4-1.2
	h0.6l0.5,0.1l1.6-0.4l2,0.2l0.4,1.4l1.1,0.5l4.7-0.8l0.4-0.2l0.2-0.8l1.9-1.9l-0.5-1.2l0.1-0.5l0.8-1.6l1.1-0.3l1.4-5.2V465l1.2,0.3
	l3.6,2.5l10-0.1l0.7-2.1l3.8-1.1l0.3-0.5l-0.5-1l1,0.8l3.3-0.4l2.7-1.4l0.4,0.4l1-1l-0.2-0.9l1.6-0.8l0.1-0.7L251.1,458.4
	L251.1,458.4z"
            />
            <path
              id="region-midlands"
              onClick={selectRegion("midlands")}
              className={getClassNames(
                "midlands",
                selectedRegion,
                markers.find((marker) => marker.id === debugId)
              )}
              d="M662.7,704.4l2.1-4l2.9-3.6l4.4-2.6l1.3-1.8l7-4.5l0.9-8.3l-1.8-7.7l-4.1-9l-1.8-6.3l-4.7-8.6
l-3-1.8l-1-1.2h-2.1l0.3-0.2l-1.8-0.7l-1.7-2.4l-1.3-0.8l-0.3-1.2l-5.5-2.3l-5.5-4l-3.8-4.7l-2.1-3.9l-1.8-0.4l-7.9,1.8l-0.7-0.2
l0.8-0.2H631l-0.1-0.6l-1.9,0.3l-2.1,1.2l-2.3,0.1l-2.2-1.2l-1.5-1.8l-1.5-0.3l-4.7,2.5l-4,4.3l-1.8,1.1l-3.6,1l-0.7,2.8l-2.6,3.2
l-0.2,1.5l0.6,0.2l-0.7,2l0.6,1.1l-0.3,0.5l-3,0.6l-0.2,1.4l1.1,0.3l-0.2,0.9l2.2,2.9l0,0l-2.2-2.9l-1.1,1.9l-2,1.3v1.1l-0.6,2.6
l-1.1,0.4l-0.6,1.5l-3.2,1.2l-1.4,1.2l-1,2.3l-1.4,8.8l-1,2.6l-1.6-0.1l-1.6,1l-0.7,1.4l0.7,1.3l-0.6,2.1l0.6,0.9l0.2,2.5l-2.6,0.4
l-0.9,1.5l-2.2-0.7l-0.8,0.8l-1.4,0.2l0.5,2.8l-0.4,0.5l0.6,2.3l0.7,0.6l-0.5,0.7l0.4,0.9l-1.7,0.6l-0.6,1l0.9,0.5l0.2,1.2l2.5,2.9
l1.4,6.4l3,2.7l-1.3,5.4l0.2,3.7l-0.3,2.6l0-0.1l-0.1-0.2l-1.8,1.1l-4,0.5l-0.8,1l-4.4,0.4l-1,1.8l-2.2,0.4l-4.7,5.4l-1.2,3.1
l-1.6,1.5l-1.6,1.7l-0.6,2.6l0.1,1.5l1.1,2.5l-0.5,2.1l0.7,2.2l1.4,3l2.6,1.9l0.5,1.7l0.8,0.3l0.4,2.4l1.6,3.4l1.9,0.5l2-1.7
l1.1-0.1l1.4,0.5l1,1.2l2.7,0.9l3.7,8.6l2.2-1.2l0.4-0.1l-1.8,1l-1.8,2.6l-0.3,1.6l0.2,1.2l1.1,0.9l1.5,2.9l-0.8,0.1l-0.8,1.3
l-1.1-0.3l0.8,1.1l-0.2,0.6l0.9,0.6l0.4,1.8l-3.7,0.8l-1.2,1.7l-1.1,4l-1-0.2l-0.9,0.8l-0.1,0.1l-0.8,1.2h1.3l0.7,1.7l1.5,0.4
l0.3,0.8l-2.1,2.8l-0.2,1.1v1.9l2.2,5.9l2.1,1.8l5.4-2.2l0.9,0.2l1.4-0.6l-1.5-1.4l0.5-0.8l1-0.9l3.5-0.2l2.1-1.4l2.2-2.4l0.1-1
l1.4-0.2l-1-1l2.8,0.1l2.9,2.2l2.2-0.4l0.4-0.7l-0.3-2.3l0.6-0.9l4.9-2.7l2.3-2.5l0.5-2.1l1.1-0.5l0.2,0.8l3.8-3.1l2-0.2l1.3-2.7
l2.8-1.1v-2.5l0.9-1.5l1.4-1.1h0.9l1.2,1.1l1,0.2l2.1-0.7l2.3-4.1l-1.2-0.7l-0.1-1.3l1.1-0.5l0.4-1.8l1.2-1.4l3.7-1.8l0.6-1l0.2-6.5
l0.7-0.5l-1.1-2.4l0.3-1.7l-3.7-2l0.7-1.1v-1.3l-3.4-0.5l-1.7-0.8l0.1-1.8l-1-0.8l1.7-3.2v0l0,0l4,0.4l4.5-0.5l1-0.9l1.6-0.4
l0.7,0.6l7.8,2l1.5-0.5l1.3-1.2l4.6,0.1l5.3-1.1l2-1.9l0.3-2.9l1-0.1l1.7-1.3l2.1-0.2l1-4.5l0.9,0.3l2.9-2.6l2.5-1.3L674,722l1-1.2
l-0.5-0.1l1.2-2.7l-1.3,0.9l-3.4-1.3l-0.2-2.1l-3.7-4.3l-6.8-1l0.3-0.3l-1.7-0.5l-0.4-1.5l1-1.6L662.7,704.4z M627.5,729.7
L627.5,729.7l-1.1-1h0L627.5,729.7z M624.5,728.3L624.5,728.3l0.2-1.6l0,0L624.5,728.3z M606.1,705.4l0.1-0.5l0.1-0.1l-0.1,0.3
l0.7,0.2l-0.2,0.3L606.1,705.4z M610.5,670.9l0,0.1l-0.9-1.1l0.1-0.1L610.5,670.9z M605.4,738.2l2.8-2.2l0,0L605.4,738.2
L605.4,738.2z M608.1,698.3l1.5,3.4l-0.1,0l-1.5-3.4L608.1,698.3z M610.5,680.4l2.9,0.5l0,0.2l-2.9-0.5L610.5,680.4z M613.1,683.4
l0-0.1l0.5,1.8l0,0L613.1,683.4z M612.6,686.2l0.9,1.1l-0.1,0.1L612.6,686.2L612.6,686.2z M614.9,692.5l-0.2-0.8l-0.8-0.4l0.1-0.1
l0.7,0.3L614.9,692.5L614.9,692.5z M614.1,690.8l-1.4-1.4l0.1-0.1l1.4,1.4L614.1,690.8z M604.4,709.5v-1l0.1-0.1L604.4,709.5
L604.4,709.5z M612.4,725.7l-2.9,1.3l0.1-0.1L612.4,725.7l2.6-0.1l0.7,1l1,0.2l-0.1,0.1l-1-0.2l-0.7-1H612.4z M612.5,672.8l2.9-0.5
l1.3,2l0,0.1l-1.3-1.9l-2.9,0.5l-0.1,1.1l-2.7-0.3l0.1-0.2l2.6,0.3L612.5,672.8z M611.5,667.7l-0.4-0.9l0-0.1l0.4,0.9L611.5,667.7z
M611.3,665.7l-1-0.6l0.1-0.1l0.9,0.5L611.3,665.7z M610.8,664l-0.4-0.6l0.1-0.1l0.4,0.6L610.8,664z M610.8,662.4l-0.1,0.1l-0.9-1
l-1,0.1l0.1-0.2l0.9-0.1L610.8,662.4z M609.6,660l-0.8-0.7l0.2-0.1l0.8,0.7L609.6,660z M609.6,658.1l-0.7-0.8v-0.2l0.7,0.8V658.1z
M608.9,656.1l-0.9-0.2l-0.9-1l-0.2-1.5l0,0l0.2,1.3l0.9,1l0.9,0.2V656.1z M603.6,651l0.1-0.5l4.7,1.2l-0.2,0.2l-4.5-1.2l-0.1,0.5
l-2.7,0.1l-0.2-0.5l0,0l0.2,0.3L603.6,651z M599.6,712.6l0.6,0.8l-0.2,0.2l-0.7-1L599.6,712.6z M597,716.3l-0.3,1.8l0,0L597,716.3
L597,716.3z M593.4,718.9l-0.6,3.4l-0.1,0L593.4,718.9L593.4,718.9z M588.3,724.4l0.5,0.7l0.7-0.3l0.5,0.5l0.2,0.9l0.5-0.4l0.5,0.3
l-0.2,0.3l-0.4-0.3l-0.5,0.4l-0.2-0.9l-0.5-0.5l-0.7,0.3l-0.7-0.9L588.3,724.4z M582.1,721.2l0.1,0.1l-0.6,4l-0.1-0.2L582.1,721.2z
M586.4,763.7l-1.2,2.3l-0.7,0.1l1.1-2.1l2.5-1.7l0.4,0L586.4,763.7z M591.5,761.9l-0.5,0l4-3.4l0.4,0L591.5,761.9z M601.7,758.3
l-0.7,0l1.5-3.2l0.7-0.1L601.7,758.3z M606.6,754.2l-0.5,0.1l2.4-1.7l0.8-0.3L606.6,754.2z M612.9,749.2l-1.4,2.3l-0.8,0.3l1.6-2.5
l0.7-0.3l0,0L612.9,749.2z M625.8,740.8l-4.7,6.1l-2.8,0.9l-1.8,1.6l-2.1,0.4l0,0l2-0.4l1.8-1.6l2.8-0.9L625.8,740.8l0.6-1.5
l0.3-2.5l0.1,0l-0.3,2.5L625.8,740.8z M628.9,731.3l-2.2,2.6l-1,0.5l1.7,2.5l0,0l-1.7-2.5l1-0.5L628.9,731.3l0.4-1.6h0L628.9,731.3zM577.5,681.5l2.2,0.7l0.9-1.5l2.6-0.4l-0.2-2.5l-0.6-0.9l0.6-2.1l-0.7-1.3l0.7-1.4l1.8-0.5
l-7-3.3l-1.6-0.4l-2,0.5l-0.4-0.9l-1.8-0.8l-2.3,0.1l-3.3,2.1l-4.3,0.4l-5.1-1.4l0.1-1.9l-0.8,0.4l-0.3-0.5l0.9-1.6l-0.4-1.2
l-1.5-0.2l-1.9-3.5l-3-2.3l-1-0.2l-0.4-3.6l-2.5-1.9l-1.7-3.8l-4.2-2.6l-0.4,0.6l-1.5,3l-2.6,2l-2.7,5.2l1.8,1l0,0l-1.7-0.9H533
l-1.6-0.5l-0.2-1l-0.8,0.3l-1-0.5l-1.5,0.6l-0.6-1.2l-0.9,0.5l-1.3-0.9v0.4h-1.4l-0.3,0.8l0.5,0.7l-1.8,1l-0.4-1.3l0.7-1.4h-0.5
l-0.1,0.5l-0.8-0.4l-0.3,1l-1.3,0.9v1.3l-0.5,0.5l-1.2,0.2v-1.2l-0.4,0.5l-0.5-0.3l0.1-1.3l-0.7,0.7l-1.3-0.9l-1-0.9l-0.1-0.5
l-1.4,0.6l-1.3-0.6l-1.6,2.5h-2.3l-0.1,0.5l-1.7,0.1l-1.3-0.4l0.3-1l-0.8-1.4l-0.8-0.3l-1.1-2.7l0.2-1.2l-0.5-0.5l-3.5,1.4l0.2,0.7
l-1.3,0.3l-0.9,2l-0.9-1.1l0.2,0.8h-0.9l-1,0.8l-0.7-1l-2.4-0.9l-0.3,1.5l0.8-0.2l-0.4,0.9l0.7,0.4l-0.6,0.7l0.4,0.6l-0.6,3
l-0.9,0.2l-1.4-0.5l-0.2-0.9l-1.2-0.1l-0.8,2.3h-1.2l-0.7,2.6l-1.4,0.1l-0.6,1.6l-0.7,0.2l0.4,0.8l3.5,0.2l0.1,1l-1.2,1.9l-0.7-0.6
l-2.2-0.2l-2.1,1l-1.7,0.1l-3-1.7l-1.9-2.6l-3.1-10.6l-0.7-0.9l-1.4,0.1l-1.6,2l-4,1.2l-1.8,2l-2.4,1.1v0.8l0.9,1.6l4.3,3.3l0.9,2.7
l2,2.4v0.6l-0.9,0.5l0.3,0.9l1.5,0.6l0.2,0.6l1.4,0.1l5.4,4.2l1.3,1.8l0.5,1.2l-0.5,1.2l-3.9,1.5l-0.1,0.5l0.8,0.3l1,1.9l2.9,1.8
l0.8,0.3l1.4-1l-1.3,3.1l1.6,1l-0.1,1.2l1,1l0.1,0.8l-0.7,0.9l0.8,0.8v1.9h0.7l0.7,2.3l2,0.7l0.7-0.2l1.2-0.2l0.6,0.1l0.2,0.7
l1.6,0.2l0.5,0.2l0.1-0.1l0.2,0.2l-0.5,1l0.7,1.3l-0.2,3.1l-1.8,0.3l-2.8,3l-0.8-1.9l-1.7-2v-0.8l-3.2-0.9l-0.8,0.7l-1.5,0.5
l-1.2-0.7l-1.7-1.5l-0.6,0.1l-1.7,0.3L468,707l-2.6,0.4l-1.2-0.5l-0.9,1.2l-0.3,2.5l-1.5-0.1l-0.6,0.5v0.7v0.8l1.2,0.8l-2.2,2.1
l0.7,0.5l-1,2.5v1.2l0.5,0.1l0.3,0.4l-0.5,0.4l0.2,0.5l2.5,1l0.7-0.9l1.3-0.3l-0.1,1.9l1,0.8l1.6,0.2h0.9h1l0.4,0.9l-0.6,0.8
l1.9,0.9l-0.4,0.5l0.4,0.4l1.7,0.2l0.1,1.8h-0.9l0.1-0.9l-0.7,0.2h-2l-0.2,0.2l-0.8,2.8l-0.8,0.2l0.1,1.6l0.4,0.1l-0.8,2.8l-2,0.7
l1.4,1l-0.8,0.3l-1.3,2l0.2,1l-1.8,1.4h-0.7h-0.6l0.1,1.2l1.5,3.1l-1.4,0.9v0.7l2.3-0.4l1.1-0.7l-0.8-0.6l0.4-0.6l1.7-0.4l1.6-1.7
l1.7-0.3l0.9,2.2l-0.6,3.2l-1.8-0.1l-0.3,1.1l0.4,1l-0.7,0.2l-0.3,0.1h-3.9l-2.1,0.8l-2.5,1.8l-1.1-0.2l-2.4,2.8l0.3,1l0.6,1.8
l2.6,1.2l1.1,1l0.4,1.2l2.8,1.1l3.1,2.9l1.2,0.4l4.2-1.1l1.2,0.6l1.4-0.1l-0.4-1.1l0.8-0.6h1.6l-1.4-0.8l0.3-1.1l1.5,0.7v0l-1.3-0.6
l-0.3,1.1l1.4,0.8h-1.6l-0.8,0.6l0.4,1.1l-1.4,0.1l-1,2.3l-2.1,0.8v2.2l-0.6,1.2v1.1l0.5,0.2l3.4,0.6l-0.9,0.3l-0.8,0.6l-1.8-0.5
l-1.9,0.8l-0.8,0.7l0.2,1.2l-1.8,0.3l-0.3,1.9l0.4,0.2l-1.8,1.3l0.4,1.9l-1.7,1.9l1.5,0.9l1-0.9l0.5,0.7l-1.3,1.2l-2.6,0.6v0.5
l-0.4,0.5l2.2,0.9l0.1,1.3l-1.1,0.4l-0.1,2.4l2.1,2.8l0.3,1.1l-0.7,2l1.9,3.8l3.4,3.9l0.3,1.8l0.6,0.4l1.4-0.4l0.6,2.3l4.8-2.2
l0.2-0.8h0.8l1.9,1.1l-1.2,0.7l1.3-0.2l0.4,1.2l0.9,0.1l1.3,1.5l2.1,1l-0.6,1.6l2.2,2.7h0.3l1.1-1.1l0.4,0.9l1-0.5l0.2,1.1l1.7-0.2
l1,1l0.9-1.7l0.7,0.4l1.5-1.7l1.1,0.7l0.1-1.2l3.1,0.1l1.9-2.6l0.1,0.4l0.4-0.2l0.4,0.4l1.3-1.2l1.7-0.5l-0.6-2.2l-1.1-1v-2.4
l-2.2-0.9l0.5-0.4l-0.2-1.7l1.5-1.4l-1.3-1.3l0.8-1.5l1-0.1v0.9h1.7v1.8l1.5,0.3l0.9-0.2l0.4-1.7l2.6-0.9v-3.6l0.6-0.8l0.2-2.6
l-0.9-1.4l-0.2-2.4l0.7-0.6l-0.9-2l0.4,0.1l0.9,2l-0.7,0.6l0.2,2.4l0.9,1.4l-0.2,2.6l-0.6,0.8v3.6l-0.1,0.8l1.8,1l-0.1,2.8l0.9-0.1
l0.8,1l1.2-0.4l1.9,0.4l1.9-3l2.2,0.5l0.3-0.9l1,0.4V802l-1.5-1.5l0.7-0.4l-0.2-2l1.2-0.8l1.8,0.5l1.1,0.9l-1.5,1.1l-0.7,2.1
l0.7,0.3l-0.1,0.5l1.8-1.6l1.7,0.5l1.5-0.4l0.7,1.1l0.7-0.1v-0.5l0.7-0.1l2.7-2.6l2.1-0.2l0.2,0.6l1,0.2l1.2-1.5l3.3,3.2l1.4,0.6
l0.2-1.6l0.7-0.9l-0.3-0.8l-2.1-1.5v-1l-0.7-0.7l1.3-0.2l0.4-0.7l0.9,0.7l0.8-1.3l0.4,0.7l1.1-2.2l-0.5-1.1l-1.3-0.7h0l1.3,0.7
l0.5,1.1l1.7-1.2l1.4,2l0.5,0.2l0.6-0.9l-0.1,2.7l1.1,0.7l0.2-0.7l0.5,0.1l-0.2,1.3l1,0.8l-0.4,1.2l1.4,0.4l0.5,0.5l2.5-0.8l0.4,0.6
l-3.3,4.1l0.4,0.8l-0.3,1l0.4,1.2l0.6,0.2l-0.2,0.4l1.2,0.1l1.8-1.3l0.8,0.2l-0.4-0.9l1.4,0.3l0.5-4.6l1.5-5.2l2.3-1.9l1.3-2.9
l1.5,0.8l0.2,1.2l1.6,0.3l1.7-3.6l1.7,0.1l0.9-0.8l1,0.2l1.1-4l1.2-1.7l3.7-0.8l-0.4-1.8l-0.9-0.6l0.2-0.6l-0.8-1.1l1.1,0.3l0.8-1.3
l0.8-0.1l-1.5-2.9l-1.1-0.9l-0.2-1.2l0.3-1.6l1.8-2.6l-3.7-8.6l-2.7-0.9l-1-1.2l-1.4-0.5l-1.1,0.1l-2,1.7l-1.9-0.5l-1.6-3.4
l-0.4-2.4l-0.8-0.3l-0.5-1.7l-2.6-1.9l-1.4-3l-0.7-2.2l0.5-2.1l-1.1-2.5L557,738l0.4-0.3v-0.6l0.6-2.6l1.6-1.7l-0.1-0.1l-0.1-0.2
l1.5-1.4l1.2-3.1l4.7-5.4l2.2-0.4l1-1.8l4.4-0.4l0.8-1l4-0.5l1.8-1.1l0.2-2.7L581,711l1.3-5.4l-3-2.7l-1.4-6.4l-2.5-2.9l-0.2-1.2
l-0.9-0.5l0.6-1l1.7-0.6l-0.4-0.9l0.5-0.7l-0.7-0.6l-0.6-2.3l0.4-0.5l-0.5-2.8l1.4-0.2L577.5,681.5z M554.5,722.2l0,0.1l-0.5-0.5
l0.1,0L554.5,722.2z M555.7,730.2l0.2,0.1l-1.2,0.4l0-0.2L555.7,730.2z M551.6,727.3l2.8,0.9l0,0L551.6,727.3L551.6,727.3z
M555.3,715.9l0.1,0.1l-6.6-1l-0.2-0.1L555.3,715.9z M540.6,679L540.6,679l-1.9-0.2l-0.2-0.1L540.6,679z M540.6,749l-1,0.4l0,0
L540.6,749L540.6,749z M540.1,766.6l-1.3,1.3l0.3,4.4l-0.4,1.1l-1.3,1.2h0l1.3-1.2l0.4-1.1l-0.3-4.4L540.1,766.6l-0.3-0.8l0.6-0.2
l-1.4-0.7h0l1.4,0.7l-0.6,0.2L540.1,766.6z M531.4,760.8l0.1-0.2l1.7-0.2l-0.2,0.2L531.4,760.8z M535,757.1L535,757.1l0.6-0.3l0,0.1
L535,757.1z M512.3,695.3l3.5-1.8l4.3-3.6l4.8-6l3.1-0.5l5.8-3l1.9-1.5l0.4-1.5l0,0l0.1,0.1l-0.4,1.5l-1.9,1.5l-5.8,3l-3.1,0.5
l-4.8,6l-4.3,3.6l-3.5,1.8l-3.7,3.5l0.1,1l-1.1,0.3l0.7,1.6l-4.9,4.2l-0.9,3.4l1.2,2.2l-0.5,0.2l-1.5-2.8l0.9-3.4l4.9-4.2v-1.2
l1.1-0.3l-0.1-1L512.3,695.3z M513.1,741.4h-0.8l-0.1-2h0.7l3.1-4.3v-2.4l0.8,0v2.8l-3.1,4.3H513L513.1,741.4z M506.2,712.1
l-0.1-0.2l0.6-0.1l0.3,0.7l0.9,4.4l-0.7,0.8l0.9,0.5l0.3,3.6l1.5,1.3l1.9,6.3l1.2,0.8l1.1,2l-0.6,0l-1.3-2.4L511,729l-1.9-6.3
l-1.5-1.3l-0.3-3.6l-0.9-0.5l0.7-0.8L506.2,712.1z M504.7,711.9l-0.1,0.4l-0.2-0.3L504.7,711.9L504.7,711.9z M493,770.6l0.1-0.2
l0.3,0.4l-0.1,0.2L493,770.6z M495.3,701.8l-0.2,1.4l0,0L495.3,701.8L495.3,701.8z M505.2,701.1l0.2,1.4l0,0L505.2,701.1
L505.2,701.1z M478.9,762.3l-0.5,0.7l-1.5,0.4l-0.3,1.1v-0.6l-0.2-0.1l0.3-0.9l1.5-0.4l0.5-0.7l2.3,0.4l0.8,0.3l0,0.4l-0.6-0.2
L478.9,762.3z M481.9,764.3l0-0.5l0.7,0.1v0.5L481.9,764.3z M482.8,765.8l2.9-0.3l0.7,0.4l-0.1,0.3l-0.4-0.2l-3.1,0.3V765.8z
M489.7,771l-1.9,0.3l-0.8-1.2h-0.8l-1.3-1l-0.5-0.7l0.2,0l0.1,0.2l1.3,1h0.8l0.8,1.2l1.7-0.3l0.5,0.1L489.7,771z M490.4,767.8
l-1,1.8l-0.1,0.4l-0.3,0l0.2-0.9l1-1.8h0.6l0.1,0.5H490.4z M492.5,769.5l-0.4-1h-1L491,768h0.9l0.4,1l0.8,0.3l-0.1,0.4L492.5,769.5z
M499.3,776.9L499.3,776.9l0.2,0.1l0,0L499.3,776.9z M502.7,777.8l0.4-1.5l2.5-1.5l0.3,0.3l-2.5,1.5l-0.4,1.5l0.3,0.4l0,0
L502.7,777.8z M506.4,787.5l-1.3-2.3l0.8-0.4l-0.2-0.5l0.6-0.6l-0.2-0.5l0.7-1.5l1-0.6l-0.7-1.3l1-0.3l0.5,0.2l-1.2,0.4l0.7,1.3
l-1,0.6l-0.7,1.5l0.2,0.5l-0.6,0.6l0.2,0.5l-0.8,0.4l1.1,2L506.4,787.5z M510.6,762.9l-1.7,0.5v3.5l-3.5,0.9l-4,1.9l-0.1,0.7
l-1.1,0.4l-1.4-0.3l-2.2,0.8l-1.7,2.1l0.4,0.9l-0.6,0.1l-1.5,1.9l0.1,1.6L493,778l-0.1-1.8l1.5-1.9l0.6-0.1l-0.4-0.9l-1,0.4
l-0.7-0.5l0.1-0.3l0.4,0.3l1-0.4l1.7-2.1l2.2-0.8l1.4,0.3l1.1-0.4l0.1-0.7l4-1.9l3.5-0.9v-3.5l1.7-0.5l1.1,0.4l0.6,0.6L510.6,762.9z
M533,764.5l-1.2-0.5l-2.4-1.6l-3.1-0.8l-8.5,1.7l-2.9-1.1l-0.7-0.9l-1.6-0.1l-1.7,1.1l-0.5-0.6l1.7-1.1h1.2l0.1-0.8l-1.6-2.4
l-0.3-1.4l0.9-1.3l-0.3-4.9l1.1-3.6l0.3-4.3v-0.1h0.8v0.3l-0.3,4.3l-1.1,3.6l0.3,4.9l-0.9,1.3l0.3,1.4l1.6,2.4l-0.1,0.8h0.4l0.7,0.9
l2.9,1.1l8.5-1.7l3.1,0.8l2.4,1.6l0.4,0.3l0,0.4L533,764.5l0.7,1h0L533,764.5z M540.6,789.6l-3.5-0.5l-2.8-2.2l-0.1-2.9l1.6-1.4
l0.5-1.3l-0.8-4l1.4-1.3l-0.1-1.5h0l0.1,1.5l-1.4,1.3l0.8,4l-0.5,1.3l-1.6,1.4l0.1,2.9l2.8,2.2L540.6,789.6l2.2,1.4h0L540.6,789.6z
M548.8,746l-5.6-0.1l-2.9,1.1l0,0l3.3-1.3l5.6,0.1l4.2-1.5l-0.1,0.1L548.8,746z M556.6,730.8l-0.1-0.1l2-0.1l0,0.1L556.6,730.8z"
            />
            <path
              id="region-sco"
              onClick={selectRegion("sco")}
              className={getClassNames(
                "sco",
                selectedRegion,
                markers.find((marker) => marker.id === debugId)
              )}
              d="M338.9,426.8l1.1,1.5v1.4l-1.5-1.5l-0.2-1.1L338.9,426.8L338.9,426.8z M327.6,406.3l3.9,2.2
	l2.5,2.6l1.4,3.9l0.7,3.8l-1.6,2.1l0.1,1l2.7,1l1.6,2.2l-2.7,2.4l0.5,0.9l2.5,1.4l-0.9,2.3l1,2.8L339,436l-2,1.8l-1.3-0.6l-1.5,0.2
	l-1.8,1.1l-3.7-0.4l-0.7-0.6v0.4l-0.4-1l-3.3-2l-0.8-3.6l-1.8-0.9l0.8-5.6l-0.3-1.5l-2.9-4.5v-2l1.9-5.5l2.2-1.1l0.9-2l1.8,0.1
	l-0.5-0.6l0.5-0.8L327.6,406.3L327.6,406.3z M298.6,405.4l0.8,0.8l-1.2,1.4l0.6,1.2l-0.6,1.4l0.9,0.2l-1.1,0.9l0.5,1.1l-0.3,0.9
	l-1.8,1.5l-0.4-1l0.6-1.4h-0.7l-0.2-1l1.2-1.8l0.5-1.9l0.5,0.2l0.3-1.4l-0.6,0.2l0.2-0.7l0.5,0.1L298.6,405.4L298.6,405.4z
	 M350.2,398.3l0.6,3l-0.5,1.4l-0.8,1l-0.3-1l-1.8,1.2l-0.3-1.3l1.3-3.5L350.2,398.3L350.2,398.3z M332.6,383.6l1.4,0.3l3,3.4
	l2.2,1.2l0.2,0.9l-0.8,1.2l1.7,0.4l0.3,2.3l2.1-0.5l0.2,3.8l1.3,4.5l-1.6,1.7l1,1.3l0.3,1.5l-1.6,0.9l-1.7-1.4l-0.7-3l-2-1.9
	l-1.4,0.6l-0.3-3.4l-0.8,0.1l-0.4-1.2l0.6-3.6l-2.1-0.8l-2.9-3.9v-1.3l-0.5-0.5L332.6,383.6L332.6,383.6z M273.2,382.1l0.5,0.5v1.5
	l-0.8,3.3l0.8,1.1l1.1,4.7l-0.1,3.2l1.2,3.3l2.4,2l-0.4,2.4l1.5,2l-0.7,1.6l0.6,0.9l0.4,0.6l0.4,1.3l-1.9,1.8l-0.6,0.4l0.2,0.4
	l-0.7,0.4l-0.5,1.7l-1.1,0.5l0.3-1.2l-3.9,2.5l-1-0.6l-1.3,0.8l-0.5-1l-1.3,0.3l-0.3,0.4l0.5,0.7l-1.9,3.2l-1.1,0.3l-0.6,1.2
	l-2.8-0.3l-0.1-0.7l-1.6-0.3l0.6-2.1l-0.3-1.1l2.1-3.5l2.1-0.1l0.2-1.6l-1.7-4.1l-1.5-1.4l-1.8-0.1l0.1-1.5l0.7-2l1.7-0.4l1.9-1.3
	v-1l-1.5-1.4l-2-0.2l-1.5,0.6l-1.4,4.1l-2.7,4.3l-4.6,3.9l-1.4-0.7l-0.2-1.2l-0.9-0.7l1.7-1.4l0.2-1l-0.7-0.5l0.6-1.8l1-1.3l1.2-0.5
	l-0.5-0.5l0.7-1.5v-1.3l-0.3-0.5l-1.4-0.2l-0.1-0.6l1.8-1.8l0.2-1.5l-0.6-0.5l0.6-0.5v-2.3l1.3-0.3l0.3-0.6l1,0.9l1.3-0.7l0.7-1.3
	l3.5-2.5l0.4,0.6l0.2,1.2l2-0.3l3.2-2.7l0.8-1.3l1.4-1.6l1.5,0.2l0.7-1L273.2,382.1L273.2,382.1z M271.9,360.2l1,1.4l-1.6,2.9
	l-0.4,1.7l-2,2.2l1,0.8l-0.5,0.7l0.3,0.3l-1.5,1.7l0.3,0.8l-0.5,0.7l-0.3-0.6l-1,0.3v1l-1.5,1.2l-0.5-0.7l0.5-0.9l-1.3,0.1l-0.1-0.7
	l-0.1-0.3l1-0.7l0.6-0.5l0.1-1.1l-1.2,0.8l-0.6-0.3l0.8-0.6l0.1-0.8l0.8-0.1l0.1-0.6l-0.5-0.2l-0.1-0.8l0.5-1l0.3-1l1-1.4l1.5-0.8
	l-0.1-0.6l0.8-0.3l1.2,0.5v-1.5L271.9,360.2z M299.5,358.3l1.4,2.3l-0.4,0.7l0.4,1.3l-4.2,7.2l-1.4,3.5l-1,0.4l-1.7,4.5l-0.6,0.6
	l-0.4-0.3l-1,3.2l0.4,0.2l-2,4l0.2,1.7l-0.9,1.2l0.3-1.2h-0.8l0.1,2l-0.5,0.6l-1.3-0.6l-0.8,0.6l-0.9,3.6l0.7,0.9l-0.8,1l-0.7,2.8
	l-2.9-0.7l-1.7,0.3l-0.2-0.6l-1.6-0.6l-1.4-2.8v-3.6l-0.6-1l0.7-3.4l0.7-0.4l1.9-3.9l1.1-0.8l6.3-1.4l0.6,0.3l2.1-1.3l-0.2-1h-0.5
	v0.5l-1.2,0.1l-0.3,1.1l-1.3,0.4l-3.4-1.7l-0.8,0.2l0.4-2l2.1-2.8l0.8-2.3l5.6-4.8l1.7-1.2h1l-0.1-0.8l1.2-0.8l0.2-0.9l1,0.3
	l0.5-1.1l1.9-1.2v-0.9l1-0.5l0.5,0.4l-0.1-0.9L299.5,358.3L299.5,358.3z M300.7,352.6l1,1l-0.3,0.7l0.5,2.1l-0.7-0.1l-1.1,1.1
	l-2.8-0.1l-0.5-1.1l0.5-1l0.4,0.2l0.6-1.4L300.7,352.6z M305.8,349.2l1.1,0.8l0.2,1.8l-1.5,1.4l-0.5-1.7L305.8,349.2L305.8,349.2z
	 M303.9,344.7l0.5,0.6l-0.1,1.2l0.9,0.8l-1,3.2l0.3,2.8l-0.4,0.5l-0.9-0.5l-0.1-1.5l-0.9-1.2l0.6-2.2l0.2-0.9l0.2-2.1L303.9,344.7
	L303.9,344.7z M306.8,339l0.2,2.2l-1.1,0.9l0.5,1.6l-0.9,0.4l0.1,0.6l-0.8,0.7l-0.8-1.2l0.2-1.7l-1-0.2l-0.2-0.6l2.2-2.8l1,0.5
	L306.8,339L306.8,339z M257,335.6l0.1,1.8l-1,2l-1.4,1.3l-1.2-0.3l-0.3-0.5l1.4-1.5l-0.8-0.7L257,335.6L257,335.6z M312.8,327.2
	l0.6,0.4l-0.6,0.2v0.8l-3.4,4.5l-0.5,0.3l0.4-0.9l-0.6-0.4l-1.6,0.2l1.6-3.2h1.2l0.9-0.7L312.8,327.2L312.8,327.2z M261.9,318.9
	l0.4,0.1l1.3-0.1l0.5,0.9l1.7-0.8l0.7-0.3h0.7l2.8,2l1,0.2l1.3,1.1l-1.2,1l-1.7-0.3l-0.3,0.7h-0.7l-0.5-0.6l-0.5-0.2h-1l0.3,0.8
	l-0.5,0.5l-0.5-1.8l-1.4-0.6l-0.5,0.6l-0.3-1.2l-0.7,0.5l-0.9-0.2L261.9,318.9L261.9,318.9z M317.3,311.8l-5.1,7.2l-5.1,4.2h-0.7
	l1.4-1.7l0.3-2.1l-1.4,0.3l3.9-1.9l4-4.7l-0.1-1.1l0.9,0.6L317.3,311.8L317.3,311.8z M233.7,312.2l0.9,1.5l0.9,0.1l0.1,1.9l-0.6,0.4
	l-0.7,0.2l-0.9-0.3l0.4,1h-0.7l-0.7-1l-1.7,0.4l-0.4,0.9l0.9,0.9l-1,2.1H229h-1l-0.7-0.4l-1.2,0.5l-1.2,1.6l0.4,1.5l-0.4,1.8
	l-1.2,0.5l-1.2-0.3l-0.6-1.5l-0.9-0.3l-1.5,0.7l0.3-4.1l-0.3-0.7l-0.4-1.1l0.2-0.4l0.7-1.5l1-0.2l0.4-0.8l1.2-0.3h1.1l1.6,0.4l0.2,1
	l0.7,0.1l1.1-1.4l2.5-1.3h0.7l1.2-0.2l0.7-0.2l0.4-0.3L233.7,312.2L233.7,312.2z M273.2,300.9l0.6,0.3l0.2,1.1l3,0.6l0.5,1.2
	l-0.6,0.9l1,0.9l1.3,0.2l1.2,2.4l1.5,1.2l2.2,6.6l0.9-0.4l1.3,0.9l0.7-0.7l2.7,0.1l2.1,1.5l0.4-1l1.9,0.3l1.9,2.9l1.5,0.8l1-0.7
	l1.1,1.9l0.8,0.1l1.1,1.1l0.3,1l1.4-0.5l0.4,1.1l-0.4,2.8v0.8l-0.9,2.8l-0.8,0.8l-2.4-0.8l0.5-2.7l-2.1,0.5l0.7,0.6v1l-1.4,0.4
	l-0.2,1.2l-2.1,1.8l5.2-2.1l1.1,0.2l-0.3,1.4l-3.2,3.3l-5.3,2.9l-0.2,0.6l-2.7-0.5l-0.9-0.8l1.6-1.3v-0.8l1-0.4l-0.3-1l-1.1,0.4
	l-0.2-0.6l-1.7,1l-1.9,2.9l-1.8,0.4l-1.7-0.5l-0.6,1.1l-2.9,2.2l-0.9,0.1l-1.3-1.2l-0.6,0.5l-2.4,0.1l-1,1.4h-0.8l-0.8-0.7l-0.9,0.5
	l-1.4-0.7l-1.4,0.9l-1.5-0.3l-0.9,2.4l-0.6-0.1l-0.7-0.5l-0.9-0.3l-0.8,0.4l-1.2-0.3l-1-0.9l-0.5-0.4l-1,0.1l-0.9-0.4l0.5-1.4
	l-0.1-1l1.5-4.3l3.8,0.3l0.6,0.4l0.9,0.5l0.2,1.4h-1l1,0.6h1.7l0.1-1.1h-0.6l-0.3-1.1l0.3-0.6l1-0.1l2.6,1.3l0.2-0.5l0.5-0.5
	l1.4,0.5l1.8-0.5l1.5-0.2l2.6-1.5l0.7-0.2l2.2-0.9l0.6-1.2l-0.6-1l1.1-0.2l-1.6-0.8l-2.2,2.1l-1.4,0.5l-1.7,0.6h-2.5l-1.7,1
	l-1.2-0.2l-1-1.7l0.2-1.4l3.5-3.1l0.2-1.7l0.6-0.4l-0.4-0.8l1.7-1.5l2.2,0.4l1.8-0.5l0.7-1.2l1.6-0.6l0.4-1.2l0.8-0.3v-1.3l-1.1-0.4
	l-1.5,1.2l-3.8,0.7l-1.2,0.8l-0.6-0.4l-0.4-0.6l-0.9-0.1l-0.2-0.7l0.7-0.4l-0.6-1.1l-1.5-0.6l-0.8-0.4l-2.7-2.2l-3.6,0.7l-0.5-0.7
	l-0.9,0.1l-1.9-0.9l0.2-1.9l2.1-0.6l1.4-2.3l-2,0.3l-0.6-3.5l0.8,0.5l1.1-0.6l1.4,0.4l0.8-1.3l1.5,1.2l0.4-0.8l-0.1-2.6h0.8l1,0.3
	l0.1-1.2l2,0.2l1.2-1.3L273.2,300.9L273.2,300.9z M251.5,296.8l1.2,0.4l0.1,1.5l-2.9,6.1l-0.5,0.5l-1-0.9l-0.2,2.4l-1.5,1l-1,0.9
	l-0.8,0.4l-1.2,0.1l-0.8,0.9l-1-1.5l-0.8,1.8h-0.6l0.2-0.9l-0.4-0.2l-1.6,0.2l-0.2,1.8l-0.9,0.6l-0.3-1.5l-0.6-0.4l0.5-1.2l0.5,0.6
	l1.2-1.8l0.2,0.7l1-0.2l-0.1-0.8l0.8-0.1l0.6-1.9l1.1-0.4l0.4-1.9h0.8l1-0.8l0.8-0.6l-0.1-0.7l1.3-0.4l1.2-1.9l1.5-0.5l0.3-0.7
	l0.5-0.4L251.5,296.8L251.5,296.8z M177.8,285.6l2,0.7l-1.6,0.7l-1.5-0.8L177.8,285.6L177.8,285.6z M264.5,278.8l0.6,0.1l0.9,0.6
	l1.2-0.3l0.5,1.1l-0.2,0.9l-0.5,0.1l-0.6,0.5l-0.8-1.2l-0.6,0.8l-0.7-0.2l-0.5-1.1l1-0.5L264.5,278.8L264.5,278.8z M179.1,281.1h0.4
	l-0.7,2.1l0.4,0.9l-1.3,0.7h-0.7l-0.3-0.7l0.6-1.2l-0.7-1.1l0.7,0.2L179.1,281.1L179.1,281.1z M182,277.4l1.4,0.7l-0.9,0.9l-1.8-0.2
	l0.6-0.7l-0.3-0.5L182,277.4L182,277.4z M272.4,267.7l1.4,1.1l0.5,1.4l-0.3,4.1l-1.3,2l-1.5,0.1l-2.9-1.6l-0.2-2.1l1-1.3l2-0.1
	l0.2-1.7l-0.6-0.7L272.4,267.7z M185.2,272.7l1.7,0.4l0.6,1.5l-0.5-0.3l-0.1,0.9l-1-0.4v0.5l-1.1-0.8l-0.4-1.4L185.2,272.7
	L185.2,272.7z M182.5,267.3l2.3,0.4l0.2,0.4l-0.6,0.4l2.7,0.3l0.9,0.7l-2.2-0.4l-1,1.3h1.9v0.5l-1,0.6l-0.7-0.2l-0.2,0.5l-1-0.1
	l-0.8-1.5h1v-0.8l-2.3-0.6l1.3-1L182.5,267.3L182.5,267.3z M260.5,254.4l2.3,1.4l0.6,1l1.4,0.4l0.8,1.9l-1.7,0.5v0.6l1.9,0.2
	l0.4,0.5l-1.4,5l-1.6,1l-0.5,1.2l-1.1,0.8l-1.2-0.1l-1-1.5l-1.5-0.4l-0.5-2.4l-1.1-0.2l-4.1-3.8l3.1-2.6l0.8-1.5L260.5,254.4
	L260.5,254.4z M246.2,253.3l4.3,0.3v1.4l1.2,0.7l0.3,0.6h-1.1l-0.4-0.5l-1.4,0.5l-0.3-1.5l-1.1,0.1l-0.4-0.1h-0.6l-1.1,1.3l-1.8,0.5
	l-1.3-1.1l1.9-1.5l1.7,0.1L246.2,253.3L246.2,253.3z M190,254.6l1.8,0.9l-0.4-0.1l1.1,2.7l-1.1,1.2l1.4,1.8l0.6,1.1l1.1,0.3l0.1,1.2
	l-1.2-0.1l-0.5,0.4l-0.7,0.4l-0.8,1l-0.5,0.2l-0.3,0.4l0.7,0.9l-0.5,0.5l-0.7-0.3l-0.4,0.7l-1.8-0.3l0.1-0.8l-0.6-0.2l-0.6,0.7
	l-0.5,0.5l-2.8-0.7l-0.5-0.6l0.7-1.8l1.7-0.1l0.7-0.8v-1l0.2-0.7l-0.6-0.5l0.8-0.8l-1.4-0.9l2.7-0.2l1.5-0.8l0.8-2.5l-0.4-1.7
	L190,254.6L190,254.6z M193.9,254.3l1.1,1.2l-0.9,1.1h-0.7l-0.4-1.4l0.3-0.4L193.9,254.3z M199.2,251.1l0.2,0.4l0.5-0.5l1.6,0.1
	l-0.6,2.8l0.1,0.5l-0.9,1.1l-1.2-1l0.3-1.3l-0.7-1.4l0.2-0.6L199.2,251.1L199.2,251.1z M268.2,242.2l1.5,0.3l0.1,1.3l-0.8,0.8
	l-1-0.5l0.4,1.9l-1.2,0.7l-1-0.3l-1.1-2.2l1.8-1l0.3-0.1L268.2,242.2L268.2,242.2z M281.5,223.8l2.9,0.8l1.5,1.3l0.1,2.9l-1.2,0.9
	l-2.5,0.1l-0.8-1.5l-0.8-0.2l-0.9-2.3l0.4-1.5L281.5,223.8L281.5,223.8z M206.4,213.8l-0.5,1.1l0.5,0.3l0.1,0.5l-1,0.7v0.3l-1.9-0.6
	l0.5-0.4l0.3-0.3l-0.2-0.7L206.4,213.8L206.4,213.8z M195.5,213.4l1-0.1l2.5,2.5l0.9,0.1l0.2,0.1l-0.7,0.4l0.6,0.8l0.4-0.4l1.1,0.8
	l0.2,0.8l-1.5-0.5l0.5,0.6l0.8,0.5l0.4,0.5l1.6,1.5l1,0.1l-0.1,0.9l-0.6,0.7h-0.9l-0.6,0.8l-0.6-0.4l-2.1-0.9l0.2,0.4l1.5,1.6
	l1.1,0.3l0.9,0.1l0.2-0.6l0.5,0.2l0.5,0.4l-0.5,0.6l0.6,0.5l-0.1,1.1l1.4-0.5l0.4,0.8l-0.7,0.5l0.3,0.4l-0.4,1.2l-1.4-0.1l-1.7,2.5
	l0.7,0.4l-0.1,0.9l-0.9,0.6l0.2,1.1l-0.3,0.6l-0.6,0.6l-0.8-0.2l-0.1,0.5l-1.4-0.5l0.4,1.2l1.6-0.1l0.2,0.7h0.6l-0.3,1.9l1,1.2
	l0.4,2.3l-1.5,1.6h-1l-1.2-0.7l-0.5,0.1l0.1,0.5H198l-0.7-0.1v-0.5l-1.1,0.2l0.1,0.4l1.5,0.7v0.5l0.7,0.4l1.7-0.7l2.7,0.8l-0.4,0.9
	l0.7,0.3l0.7-0.1l0.2,0.8l0.9,0.2l-0.4,1.2l-1,0.3l0.3,1l-1.9-0.4l-1.3-1.1l-1.7,0.7l-4.2-0.2l-0.5-0.8l-1-0.1l0.3-0.8l-0.5-1.1
	l-1-0.3l0.7-0.9l-0.9-1.2l0.7-1.4l-1.3-3.8l0.2-2.7l-0.7-0.5v-1.8l-1.4-0.6l1.3-0.2l0.8-1.3l0.1-2l-0.4-0.4l0.5-0.1l0.1-0.9
	l-1.2-0.4l0.3-0.4l0.8,0.5l0.3-0.8l0.8-0.2l0.3-1.2l-0.1-4.9l-1.8-4.7l0.3-0.5l1,0.1l0.7-1.2l1.4-0.2l-0.2-1.6l0.7-0.4L195.5,213.4
	L195.5,213.4z M282.6,204.1l-0.3,1.8l-1,2.8l-1.6,1.6l-0.4,1.4l0.8,2l0.1,2.6l1,2.1h0.6v1.5l-1.8,3l-1.9,0.5l-1.1-0.7l-0.6-1.9
	l0.2-3.4l-0.5-0.5l0.4-0.6l-0.6-3.8l1.6-4.5l0.6-0.3l2,1l0.4-1.2l-1.2-0.5l-0.2-1.1v-1.3l0.7-0.9l0.8,0.8l1.1-0.3L282.6,204.1z
	 M206.8,203.1l1,0.1l-0.1,0.6l0.8,0.6l-0.5,0.2l-0.6,0.2l1,0.4l-1.5,1.8l-0.8-0.5l0.3-1.3l-1.2-0.7l0.3-1h0.3L206.8,203.1z
	 M197.2,204.5L197.2,204.5l2,0.9l0.8-0.5l1,0.6l1.1,0.4l0.6,0.3l0.3,0.2l0.6,0.3l1.6,1.5l0.5-0.1l-0.7,0.7l-1.5-0.8l-0.4,1.2
	l1.1-0.1l0.4,0.6l-1.5-0.1l-0.6-0.1l0.1,1h-0.4L202,210h-0.6l0.4,0.5l0.1,0.4l0.7,0.7l1.2,0.2l-1.2-1l2-0.1l1.1,1.3l0.1,0.6
	l-0.1,0.5l-0.2,0.4l0.5,0.3l-1.2,0.6l-1,0.3l-0.4,0.5l-1.9-0.7l0.5,1.2l-1.6-0.6v0.8l-1.3-0.1l-2.3-2.5l-1.1,0.1l-0.6-0.6l-0.6,0.3
	l-1.5-0.8l0.5-0.1l0.1-0.8l-0.3-1l-0.9-0.8v-0.8l0.6-0.4l-0.8-0.3l0.2-1l2-0.5V205L197.2,204.5L197.2,204.5z M283.2,193.6l0.6,0.2
	l0.4,1.6l-1.6,6.7l-1.3-1.9l0.3-0.9l-0.6-0.9l1.4-0.4l-0.6-2.8l0.4-0.6l0.4-0.1l0.8,0.6l0.1-0.6l-0.6-0.3L283.2,193.6L283.2,193.6z
	 M177.6,199.2l0.5,0.3l-0.3,0.6l0.9,0.5l1.4-1.2l0.7,0.4l-0.5,0.7l0.5,0.3l-0.9,0.2l-0.4,1.2h-0.9l0.7-0.3l-0.2-1.2l-0.7,0.5
	l-0.9-0.8l-1.2,0.4l0.4,0.5l-1.2-0.2l0.3-1l0.5,0.4L177.6,199.2L177.6,199.2z M260.4,180l1.3-0.9h0.8l0.3,1.1l0.1,0.6l2.5,2.3l0.9,4
	l2.7,0.6l2.6,5.5l0.8-0.1l-0.7,2.5l0.5,2.3l-0.5,5.4l0.8,1.7l-0.6,6.4l-1.9,2.3l-0.6-0.5l-0.6,0.9l0.6,0.5h0.7l0.9-0.6l1.2,0.1
	l1.1,1.3l-0.5,2.4l-0.8,0.4l1.8,3.4l0.8-0.4l0.6,0.6l-0.8,1l0.7,1l-0.4,0.8l-0.5-0.4l-1.5,0.4l-1.3,1.9l2.3-1.7h3.8l1,2.4l-2.4,2
	l0.2,0.8l3.3-2.2l1.4,1.8l3.6,1.4l0.2-0.4l1,0.1l1.2,1.2l-0.5,1.3l0.9,0.2l1-0.3l1.4-1.5l2.9-0.1l2-1.6l1.6-0.5l2.7,0.4l-1.2,1.3
	l4-0.5l1.2,1.1l-1.3,2.3l0.3,1.3l-0.5,1.8l-3,2.8l-3,1.7l-1,0.4l-1.3-1l0.3,1.6l-0.4,1.4l0.3,0.5l0.8-0.8l0.2,0.9l-0.7,1.2l-0.1,0.4
	v1.3l-1.4,1.6l-1.7-0.1l-1.2,2.8l-0.8,0.3l-0.7,1.5l0.2,0.8l-1.4,0.9l-0.6,1.2l-2,1l-1,0.4l-1.8,1.3l-1.1,0.2l-0.4-1.2l-0.5-0.9
	l-0.4-1.3l1-2.8l0.7-0.3l-0.1-0.5l0.7-3.2l0.8-0.2l-0.4-0.7l-0.8,0.1l0.4-1.1l1.6-0.9l-0.2-0.6l0.5-0.5l1.5-0.6l1.1-1.2l0.8-1.1
	l3-0.7l1.2-1.6l-1.7,1.1l-1-0.3l-0.1,0.8l-1.5,0.2l-1.3-0.6l-3.6,1l-0.6-3.8l-1-0.7l-0.9-2l-0.2,0.8l0.9,2.1l-0.3,1.5l-0.6,0.9
	l-0.6-0.2v1.3l-2.2,5l-1.9-1.5l0.6-3.5l-0.4-2.1l-0.8-0.6l-0.4,0.8l-0.9-0.3l-0.4-1l-0.4,0.2l-0.3,0.5l-0.7,1.7l-2.5-0.3l-6.4,2.2
	l-0.6-0.2l0.5-1.4l1.7-1.7l0.2-1.3l-0.9-0.2l-2.6,1.7l-1.5-0.8l-1.4-2.4v-1.4l2-0.4l0.4-2l-1,1.2l-2.6,0.4l-1.4-1.1l-0.1-1l-1.5-1.5
	l-0.6-2.7l-1.4-0.9v-2.1l1.6-1.4l0.6,0.2l0.6-0.7l0.6,0.1l-0.1-1.6l2.8,1.6l1.9,2.7l1.6,0.6l-2-3l-1.7-1.7l-0.5-0.5l-0.7-0.3
	l0.3-1.3l-0.8,0.9l-1.9,0.1l-0.8,1l-0.3-1l0.6-1.2l-1.7-1.2l0.3-0.8l-0.6-0.7l0.3-1.7l-0.6-1l-0.5,2.4l-1.6,1.8l0.4-1.4l-0.5-0.3
	l0.6-1.1l-0.7-1.1l-0.1,1l-0.6,0.4l-0.7,0.6l-0.6-0.6l-0.7,0.2l0.4,0.4l0.2,5.4l-0.5,0.7h-0.7l-0.8-0.3l-1.8-1.7l-2-0.9l-1,0.4
	l-1.9-1.9l-1,0.1l-1.4-2.3l0.6-0.7l-0.9-2.5l-1.3-0.6l-0.2-0.6l-1.4,1l0.2-2.3l0.5-0.6l-0.2-1.5l1.5-0.1l2.4,1l-1.4-1.7l-0.4-2.3
	l0.1-1.9l2-1.6l0.8,1.9l1.1,0.9l1.3,2.3v1.2l0.9,0.2l0.8,1.8l0.8,0.2l0.1,0.6l0.8-0.4l0.1-0.9l0.5,0.1l0.4-0.9l-0.4-0.5l-0.6,0.5
	l-0.7-0.5l-0.1-0.5l0.6,0.2l0.1-0.7l-0.6-0.6l-0.8-3l-0.6,0.2l-0.1-0.8l1.8,0.1l1.2-0.9l1.8,1.8l0.6-1.4l-1.5-1.1l-1.3-2.3l-2.3-1.8
	l-0.5,1.1l-0.3-0.2v-1.1l0.8-0.5l0.5-2.9l-0.1-3l1.3,0.3l2.1,2l0.9,1.9l0.2,2.8l0.8,0.5l0.9-0.4l2.2,1.6l2,2.4l0.7,1.7l2.2-2.2
	l-1.3,3.8l0.6,0.9l0.2-1.6l1-1.5l1.2-0.5l-0.4-1.5l0.5-0.7l1,1l0.5,2l1.6,0.8l0.6,0.5l0.4,0.2l0.5,1l0.4-0.6l-2.8-3.3l-2.1-5.4
	l0.3-0.9l0.8,0.7l1.3-2.6l-0.6-0.3l-0.4,0.6l-1.2-0.1V191l-2-3.5l0.4-0.7l1-0.3l-0.2-1.2l1.6,0.1l1.9-1.3l0.5-1l-0.3-0.5l-0.3-1.6
	l0.6-0.5L260.4,180L260.4,180z M198.8,180.3l-0.2,2.4l1.4,0.7l0.2-0.3l1.8,0.6l0.9-1.7l-0.1-0.4l-0.7,0.3l-0.2-0.7l3.1,0.1l0.7-1.3
	l0.9,0.6l-0.4,0.6l0.9,0.1l1.5,1.5l-0.4,0.9l0.5,0.3l-0.4,0.6l0.2,0.2l0.8,0.1l1-0.8l0.6,0.1l1.3,0.9l0.1,0.6l1,0.6l0.4-0.3l0.8,0.9
	l-2.4,3.9l-2.1-2.1l-0.6-0.2l-0.3-0.1l-0.2,0.2l-0.5,0.8l0.5,0.7l-0.3,0.6l0.1,0.6l0.1,0.8l-0.4,0.5l0.6,0.5l0.6-0.6l0.9,0.3
	l0.9-0.8l0.8,0.3l-1,3.2l-1.8,1.1l0.7,0.5l-0.4,1.5l0.4,0.7l-0.9,1.2v1l-0.1,0.7l-1.8,0.1l-1.2,0.1l-1.6-0.1v0.8l1.4,2.5l-0.3,0.7
	l-1.6-0.6h-0.8l-1.2-0.8l-0.6-0.1l-1.2-0.5l-0.8,0.5l-1.8-0.8l-0.5-1.1l-0.8,0.5l-1.5,0.4v-3l-2.5-2.7l-0.2-0.2l-1.5-2l-1-0.1
	l-1.8-0.3l0.1-1l-0.8-0.3l-0.5-0.6l-0.6-0.9l-0.8,0.2l-0.4-1.3l-0.9-0.2l0.7-1.1h0.6l0.7-0.2v-0.9l-0.6-0.5l1.7-0.8l-0.3-1l0.8-1
	l0.3-0.4l-0.6-1l1.2-0.5l1,0.6l0.7-0.5l0.4,0.2l1.5-0.1l0.7-0.4l0.7-0.5h1.3l0.2,0.4l0.2,0.5l1.5-0.7l0.4-0.7l-0.5-0.8h0.6l0.1-0.6
	l0.7-0.2l0.1-1L198.8,180.3L198.8,180.3z M200.2,177.6l0.9,0.6l-0.3,1.9l-1.3-0.8l-0.1-1.2L200.2,177.6L200.2,177.6z M208.1,175.4
	l1.3,1l-0.6,0.7v1l1.9,1h-1.2l-1.5-1.5l-0.5,0.3l0.6,1.2l-0.7-0.3l-0.3,1l-3.1-0.3l1.7-3.6L208.1,175.4L208.1,175.4z M212.7,174.1
	l2.4,2.6l-2.6-1.4l-0.2-0.8L212.7,174.1z M213.3,171.3l0.7,1.8l-0.4,1l-1.5-2L213.3,171.3z M203.9,170l0.9,0.1l1,1.6l-1.4,1.3
	l-1.4,0.1l-1.5-1.4l0.6-1.1L203.9,170L203.9,170z M237,158.9l3.4,1l-0.3,0.5l0.6,0.2l0.2,1l-1.9,0.8l-0.2-0.4l-0.6-0.1l-0.9-0.1
	l-0.4-0.7l-0.2-0.6l-0.4-1L237,158.9L237,158.9z M217.8,153.8l1,0.4l0.4,1.8l-0.4,0.7l-0.2,0.5l-0.9,1.5l-2.3-1.7l-0.4,1.4l-1.5,0.9
	v-1.7h-0.7l0.1-0.6l0.7,0.2l0.1-0.5l1.6-0.2l0.5-1.8L217.8,153.8L217.8,153.8z M210.1,140.1l1.5,1.2l0.5,2l-0.3,0.9l-0.8,0.1l-1-1
	l-1.2,0.3l-1.2-2.1l0.6-0.8L210.1,140.1L210.1,140.1z M225.2,113.5l0.4,0.5h2.2l1,2.1l1.1-0.1l0.3,1.1l0.9,0.5l0.6,0.5l0.3,0.4v2.1
	l-1.3-0.7h-4l-0.5-1.5l1.1,0.4l-0.1-0.7l-0.4,0.1l-0.7-1.1v-0.5l-0.7-0.8L225.2,113.5L225.2,113.5z M264.9,83l1,1.3l1.3,0.6
	l-0.2,1.4l2.8,2.7l-0.9,1.1v1.4l1.7,2l-0.1,1.5l-1.3,1.1l-1.5,4.6l1,1.7l2.2,1.5l-2,0.4l-1.7,2.5l-0.7,0.1l-0.6,1l0.1,1.6l-2.1,0.6
	l0.3,0.5l-0.6,0.8l0.3,0.7l-0.4,0.6l-2.4,0.4l-0.4,1.1l0.5,1.5l-1.2,1.5l2,2.5l0.6,0.3l0.5,0.1l2.3-2l0.7,0.2l0.8-0.6l1.3-2.3
	l1.9-0.6l0.5-1.2l0.6-0.2l1.4,0.6l-0.3,1l-0.9,0.5l0.4,0.8l-1.1,1.4l0.7,0.9l-0.4,0.8l-0.7,0.9l-2.2,1v1.1l-2.1,1h-0.9l-0.2-0.8
	l-1-0.3l-0.9-1.9l-1.7,0.3l-0.6,0.6l-0.2,0.2l-0.2,0.8l-0.7,0.3l-0.7-0.3l-0.3-1.6l-0.6,0.4l-0.9-0.3l-0.4-1.1l-0.5,0.6l0.7,1.9
	l0.4,0.3l-0.4,1l0.6,0.2l0.7,3l-0.4,0.6l0.3,0.5v1.4l-1.2,0.3l-1.1-0.1l-1.1,0.1l-2.8-1v0.3l0.3,0.3l1.1,0.5l0.5-0.2l0.9,0.8l-1.8,1
	l-0.9,1.3l-1.2-0.1l-1.5,0.1l-1.1,0.5l-0.1,0.4l-3.3,0.7l0.9,0.9l0.2-0.8l2.3,0.1l1.1-0.8h1.6l0.7-0.2h1.8l0.8,0.7l0.5-0.9l-0.7-0.4
	l2.3-1.4l0.2,1.1l0.2,0.6l-0.3,0.7l0.1,0.9l1,0.7l0.8,0.3l-0.8,1l0.8,0.4l-0.4,1.9h-3.8l2.1,0.8l2.7,0.2l-0.5,2.5l-1.4,1.1v1
	l-1.9-1.5l-0.8,0.2H254l-0.9,0.6l-0.4-0.7l-0.7-0.8l-0.2,1.1l-1.8-0.8l0.6,0.8l-1.4,0.2l-1-0.9l0.1,1.1l-2.8,0.1l3.3,0.7l2.7-0.3
	l1.5,1.5l-0.4,0.4l0.5,0.8l-0.4,1l0.3,0.6l-1.2,0.5l-0.2,2.8l-1.9-0.3l-1.1,1.6l-1.9-3.4l0.5,4.7l-1.5,0.3l-0.9,0.5l-0.4-0.4
	l-0.9-1.1l-0.9-3.2l-0.5,0.2l-1.3-1.5l1,3.3l1.3,2H242l-0.5-0.6l-0.8-0.3l-0.4,0.8l-1.5-2.1l-1.1-2.9l-0.6-0.2l-0.7-2.6l0.5-1.2
	l-0.6-2l1.6-2.9l1.4-1v-1.3H239v0.9l-1.9,1.6l-1.5,2.2l-2,1.1l-0.1,1l1.2,0.6l1.1,3.4l-0.2,1l1.1-0.1l2.1,3.8l-0.5,0.8l-1-0.3
	l-0.9,0.4l2.7,1.8l0.3,2.1l-2,0.2l-3-0.7l-0.9-1.1l-0.9-0.3l-1.9,0.1l0.8,1.2l0.1,1l-0.7-0.7l-0.7,0.4l0.5,1.7l0.9,0.3l0.3-0.7
	l0.7,0.4l1.2,3h0.9l0.3,1.8l-0.9,0.6l-0.6,0.1l-0.8-0.5l-1.5-1l0.6,1.8l0.2,0.5l-0.4,1.2l-1.3-0.6l-0.7-0.4l-0.6-0.2l-0.8,1.4
	l-0.4-1.3l-0.6,0.1l0.4,1.7v0.2l-0.6,0.3l-0.3-0.4H226l-0.1,0.6l0.7,2h-1.5l-0.2-0.4h-0.4l-0.2,1.2l0.6,0.4l-2.4,0.7l-0.4,2.3
	l-0.8,0.3l-1.2-0.6l0.6,1.5l-0.6,0.1l-1.8-2.8l-1.1-0.3l-0.1-0.6l-0.6-0.8l-1.5-1.2l-1.9-3.3l-1.1-0.2l-1.1-1.1L210,164l1.7-0.5
	l1.4,0.8l0.3,2.1l0.1-1l0.9,0.2l1.4-1.3h1.1l0.4-1.1l0.7-0.3l0.6-2.4l0.6-0.4l0.7,0.6l1-0.6l-0.3-2.3l0.9-0.7v-1.3l0.6,0.4l1.4-1
	l2.3,0.5l0.6,0.7l1.3,0.1l0.3-0.7l1.9,0.5l-2.7-1.8l-0.6-0.9l1.6-0.4l-0.4-0.8l-0.6,0.6l-3.5-0.8l-0.5-0.5l-1.4-1.2l-1.6,0.1
	l-1.9-1.8l-0.3,0.3l0.6,1.1l-1.8,0.3l-2.1-1.2l-0.1-1l-0.6,0.5l-0.8-0.2L213,146l-0.5-0.6l-0.8,0.9L211,146l0.3-0.9l1.2-0.6l0.4-2.4
	l2.1,1.4l0.2-1.5l1.2-1.1l2.5-1.2l1.5,0.2l1.7-1l-2.6,0.2l-3.8,1.7l-1.4-0.9v-0.6l2.6-0.9l-2.4-0.1l0.4-1l1.9-0.9l-0.4-1.2l-0.7,1
	l-1.8,0.8l-1.2,0.3l-0.8-0.7l-0.8-5.2h-0.6l-0.8-1l0.4-2l0.9,0.2l0.5-0.8l0.2-0.7l0.9-1.1l-0.4-1.8l-0.6-0.3l0.3-0.8l1.2-0.7
	l0.2,0.6l1.3,0.4l0.1-0.7l0.4-0.4l-0.9-0.8l0.8-1.5l-0.5-0.3l0.6-2.4l0.7-0.1v-0.7l1-0.7l-0.1,1.4l0.6-0.4l1.4,0.3l1.2,1.5l0.6-0.3
	l-0.2-1l0.5-0.5l1.6,2.2l1.2-0.1l0.4,0.6l-0.2,1.2l-2-0.1l-1.2,0.1l1.9,1.9l0.6-0.2l0.5,0.2h1.4l0.4,0.9h0.4l0.1-0.9l0.3-0.2
	l0.8-0.5l0.8-0.3l0.7-0.9l0.7-0.2l0.4,0.5l1.7,0.1l0.8,0.6l1.4,0.8l0.6-0.6l1.1,2l0.4-0.8v-0.6l-0.3-1l-0.5-0.1l-0.9-0.2l-0.6-0.5
	l0.3-0.9l0.9,0.2l0.2-0.5l-1.2-0.6l-1.4-1.8l-0.5-0.3l-0.8-1.5l-0.6-0.6l0.2-0.7l-0.8-1.8l-0.4-0.7L229,111l0.8-0.8l0.5,0.1l0.7-0.8
	l-0.6-0.9h1l0.8-0.7l1.5-0.5l1.4-0.7l0.3-0.9l1.4-1l0.9-0.1l1.2-0.5l0.2-1l0.6,0.2l0.4-0.6l0.7,1.4l1.1-0.4l0.4-0.3l0.8-0.4l1.5-1.4
	l2-0.1l-0.1-1l1.7-2.8l1.4-0.2l0.8-1.4l2.7-1.9l0.5-1.3l4.9-2.8l1.4-2.3l1.9-0.3l1.9-2.2v-1.1l-0.6-0.4L264.9,83L264.9,83z
	 M446.2,64.2l1.4,1l0.7,2l2.1,0.4l1.9-1.2l1.8-0.2l0.7,0.6l0.8-0.1l0.3-0.5l1.9-0.6l2.1,2.6l3.6-1l2.2,0.4l0.9-0.5l1.7,0.5l-0.8,1.8
	l-0.3,3.8l-1.8,0.7l0.8,1.3l-0.5,2.1l-3.9,5.3l0.5,4.6l1,0.9l3.1-0.7l0.5,1l-0.5,1.4l-0.1,2.4l-1.5,2.1l-0.4,2.3l-1.4,3.1l0.1,0.9
	l-2.8,2.4l-4.3,5.5l-2,0.9l-2.4,0.2L449,111l-3.6,2.3l-1.6,2.5l-1.4,1.9l-1.7,1.9l-0.7,2.1l-2.1,2.9l-6.4,4.7l-1.1,1.6l-1.9,0.7
	l-0.8,1.4l-2.4,2.3l-1,0.1l-1.2,1.6l-1.1,0.6l-0.8-0.4l-1.7,1l-2,2.2l-0.6,4l-1.7,0.4l-1.3,1.6l-2.9,0.7l-2.9,1.5l-1,3.3l0.5,1.4
	l-0.6,1l0.4,2.2l-1.1,1.5l-0.1,1.2l0.6,1.1l0.5,0.6l2.1,0.4l-2.6,0.5H404l-4.6,0.8l-2.1-0.5l-1.2-0.7l-0.2-0.7l-1.9-0.5l-3,0.1
	l-1.1,1l-1.1-0.1l-0.2,0.4l1.8,0.4l4.6-1l2.2,3.3l1-1.2l3.1,0.8l1.2,0.9l1.2,0.4l3.7-2.4l3.1-0.9l3,3l0.9,0.5l1.3-0.2l1.8-0.9
	l1.1-2.9l2.5-0.7L420,164l-5,7.3l-2.9,2.9l-1.1,3l-2.6,3.7l-0.9,0.4l-3-0.4l-0.4-1.6l-1.5-1.5l-0.8,0.2l-2.3,0.9l-2.9,2.9l-2.7-0.6
	l-1.1,0.1l-1.5,1.5l-1.8-0.1l-0.9,2.3l-2.8,1l-2.1,2.9l-1,0.5l-1.4,3.3l0.7-0.1l0.4-1.1l0.7-0.7l0.9-0.3l7.1-6.3l2-1l3.8-0.3
	l1.1,1.4l1.1,0.4l2.1-0.4l3.1-2.2l2.7,0.9l-1,1.8l-2,1.5l-3.7,4.8l-0.7,2.1l1.4,1.8l-2.7-0.5l-1.8,1.2l-4.8,7l-7.2,0.4l-1.2,0.3
	l-1.1-0.1l-0.5,0.5l1.4,0.7l1.5,0.7l4.3,0.3l1.6-1.2l2.1-0.6l2.8,0.9l1.9-3.2l6.2-4.5l0.3-0.9l-0.3-0.7l-1.7-1.3l3.1-2.5l1.7,0.1
	l4.4,1.9l3.8-0.4l1.6-1.1l2.2-2.5l2.2-0.8l1.5-1.5l1.3-1.5l4.9-1.3l1.1-0.5l3.3,0.8l3.2-0.3l2.1-2.1l-0.1-2.6h2.4l1.5-0.9l5.5-1.6
	l2.7,0.6l1.7-0.4l0.9,1.4l5.5,2.9l9.4,2.8l5.8-2.6l0.8-1.4l4.5-1l0.4,1.1l1,0.5l2.1-0.9l0.9,0.9l1.8,0.2l0.5,1l1.6-1.1l2,1l1-0.8
	l2.1,1.1l2.1-0.2l1.2,0.4l1.3,0.6l2.4,0.1l1.8-0.2l3,0.5l1.3-0.5l1.9,0.6l2.4-0.9l0.7,0.7l1.4-0.8l0.1-1.3l1.8-1l2.2,1.9l0.9-0.3
	l2.4,0.4l1.2,0.9l1.1-0.3l0.8-1.1l0.6,0.1l2.2-2.1l0.5,0.4l2.5-0.3l1.8,0.8l2.3-0.6l0.6,0.8l-0.2,1l0.7,0.5l1.4,0.4l1.5-1l1.4,1.3
	l2.4,4.6l3.7,2.3l-0.3,4.2l0.7,1.9l0.8,0.7l-0.2,3.2l0.5,1.2l2,1.4l-0.3,0.8l-0.8,1.1l-0.7,0.7l1.4,1.8l-0.6,1l-2.3,2.9l0.2,1
	l-0.7,1.2l-1.9,1.3v1.9l-3.3,2.8l-0.8,1.7l-2.9,2.9l-2.9,5.8l-2.4,5.9l-1.3,5.2l1.8,0.3l-0.2,2.3l1.9,1.5v2.4l-1.3,2.5h-2.1l0.1,0.7
	l-1.6,2.7l0.2,0.7l-4.2,5.8l-1.2,4.4l-1.7,1.4l0.8,1.9l-0.4,0.5l0.1,4.4l-1.2,1.6v1.5l-1.1,2.2l-1.9,2.2l-1.6,1.8l-0.9,2.3l-1.2,1.4
	l-2.1,0.9l-1.3,1.6l-1.1-0.2l-1.7,2.3l-1.5,4.9v0.7l0.7,0.5l-0.3,1.7l-1.8,1.8l-1-0.1l-1,1.6l-0.1,2.7l1.4,0.9l-2.5,3.9l-0.1,1.1
	l-1.9,2.4l-3.6,1.4l-1.5,2.5l-2,1.2l-1.8,1.9l-0.8,0.1l-1.5,4l-5.1-1.6l-1.2,0.5l0.7,0.6l-3.3,0.5l-1.4-0.7l-1.1,0.1l-2.8,0.6
	l-2.7,1.5l-2.9,0.8l-2,1.5l-0.1,1.3l-1.3,1.2l-0.9,0.8l-0.1-0.1l-0.9,0.2l-0.6-0.4l-1,1.5l-2.2,1.3l-1.1-0.1l-0.9,1.2l-2,0.3
	l-0.5,0.8l1.2,0.5l2.4-0.7l1.9-1.5l7.7-3.5l2.8-1.7l1.8-0.5l2.4-1.3l1.6-1.8l2.1-0.2l6.4,0.8l4.4-0.3l-0.2,0.1l-1,0.5h-2.5l-1.5,0.3
	l-0.4,7.7l0.4,2.8l1.3,0.7l0.2,0.7l4.5,1l0.8-0.3l2.1,1l1.8,2.8l2.2,0.8l0.7,0.9l-1,1.9l-1.1,0.3l-4.2,4.2l-6,2.7l-1.7,1.8l-1.4-0.5
	l-0.9,0.6l-2.2-0.8l-0.6-1.5l-2-0.8l-1.3-0.3l-1.5,0.8l-3.3,3.6l-2.2,1.2l-1.9,2.7h-0.7l-1.8,2l-1.8,0.8l-0.2,3.3l-1.2,2.6l-1.1,0.1
	l-1,0.8l-1.1-0.4l-0.7,0.4l-1-0.7l-1.7,0.4l-2.6,2.5h-1.2l-1.1,0.9l-1.6-0.1l-0.6,2.2l-1.3-1.2l-0.5,0.5H441V372l-5.5-1.3l-0.9-0.7
	l-2.3,0.3l-0.6-0.6l-2-0.1l-2.4,0.2l-1.5-0.8l-2.1-2.2l-0.5,0.5l0.9,0.7l1.4,2.6l4.3,1.2l1.3,0.1l3.8,1.7l10.4,2.6l1-0.1l1.8-1.2
	l1.3,0.4h1.6l2.3,2.2l2.8-0.8l0.3,0.7h1.1l1-1.1l1.5,0.4l2.2,2.7l2.7,1.7l2.1-1l2.4,0.6l3.9-2.6l1.2-0.2l1.5-0.4l0.6-3.8l2-2.5
	l1.5-0.5l2-2.4l2.4-0.5l1.2,0.8l1-0.3l2.5,0.6l1.2-0.5l3.1,0.8l1.4,1.6l0.5,1.5l1.5,0.9l1.3,1.8l2.8-0.2l2.4,1l1.8,0.8l0.4,0.9
	l1.4,0.7l1.1,0.7l0.7,1.9l3,1.7l0.1,0.5l4.5,1l2.4-0.9l3.3,1.8h2.3l0.7,2l-0.4,0.7l1.9,1.7l0.8,0.3h0.8l0.6,3.5l0.8,0.6l1.4,2.9
	l-2.1,1.3l0,0l-0.3,0.3l0,0l-1,1l0,0l0.2,2.9h-0.9l0,0l-0.6,0.5l0.2,1.1l-1.3,1.2l0,0l-1-0.2l0,0l-0.3,0.8l0,0v0.7l0,0l-1.8,0.8
	l0.7,1.3l0,0l-0.4,0.3l0,0l-1.2,1.1l0,0l-1.7,2.4l0.1,1l-0.8,0.2l0,0l-1.1,1.1l1,0.8l-0.4,0.5l0,0h-0.1l0,0l-1.5-0.5l0,0l-1.3,0.1
	l0,0l-1.1-0.5l-1.5,0.5l-0.7,1.2l0.7,0.7l0,0l1-0.2l-0.5,0.9l1.4,1.8l0,0l0.5,0.9l-0.2,1.7l0,0l3.1,3.1l0.9,3.2l-0.2,1.8l1.4,1.8
	l0,0l0.1,0.9l0,0l0.3,1.1l0,0l2.2,0.7l-1.5,0.8l-0.9,2.5l-1.7,0.6l0,0l-2-0.3l-0.9,0.8l0,0l-1.2,0.8l0,0l-0.7,0.7l0,0l-0.5,0.6
	l-1.3-0.1v0.4l0,0l-0.7,0.6l0.9,2l-0.6,0.6l0,0l-0.3,0.4l0,0l0.3,0.7l-3,2l0,0h-0.1l0,0l-0.6-0.4l0,0l-0.4-0.5l0,0h-1.5l0,0
	l-0.2-0.1l0,0l-0.8-0.2l-1.3,0.7l0,0l-1.5,0.2l-2,3.2l-2.2,0.7l0,0l-0.7,1.5l0,0l-0.5,0.5l0,0l-0.9,0.7l0,0l-1.3,1l-1.2,2.4l0,0
	l-1.2,0.2l2.2,1.4l-1.2,0.3l-0.2,2.2l0,0l-1.2,0.5l-0.9-0.2l-0.8,1.7l0,0l-0.6,1.9l-0.7,1.5l-1,0.5l0,0l-0.9,0.4l0,0l-3.6,3
	l-2.2,0.3l0,0l-0.5,0.6l0.3,0.8l-2,1.8l0,0l-1.8,1.5l-0.4,0.7l-0.3,1.1l-1.1-0.1l0,0l-0.6,0.8l0,0l-0.9,0.1l0.1,1.2l-1.9,1l0,0
	l-5-0.4l0,0l-0.5,0.6l1.8,1.3l-1.5,2.9l0.3,1.2l-0.9,0.7l0,0l0.1,0.6l0,0l-0.9,0.4v1.3l-3-0.3l-1.2,1.6l-0.1,0.1l0,0l-0.9,0.8
	l-2.7-1.4l0,0l-6.9,0.1l0,0l-4.7,1.4l-1.3,1l-0.2,1.5l-1.3,0.7l-1.3-0.6v-1l-2,1.2l-0.7,0.8l-1.6-0.5l-1.1,2l-1.1,0.1l-0.7,1.4
	l-4.5,4.8l-3.6-1.1l-3.8,1.2l-0.3-0.7l1.1-0.9l-1.2-0.1l-2.5,1.4l-0.8,1.6l-0.6-0.5l-1.1,2.1l-1.4,0.2l-1,1.4l-4.1,1.6l-1.5,1.7
	l-1.3,0.4l-3.9-0.2l-2.3-3.2l-0.5,0.8l-0.1,2.9l-1.1-0.3l-1.1-1.6l-0.4,0.8l-1.6-0.4l-1.4-1.6l-0.3-1.3l-1-0.6l-0.9,0.7l0.2-1.4
	l-1,0.2l0.1-1l-0.7-0.3l-0.2,0.8V505l-0.9-0.6l0.5-0.1l-4.7-1.3l1,1.4l-2-2.1l-3.3-2l0.4,1.6l1.7,1l-0.4,0.9l0.8,1.6l-1.1-2
	l-0.5,0.5l2,4.1l-0.2,1l-0.8,0.3l-0.2,1.7l0.4,1.5l-0.7,3.1l0.3,1.2l0.7,0.3v1.2l-0.5,1.1l-0.4-0.3v0.9l-1.8,1.4l-6.4-2.6l-1.7-1.1
	l-0.5-1.1l-0.6,0.2l-0.5-1.4l-1.6-0.5l-0.8-1.9l0.2-0.5l-1.1-1.5l-4.4-2.7l-2.4-2.6l-3.6-0.7l-1.4-1l-0.4-1l-1.7-0.9l-1.8,0.2
	l-4,2.4l-2.3,3l1,2.5v1.5l1.3,2.5l0.6,4.1l2.5,2.3l-1.1,3.1l0.4,1.6l1.4,0.3l-1.4,0.3l-0.6-0.6l-2.4-0.4l-2.7-2.3l-0.5-2.8l1.5-1.1
	l-1.1-1.9l0.4-1.3l-2-0.6l0.2-1.7l-0.9-0.5l0.1-1.9l-0.7-1.2l-1.9-1.1l0.1-0.8l-0.7-0.9l-3.1-2.1l-2.8-3.4l0.1-1l-2.4-5.1l-0.1-3.8
	l0.5-1.9l-0.5-0.7l1.6-3.6l1.2,0.1l2.8-1.2l1.9,4.6l1.3,0.8l-0.3,0.8l-0.6-0.6l-0.7,0.4l0.5,3.8l2.2,2.4l2-1.5l0.3-0.6l-0.8-3
	l-0.8-1.8l-0.8-0.5l-1.8-5.8l0.7-3.1l2.3-3.7l1-5.3l2.6-2.9h0.8l1.7-3.5l3.1-2.8l0.1-2.6l1.7-3.7l-0.1-3.9l-0.5-1l2.3-1.7l0.4-1.3
	l2-1.4l0.4-4.4l2.1-2.6l5-2.1l0.7-1.3l-0.1-1.4l0.9-0.6l0.6-2.3l-0.6-2.1l-1.4-1.1l-0.6-1.6l-1.4-0.7l0.1-0.5l0.6,0.3l0.6-0.6
	l0.3-1.8l-1.3-2.9l-1.9-1.9l-3-1.8l-1.2-0.5l-0.7,0.6l-0.9-1h-1.3l0.2-0.9l-2.7-4.2l-2.7-1.9l0.1-3.5l0.5-0.6l0.6-0.2v-0.8l0.7-0.1
	l0.2-0.2l0.9-1.2L353,400l-1.8-4.4l-0.1-7l-0.6-1l1.7-2.1l-0.8-0.3l0.3-3.4l3.6-2.2l0.9,0.5l0.7-1.6l6.2,2.2l-0.5,1.5l1.2,0.4l1-0.7
	l1.5-1.2l-3.6-1.7l-0.4-2.7l-0.7-0.7l-2.6-0.9l-0.5-0.8h-1.2l0.1-1.2l-1.9-3.2l0.1-1.4l-0.9-1.5l-0.6,2.3l1,2.8l0.9,1.8l1,0.5
	l0.3,1.1l1.4,0.2l0.2,1.4l-2.5,0.8l-2.1-0.1l-1-0.8v-1.5l-0.9-1.4v-2.4l-0.8-1.3v-0.9l2.6-6.3l1-0.9l2.7-6.2l2-2.1l0.2-0.8l-2.5,2
	l-2.5,5l-0.3,1.8l-1,0.8l-1,1.9l-1.5-1l-0.3-1.7l0.5-2.3l-1.1-2.9l-0.9,0.5l1,2.2l-0.6,3.7l2.2,2.7l-2.3,4.5l1,7l-0.3,0.6l-2.4-1.4
	l-0.8,0.2l0.1,1l2,0.8l0.5,1.1l-0.8,2.4l-0.8,0.5l-1.1,4.9l-1.4,2.1l-0.5,2l-4-1.1l0.2-1.2l-1.8-7.8l-2.3-2.2l-1-4.1L336,376
	l0.7,2.7l2.2,3.9l0.5,4.3l-2.4-0.2l-3.4-3.5l-0.8-0.1l-0.7-1.5l0.2-1.4l-0.4-0.1l-0.5,1.6l0.5,1.1l-0.8,2.1l-1.9,1.5l-0.2,1l2.2,4.2
	l0.3,3.2l-1,0.2l-0.6-1.2l-1.4-0.3l-0.6-0.9l-1.9,0.2l-0.1-1.1l-1.2,0.1l-0.4-0.5l0.4-2.1l-0.3-0.2l-0.7-0.7l-0.9-0.6l-0.8-0.9
	l0.6-2.4l-0.3-1.1l1.1-3.2l-1.1-1.8l0.9-3.4l0.6-0.6l0.6-0.5l1.9-4.8l4.7-5.7l-0.1-1.7l0.5-0.8l1.4-0.8l1.9-0.3l3-2l1.2-1.2l0.2-1.9
	l1.4-3l1.5-1.5l3.6-1.7l3.6-3.6l-4.9,3.2l-3.1,1l-0.4-1.4l-1.2,1l-2.1,4.8l-0.4-0.5l0.4,1.4l-0.8,1.8l-1.7,1.5l-3,0.8l-2.8,2.4
	l-1.1,1.6l-0.1,1.8l-1.6,1l-1,2.8l-1.1,0.8l-0.6-0.1l-0.8,1l-0.4,3.1l-1-0.1l-1.5,2.4l-0.5-0.4h-1.1l-1.1-0.5l-0.3-1.1h-0.9l0.2,5.2
	l0.9,2.9l0.6,5.8l1.1,0.9l-0.5,0.1l1,1.9l-1.2,0.7l1.7,0.2l2,3.3l1,0.8l1.6,4.8l-0.8,2.1h-1.5l-2.7,1.6l-3.4,4.6l-0.1,2.5l-0.6,0.5
	l-0.6,3.6l-0.8,0.7l0.5,4.5l1,1.4v1.8l-0.6,0.5l-0.4-1h-0.6l-0.8,1l0.2,4.6l-0.9,0.2l-0.4,4.3l-2,2.1l-0.5,3.8l-1.9,1.2l-1.1-0.5
	l-0.3,0.4l1.4,1.2l2.7-1.6l0.1,0.7l-1,0.3l1.8,3.1l0.4,3.4l-2.6,3.4l-0.1,0.8l-2.8,1.8l-5.1-0.1l-2.4,1.8l-2.3,0.3l-2.3-1.2
	l-0.4-1.2l0.5-8.9l2.5-2.5l0.3-1.1l1.6-0.3l0.6-1.8l0.1-8.5l0.6-1.8l-0.8-4.3l1.4-1.4l1.3-5.1l0.5-3.9l-0.7-1.6l2.5-1.6l1.1-1.5
	l1.4-2.9l-0.2-0.4l1-0.8l0.8-2.2l1.3-0.2l2.1-1.8l0.4-1l1.7-1.1l2.6-5.7l-4.9,6.5l-1.3,1.3l-2.4,1.2l-1.2,1.8v-0.6l-1.2,0.2l0.6-1.6
	l-0.9-0.6l0.6-0.8l-0.3-0.3l-1.5,0.6l-1.7-1.5l-0.3-1.5l0.5-3.8l0.8-2l1.6-2l0.4-1.9l2.3-3.3l-0.4-0.4l-1.2,0.4l-2.4,3.2l-0.7-0.2
	l-1.3,1.8l-0.4-0.1l0.2-2.2l-0.6-0.3l0.1-1l1.5-3.7l1.5-1.6l2.6-4.7l1.2-0.6l0.6-1l0.1-0.4l-1.8,1.2l1.9-2.9l-1.4,1.2l-0.5,0.6h-0.7
	h-0.2h-0.8l0.8,0.8l-2.4,3.8l-0.1,0.4l-1,1.8l-1.7,3.6l-0.9-0.6l1.5-2.8l-1,0.3l2.9-7.6l0.9-0.8l0.6-2.2l1.1-1l1.9-3.4l1.1,0.5
	l1.5-0.5v-0.8l-0.7-0.2l-0.4-0.5l-0.4-1l3.9-8.3H311l-0.4,0.8l-2.9,2.7v0.9l-1.1,1.5l-1,0.1l0.7-1.8l0.4-1.3l0.8-1.1l-0.1-0.9
	l2.6-4.6l-0.2-1.5l-0.8-0.1l-0.3-0.6l1.5-0.3l1.1-1.1l0.9,0.3l0.2-0.8l1.3-0.3l-1-1.4l-0.9,1.1l-2.7-0.1l-1.4,1.4l-0.9-0.1l0.8-3.7
	l-0.6-0.2l0.9-5.6l1.5-1.7l0.8,0.1l0.2-0.4v-1.2l1.5-3.9l2.6-2l-0.8-1.6l0.2-1.2l0.5,0.1l0.6-1.1l1.5-1.1l0.3,0.7l1.3-0.6l0.3,0.5
	l2.8-0.5l0.8-0.6l1.5,1.1l0.9-0.8l2.9,0.8l1.2,1.2l0.8,0.5l2.7-1.2l1.2-0.8l1.2-2l1.3-0.6l-0.6-0.6l1-1.2l1.6-2.4l2.3-5.6l-0.8,0.4
	l-2.5,5.3l-1.2,0.8l-1.2,0.7l-1.7,4.4l-2.9,1.6l-1.6-1.9l-2,0.4l-3.7-1.3l-1.1,0.3l-0.2,1.2h-2.9l0.8-1.4l-0.6-2l-0.7-0.6l-0.8,0.1
	l-1.4,2.1l0.2-1.4l-1.3,0.8l3.8-6.2l0.9-0.1l0.3,1l-0.3,1.2l1.2,0.7l3.1-1.2l2.4-2.9l1.4,0.2l1.5-0.5l0.2-0.5l-0.9-0.1l-1.4,0.6
	l-1.2-0.3l-1.2,0.4l-1,1.5l-1.9,1.2l-1-0.1l-0.7-2.1l-1-0.5H318l0.6-1.4l2-2.4l1.2-2.7l3.9-3.9l-0.1-1l-1.5-0.2l0.5-0.7l2.4-1.9
	l0.9-0.3l0.8,0.6l1.9-2.3l2.7-0.1l1.8,1.2l1-0.6l0.9,0.3l1.4-1.5l8-2.4l-2.6,0.1l-9.3,3.3l-0.5-0.8l-1.1-0.8l-4-0.2l1.3-3.2
	l7.4-10.2l-0.8-0.6l-1.8,2.6l-4.8,4.8l-0.1,1l-1.6,2.3l0.8,1.2l-1.9,0.9l-0.7,1.5l-0.5-1l-1,0.1l-2.6,2.5l-0.4,1.3l-2.7,3.2
	l-2.1,0.4l-2.2,3.1l-1.2,0.5l-0.6-0.6h-1.2l0.1,0.5l1.6,0.6l-4.3,7l-2.4,2.2l-5.5,3.2l-0.7-1.6l-2.3-0.6L298,316l-0.7,0.7l-1-1.6
	l1.5-2.7l-0.2-0.6l-2,3.3l-7.7-2.2l-3.4-3.5l-1.2-3.2l-1.4-1.2l-0.4-2.9l0.3-0.6l1.9,0.1l1.4-0.7l1.7,0.4l0.8-0.5l-2.5-0.4l0.6-1
	l0.9-0.1l1.9,1.6l0.4,1.4l3.8,2.3l0.2-0.5l-0.8-0.7l-2.2-1.2v-1.4l2.3-0.4l5.8-4.7l1.6,0.5l1,1.7l1.1-0.3l2,1l3-0.1l0.5-0.5l3.4-0.2
	l0.1-0.5l-4.4-0.1l-2.7,0.5l-3.7-2.8l-3.3,0.2l-0.6,0.3l-3.9,3.8l-1.2-0.9l-2.6,0.8l-1.6-0.3l-0.5-1.2h-0.7l-0.9,0.2l-0.8,1.4
	l-0.9-1.3l-2.7,0.4l-1-1.2l-1.4-0.4l-1.8,0.7l-1-0.1l-2,0.7l-2.6-0.5l-1.9-1.4l-0.9-1.8l0.4-0.8l0.5-0.5l0.6-1.3l1.2,0.1v-0.9
	l-0.7-0.4l0.4-0.7l1.3,0.3l1.4-1.1l1.8-0.4l3.4,0.5l0.8-0.2l0.8-0.2l1.3-0.2l0.3-0.8l2,0.4l0.9-0.7l0.1-1.1l1.3,0.8l2,2.9h0.6l1-1.2
	l1,0.7V289l-0.8-0.7l-0.6-1.2l0.4-0.9l1.5,0.7l1-0.1l0.8-0.7l1.9,0.1l-0.1-0.3l-0.4-0.6h-1.4l-1.2,0.7l-2.2-0.6l0.7-2.3l0.6,0.1
	l0.8-2.6l1.4-0.4l1.1,0.7l0.6-0.9l1.3,0.2l2.1-1.3l2.1,0.6l0.9-2.1l1.8-1l-0.1-1.1h-0.6l-1.7,2l-0.7,1.4l-3.9-1.3l0.2-0.7l2.2-1.8
	l1.6-0.4l0.1-0.4l-0.6-0.3l-0.2-0.5l-4,0.6l-1.9,0.9l-0.5-0.3l-1.4,0.9l-0.9-0.4l-1.4,1.1l-1-0.3l-0.3-0.7l-0.9,0.2l-0.1-1.2
	l1.5-0.5l0.5-1.1l0.8,0.8l1.8-0.1l-0.1-1h-1.5l-0.2-0.6l-1.5-0.8l0.7-0.7l1.1,0.2l1.2-2.7l-0.3-1l1.4-1.4l-0.6-0.3l0.1-1.6l0.9-1
	l0.1-1.6l0.7-0.5l5.9-0.7l1.2,3.2l4.5,2.4l0.1-0.6l2.8-0.6l4.1-1.6l-0.7-0.7l-1.1,0.2l-4.4,2l-1.1-1.1l-1.5-0.4l-1.5-2.8l0.6-1
	l-0.4-1.1l-1.6-0.4l-1.5,0.6l-0.5,0.8l-1-0.8l-0.4-1.5l-1.1,0.5l-0.5-2.5l1.1-2l2.1-1.5l1.2-2.7l2.6-0.1l2.1-1l3.2,1l1.7,2.7
	l1.1,0.5l1,0.5l0.4-0.4l0.7-0.9l1.2,0.2l4.6-1.4l1.9,0.1l-2-0.7l-4.3,1.1l-2.2,0.4l-1.5-1.3l-0.7-2.4l-1.2-0.6l-1.8-0.8l-3,0.3
	l-1.9-1.6l0.1-1.2l0.6-1.8l2.2-1.6l0.9-1.7l-0.3-1.2l-1.4-0.6l0.2-1.3l1-1.4l2.4-1l1.4,0.1l2.8-1.4l3.2,4l2.6,2.3l1.3-0.3l0.2-0.7
	v-0.4l-2.8-1.4l-3.5-3.8l0.1-1.1l1.6-1.7l-1.6,0.9l-0.8,1.1l-2.3,0.5l-1.9,0.6l-0.3-0.8l-1.3,0.4l-2-1.3l-4.6,0.7l-0.6-0.6l0.3-1.5
	l0.6-2.2l0.9-0.2l0.5-1l1,0.2l-0.3-1.9l1.4,0.1l1.1-1.1l0.1,1.6l3.3-1l1-1.1h2.7l4.4-4.1l0.4-1.7l-1-0.7l-1.9,2l-0.7,0.9l-2.3,3
	l-1.9-0.1l-1.3,0.5l-0.6-0.5l0.2-0.6l-1.7-0.3l-0.3-0.6l0.4-1l2-1l-0.9-1.8l-4.5,3l-2.2,2.4l-1.3,0.7l-2.8,0.5l-0.9-1.4l-0.6-0.5
	l-0.7-0.1l0.1-2.8l0.1-1.8l-0.3-1l0.5-2.5l-0.7-1l-1.6-0.1l-1.2-3.8l1.2-9l0.3-1.4l0.8-2l0.9,0.3l0.7-0.9l0.8,1.2l1,0.1l0.8,1
	l0.5,0.4l1.2,0.9l-0.4,0.5l0.4,0.6l1-0.4l0.6-0.6l0.4-0.5l0.7,0.7v2.1l1.6,0.7l1.5,1.8l-0.4-4l1.7,1.5l0.8,0.5l0.6-0.8l2.1,0.7
	l0.7-0.3l1.6,0.1l0.7-1.8l-1-0.8l-1.2,0.3l-2-0.7l-1.5,0.5l-1.3,0.2l-0.9-0.2l-1.1,0.8l-0.1-0.9l-2.1-1.3l0.2-1l1.2,0.5l-0.3-1
	l-2.6-1.1l-0.6-1.3l0.2-1.1l-1.4-2l-1.8-1.5l-2.2-0.5l0.5-2.5l1.5-2.7l-0.1-1.5l3.2-1.4l0.9,0.3l-0.1,0.8l2,0.3l0.5-0.3l0.9-0.3
	l-0.5-1.1l-0.8-0.1l0.2-1.3l-0.7-0.9l-3.7-0.1l-1.6-1.5l-1.7-0.6l0.6-4.1l-0.9-5.5l0.4-1.1l-0.3-2.1l0.6-0.4l1.6,0.1l1.2-1.1
	l1.4,0.4l1.4-0.3l1.2,0.1l1,0.5l-0.6,2.5l0.5,1l0.8,0.2l0.6,1.2l-0.4,1l0.2,2l2.6,3.4l1.2,0.4l0.2-0.9l-1.4-2l0.8-0.8l0.6,0.2
	l1.3,0.3l-0.2-0.8l-0.9-0.4l0.9-1.6l0.1-2.4l-1.2-0.6l-0.2-1.1l-2.3-0.5l0.2-0.9l-1-1.9l-0.1-1.3l2.4-4.1l2.4,0.6l1.2,0.1l0.7,0.7
	l-0.1,2.6l1.1,2.6l3,0.8l1.3,0.9l0.7-0.4l-0.2-0.8l1.2-1.4l0.2-2.3l1-1.8l1.5,0.5l3.1,2.3l1.4,2.3l1,0.7l5,1.6l0.2-0.5l-0.9-0.8
	l-0.3-1.1l-4.1-1.5l-0.4-1.5l-1.4-1.7l-3.5-0.8l-0.6-2.1l2.1,0.1l-0.1-0.8l0.8-0.2l0.5,0.5l-0.2,0.9l1.7,1.5l1.8,0.5l3.8-0.4
	l1.4,0.5l4.2,4l1.7,0.5l2.4,4.8l0.9,0.3l-0.6-2.9l-1.3-2.4l-1.9-1l-0.7-1.5h-1.4l-0.8-1.5l-2.8-2.2l1.6-0.5l0.6-0.8l0.2-0.7
	l-0.7-0.4l0.1-1.4l-2-0.1l-0.8-0.4l-0.4-1l-3.9-0.9l-0.1-1.4l-1.4-2l-1-1l-0.9-1.6l-3.6-0.5l-0.3-0.8l0.7-1.5l-1.9-1.2l-0.9-1.7
	l0.2-1l0.5-1.5l0.7-0.9l0.6-0.1l2.9,2.2l1.1,2.1h0.4l0.4-1.3l0.7,0.5l1.1,1l1.4,0.4l1.1-1l-0.3-1.2l0.4-1l0.1-1.2l-0.4-0.8l-0.6-0.6
	l-0.5-0.8l2.5-0.2l-1.4-0.6l-0.8-1.5h2.4l0.5-0.5l0.6-0.6l0.2-0.9l-1.7,0.9l-2.7-0.9l-0.2-0.8l-0.4-1l0.7-0.1v-0.7l-1.6-0.5
	l-0.8-1.5l-0.8-1l-0.3-1.8l-1.2-1.5l-1.5-0.8l0.7-2.2l1.2-1.2l0.5,1.5l1.9-0.1l-0.2,1.1l1.2,0.3l1,1.8l2.1-2.1l1.8-0.7l-1.7-0.2
	l-0.6-0.9l1.1-1.2l0.3,0.9h1.1l0.8,0.4l1.6,1.4l1.2-0.6l0.6,1.4l0.4-2l0.9,0.5l1.2-0.8v-0.7l0.9-0.4h0.6l3.4,2.8l1.8-0.4l0.4-0.9
	l0.9,0.4l0.2,1.4l1.6,1.7l1.5,0.2l0.2-0.7l-2.8-2.2l1.6-0.7l-3.3-0.9l-0.7,0.5l-1.4,0.3l-3.5-2.5l-1.5-0.1v-1.1l-0.2-0.4l-0.6-1.1
	l0.1-1.3l1.1-1l-0.9-0.8l-0.5,0.9l-0.6,0.1l-0.5-0.5l0.7-1.4l-1.1-0.8v-1.1l-0.8-0.2l0.1-0.4l0.6-0.9l2-3l-0.5-1.6l0.7-1.4l0.7,0.1
	l1,1.8l1.6,0.1l1.1,0.1l0.6,0.5l-0.1,0.9l1.2-0.1l0.3-0.2l0.5-0.9l-1.8-0.6l-0.5-0.5l-1.5-0.6l-1.2-1h2.5l-1.1-1l-1.3,0.1l-0.3-0.8
	l0.9-1.1l1.3-1.3l0.5-0.3l2.8,0.5l2.4,3l0.1-0.7l-0.6-0.7l0.5-0.6L344,91l-0.2-0.9l-2-0.7l-0.3-0.5l-2-0.7l-0.3-1l-1.5-0.1l-0.7-1.2
	l0.5-3.2l0.8-1.4l0.9-1.2l1.3,0.1l1.4-1.1l1.1-1.9l-0.1-0.8l1.1-1.1l-0.4-1l0.6-0.6l-0.2-2.8l0.5-1.2l0.7-0.2l2.7,2l1.2-0.8l2.5,0.5
	l1.2-0.3l1.7,1.9l1.5,0.4l0.5,1l-0.2,1.5l0.5,0.3l0.8-0.9l0.6,0.4l1.2-0.5l-1.4-2.4l1.5-0.5l0.5,0.7l-0.3,0.9l0.8,0.9l0.6-0.1
	l0.5,1.9l1-0.2l0.8,0.5v1l1.9,0.8h1.4v0.8v1.4l-3.7,4.4l-2.6,5.4l1.1,0.2l0.7-1.9h0.9l0.9-1.5l1.9-0.6l0.2-1.6l-0.5-0.3l1.7-2.6
	l1.5-0.4l0.2,1.1l1.3-2.4l-0.6-2.4l0.9-2.8l0.8-0.3l0.5,0.6l1-0.1l0.7-0.5l2.6,0.5l0.5,1.1l2.3,0.9l1,0.6l1,0.7l-0.2,2.5l0.6,0.6
	l0.8-1.4l1.2-0.6l-0.1,0.9l-0.9,0.6l-0.2,2.1l-1.6,0.5l-1.4,1.6l0.3,0.1l0.5,0.6l0.4-0.9l-0.3-0.5l0.6-0.4l1.2,0.1l1.2-1.1l0.6-0.2
	l2-3.2l1.4-0.4l0.5,0.4l1.1-0.6l2.1,0.8l0.5,1.1l0.7,0.1l0.7-0.5l-0.2-0.8l1.2-0.4l0.7-1.4h0.6l1.5,1.3l0.7-1l1-1.9l1.1,0.4l0.3-0.7
	h0.6l0.8,0.4l1.2,0.5l0.9-0.3l1.5-1l0.3-2.3l1.2-0.1l0.4-1l0.8,3.8h2.2l0.8-0.5h1.4l2.6,0.6l1.9,0.7l3.9-1.2l1.8-0.2l1.5-0.6
	l1.7-2.1l2.3-1.4l1-1l1.1-0.8l0.3,0.8l1,0.2l6-1l-0.8,1.8l1.6,1.2l2.6-1.5l1.5-0.2l1.8,0.2l0.1,0.9l2.1,0.1l1.6,0.8l1.1-2.5
	l-3.9-2.9l0.3-2.2L446.2,64.2L446.2,64.2z M462.6,61.2l1,1.5l-0.3,1.6l-1.4,0.2l-0.6,0.7l-0.3-0.9l1-1.4v-1.5L462.6,61.2L462.6,61.2
	z M465.5,42.6l0.2,0.9h-0.9l-1.3,1.3l0.1,0.4l1.5-0.4l-0.2,2.2l-1.4-0.7l-0.2,0.7l-1.4,0.2l-0.7-0.4l-0.6-1.4l0.4-0.7l1.4-1.1
	L465.5,42.6L465.5,42.6z M459.3,41.8l1.3,1.7l-0.7,1.2l-0.8-0.1l-0.6-1.4L459.3,41.8z M447.4,32.6l2,0.6l0.9,1.6l-0.1,0.8L452,36
	l4.5,3.3l0.1,0.5l-0.2,0.7v1l1.2,1l-1.1,1.4l1.5,0.6l-0.5,1.1l2.1,0.7l-1.9,1l-0.5,1l-0.5-0.3l-2.2,0.8l-0.6,1.5l1.2,0.4l1.8-2.1
	l2.6-0.1l0.9-0.6l1,0.8l-1,1.6l1.4-0.4l-0.1,0.5l-3.5,1.2l-2.2-0.7l0.1,0.8l-0.8,0.6l-1.1-1.2l-2.5,0.7l-0.5-0.3l-2.3-4.2l0.4-0.5
	l-2.6-2.5l-0.6-3.5l-3.4-0.6l0.1-1.9h0.4l0.2-1.8l1.2-2.4l1.5-0.1L447.4,32.6L447.4,32.6z M450.4,31.6l1.8,1l0.6-0.6l0.5,1.8
	l-1.3,0.4l-1.4-0.6L450.4,31.6z M482.3,13.7l0.2,2.2l-1.5,2.7l1.1,2.3l-1.3,1.2h-0.6l-0.6-0.7l-0.7-0.8l-1.3-0.3l-1,0.4h-0.1
	l-0.3-0.7l-0.9,0.9l-1.2-0.3l2-5.8l0.9,2l2,0.5l1.1-1.1l-0.2-1.7h1.4L482.3,13.7L482.3,13.7z M472.2,13.3l0.3,1.2l0.8,0.4l-0.4,0.6
	l-0.6-0.2l-0.8,0.4l-0.3-0.9l-0.9-0.2v-0.7L472.2,13.3L472.2,13.3z M472.3,8.9l0.5,0.2l0.2,1l-1,0.9l-3.1-0.4L472.3,8.9z M456,5.7
	h1.6l1.1,1.4h1l0.9,1.1l3.2,1.7l1.5-0.5l0.2,1.2l1.2,0.3l0.4,0.8v0.4l-1,0.4l1,0.4l1.1,1.7l1,0.1l0.6,1.1v2.1l-0.8,1.3l0.3,0.7
	l-0.8,0.1l-0.9-1.2l-1.3-0.1l0.4,1.4l-0.7,0.8l0.1,0.9l-1.2-0.1l-0.5,0.7l-0.5,0.2l-0.2,0.9l-0.9-0.1l0.5,0.9l1.1,0.5l2,0.2l1.1-1.5
	h1.9l2.4,1.8l0.1,1.2l0.6,0.2l0.8-0.8l0.2-1.8l0.9-1.2l0.1,1.2l0.8-0.6l1.1,0.2l-0.2,0.8l-0.7,1.3l1.1,0.2l-1.4,1l0.4,1l-0.4,1
	l0.5,0.4l1.3-0.2l0.3-0.5l1.1-0.7l0.5-1.4l2,0.6l1.4-1.5l0.7,1.7v2l-0.8-0.6l-1.1,0.3l-1.4,1.4l1,0.6l-0.1,1.4L481,33l-0.4,0.9
	l1.7-0.4l-0.2,0.7l0.5,0.4l0.1-0.5l0.4-2l-0.9-0.7l6-3.3l0.1,2.9l-0.1,1.1l-0.6,0.4l0.3,2.2l-1.1,0.2l-1.1-0.6l-0.9,1.2l-1.4-0.2
	l-1.1,2.5l-1.1,0.9V40l-0.7,0.5l-0.5-0.4l0.3-0.6l-1.3-1.4l-1.8-1l-0.8,0.5l0.2,0.6l-0.4,0.8l-0.3,1.2l1.4,0.1l-0.3,2.2l2,0.3
	l-1.3,1.2l-0.7,1.1v1.3l-0.5,0.6l-0.8,1v1l-0.7,0.1l-0.5,0.8l0.7,1l-0.4,1.5l-0.8,0.7l1.9,1.6l-0.9,1.8l0.6,0.3l-0.4,0.4l-2.5,0.4
	l-0.4-1.4l-0.6,0.5v-1l-1-1l0.2-0.8l0.6-0.1v-3l-0.9-0.1l-1.8-2.4l0.7-1l1,1h0.7v-0.7l-1.3-1.8l-1.4,1.1l-0.6-0.3l1.3-1.7l1.6,0.6
	L471,45l1.5-0.5l0.4,1.2l0.8-1.1l2.1-0.3l0.5-0.6l-2,0.2l-2-0.8l-0.2-0.6l-0.8,0.6l-0.5-0.5l2-1h1.3l1.5,0.6l-0.7-2.6l0.4-1.4
	l-1.2-0.6l0.2-1l-2.5-4.6l0.2-2.3l-1-0.3l-0.7,1.3l-2.2,1.4l-2.2,0.4l-0.6-0.5l-0.3,1.5l-1.1-0.6l-1,0.2l-0.5,0.7l0.4,0.4l-0.9,0.5
	l-1.7-0.4l-1,0.3l-0.4,0.7l-0.7,0.4l-2-1.9l-1.2-2.3l0.5-2.7l-1.1-0.8v-1.2l-0.8,2.5l-1.6,0.2l0.1,0.7l-0.7,0.7l-1.9-0.4l-0.6-1
	l-1.4-0.6l0.4-0.9l-1-3.2l0.6-1.3l-0.1-0.9l-0.1-1.6h0.5l0.4-0.9l-0.6-1.3l1.3-0.8l-0.9-0.8l0.2-1.9l-0.5-0.8l0.4-0.9l-0.5-1.6
	l2.4-3.2L448.5,8l3-0.2L456,5.7L456,5.7z M474.7,3.6l1.1,1l-0.9,4.9l-1.2-2.1l-0.4-2l0.2-1.4L474.7,3.6z M465.3,0l0.4,0.6l1-0.2
	l0.1,1.7l1.1,0.7l2.3-0.1l1.6-1.2l0.3,0.8l0.7,0.1v0.8l-0.4,0.4L472,3l-0.8,1.5l0.9,3.3l-2.2,1.1L467.3,9l-3.5-2.4l-1.6-3.5L465.3,0
	L465.3,0z"
            />
            <path
              id="region-east"
              onClick={selectRegion("east")}
              className={getClassNames(
                "east",
                selectedRegion,
                markers.find((marker) => marker.id === debugId)
              )}
              d="M768.1,747.5L768.1,747.5l-0.4-1.9l0.8-2l-0.1-6l-2.9-9l-6-7.4l-10.8-9.5l-5.6-3.9l-4.6-2
	l-8.7-1.1l-10.6-3.4l-1.3,0.4l0.5,0.3l-1.9,0.1L716,702l1-0.1l-4.7-1.4l-1.1,0.5l0.7,0.3l-0.8,0.2l-0.2,0.7l-1.5,0.2l0.4-0.2l-9.3-1
	l-2.3,0.5L698,702h-0.2l-0.2,0.5l-3.1-0.8l-2.9,0.7l-2.6,2.8l-1.8,6.7l-2.3,3.7l-3.2,3.8l0.2,2.2l-0.9,0.5l-3.9-3.7l-1.5-0.2
	l-1.2,2.7l0.5,0.1l-1,1.2l1.7,1.2l-2.5,1.3l-2.9,2.6l-0.9-0.3l-1,4.5l0-0.1l-2.1-0.1l-1.7,1.3l-1,0.1l-0.3,2.9l-2,1.9l-5.3,1.1
	l-4.6-0.1l-1.3,1.2l-1.5,0.5l-7.8-2l-0.7-0.6l-1.6,0.4l-1,0.9l-4.5,0.5l-4-0.4l-1.7,3.2l1,0.8l-0.1,1.8l1.7,0.8l3.4,0.5v1.3
	l-0.7,1.1l3.7,2l-0.3,1.7l1.1,2.4l-0.7,0.5l-0.2,6.5l-0.6,1l-3.7,1.8l-1.2,1.4l-0.4,1.8l-1.1,0.5l0.1,1.3l1.2,0.7l0.1,0.4l-2.2,4
	l-2.1,0.7l-1-0.2l-1.2-1.1h-0.9l-1.4,1.1l-0.9,1.5v2.5l-2.8,1.1l-1.3,2.7l0.2,5l1.8,1.4l0.2,0.9l-2.7,5.1l0.1,3.1l2.2,2.1l-0.7,0.5
	l0.5,0.8l-1.2,1.1l0.5,2l-3.9,1.9l-0.9,0.9l-0.5,1.1l0.3,3.7l0.9,1.5l3.9,0.1l1.4,0.8l0.9-0.3l0.7,1l1.1,0.4l-0.2,1.7l-2.2,2.1
	l-0.3,1.4l1.3,0.8l1.1-0.2l0.8,0.7l-0.2,0.3L621,822l-1.1,0.2l-1.3-0.8l-0.1-0.1l-0.1,0.8l1,0.9l-0.2,1.2L618,825v0.9l0.5,0.9
	l1.7,0.5l0.5,0.9l0.8-0.1l0.8,1.6l0.9-0.1l0.5,1.3l2.2,2.2l1.6,5.8l-0.1,1l7.2-0.7l1.7-0.9l1.7-1.8l2.4-1l4-1.1l4.7,0.4l2-0.3
	l1.2-1.3l1.5-0.4l2.4-0.1l1.2,0.7l0.5,3.3h1.3l0,0.4l7.3,1.3l2.2,0.7l2.3,1.6l1.5-0.7l2.5,2.3l-0.6,0.7l1,0.2l1.5,3.5l-0.2,0.6
	l-2.5,0.3l-1.9,1.9v4l-0.6,1.2l0.8,0.4l3.3,2.5l2.5-1.2l1.2,1.8l1.2,0.4h3.5l1.9-0.6l0.8-1.8l-1.4-1.1l0.1-1.2l1.1-1.4l4-1.2l3,0.5
	l2-0.3l2.7-1.5l1.9,0.1l2.5-1l3.8,1.3l9.7-6.5l1.4-2.3h-2.5l2.4-6.7l-0.5-3.7l0.6-2.1l-1-1l-1.8,0.2l-1.5,1.1l-0.7-1l2.6-2.4h3.5
	l0.8-0.8l1,0.3l-0.5-0.5h1.1l0.1-2.1l0.5-0.1l1.4,1.2l-1-0.6l1,2.7l4.8-0.5l1.3-0.3l6.6-4.6l3.3-4.6L739,815l-1.6-1l0.5-0.5
	l-0.4,0.3l-0.7-0.4l1.1-3.3l0.7-0.9l1-0.2l-0.4-1.4h-4.4l-5.6-0.2l-3.2,0.7l-0.4-0.8l0.4-0.4l0.4,0.8l3.2-0.7l5.6,0.2l2.3-0.8
	l0.9,0.4l1.5-0.7l1.4,1l0.2,1.9l1.7-2.5l1.5-0.7l1.7-2.4l2.2-1.8l1-2.4l1.8-2.3l6.6-3.6l2.7-10.3l0.8-9.5l1.2-2.4l1.5-1.1l0.9-3.7
	l2.3-5.2l0.4-4.9l2.1-3.5L768.1,747.5z M761.5,748.1l0.1,0.1l-0.2,0.2l-0.1-0.2L761.5,748.1z M683.5,785.3l-0.2-3l-1.2-1.4l-3.5-1.7
	l-4.3-0.8l-0.2-1l0.4-1.5l0.1,0l-0.3,1.1l0.2,1l4.3,0.8l3.5,1.7l1.2,1.4l0.2,3.1L683.5,785.3z M672.4,744.3l-0.7,0.5l-0.1-0.1
	l0.8-0.6L672.4,744.3z M673.1,748.8l-0.5,0.7l-0.8,0.2l-0.3-0.2l1.1-0.2l0.4-0.5L673.1,748.8z M680.7,766.5l0.8,5.5l-0.7,1.5
	l0.3,0.4l-0.3,0.3l-0.2-0.3l0.7-1.5l-0.8-5.5l-1.4-4.8l0.3-1.9l0.2-0.1l-0.3,1.6L680.7,766.5z M671.4,746.8l0.2-0.1l0.5-0.1
	l-0.6,0.3L671.4,746.8z M672.2,745.3l0.1,0.1l-0.8,0.6v-0.2L672.2,745.3z M671.5,748l0.4-0.3l0.1,0.1l-0.3,0.3L671.5,748z
	 M669.9,813.7l-1.6,0.4l0-0.2l1.7-0.5L669.9,813.7z M668.9,820.5L668.9,820.5l0.6,0.6l0,0L668.9,820.5z M703.8,757.1l-2.8,3.5
	l-0.1,0.5l0,0l0.1-1l2.5-3.1L703.8,757.1z M668.5,732.9l0,0.1l-1.2,4.9l0-0.1L668.5,732.9z M649.3,790.5l0.8,0.6l0.1,0.3l-0.8-0.6
	l-3.1,0.2l-1.4-0.5l-0.1-0.3l1.4,0.5L649.3,790.5z M641.9,784.1l2,3.3l0.1,0.3l-2-3.3l-2.9-2.2l-0.1-0.3L641.9,784.1z M637.4,779.8
	l1.4,1.2l0.1,0.3l-1.4-1.2l-2.5-1.3l0-0.3L637.4,779.8z M634.8,777.4l0,0.2l-3.6-3.4l0-0.2L634.8,777.4z M632.2,819.1l-3.6,3.1
	l-2.2,0.1l-0.6,0.7l-1.5-1.1l-0.9-1.6l0.2-0.3l0.9,1.6l1.5,1.1l0.6-0.7l2.2-0.1l3.6-3.1l2.7,1.4l-0.2,0.3L632.2,819.1z M638.6,820.2
	l-1.1,0.3l-2-1l0.2-0.3l2,1l1.1-0.3l0.7-0.7l0,0.1L638.6,820.2z M638.9,805.4l-1.5,1.5l0-0.1l1.7-1.7l3.1-0.1l-0.1,0.3L638.9,805.4z
	 M642.4,804.7l0.1-0.3h0.4l0,0.3H642.4z M644.4,804.7l-1.2-1.3l0.1-0.4l1.3,1.4l2.5-0.4l-0.2,0.3L644.4,804.7z M655.8,800.8
	l-2.4,1.5l-0.1,0l2.4-1.5l4.1-0.2l0,0L655.8,800.8z M679.4,797.7l-1.3-1.5l0,0l1.4,1.6l1.7,3.6l0,0L679.4,797.7z M685.9,795.3
	l-2,0.7l-0.2-1.6l-2.1-2.8l0.9-4.5l0.2-0.3l-0.9,4.4l2.1,2.8l0.2,1.7l2.1-0.7l1,0.4l-0.3,0.3L685.9,795.3z M689,795.6l-1.3-0.9
	l0.3-0.3l1.3,0.9l0.9,1.6l-0.3,0.3L689,795.6z M690.5,796.6l0.3-0.3l1.9,0.2l-0.2,0.3L690.5,796.6z M696.6,794.5l-2.2,1.2h-1.2
	l0.2-0.3h1.3l2.2-1.2l0.8,0.4l-0.3,0.3L696.6,794.5z M699.4,794.2l-1-0.3l0.3-0.3l1,0.3l2-0.4l1.8,1.6l-0.6,0.6l0.8,1.7h-0.4
	l-0.7-1.4l0.6-0.6l-1.8-1.6L699.4,794.2z M707.1,805.8l-0.1-1.5l-0.9-0.3l0.1-1.2l-1.4-1.3l0.1-2.1l-1.7,0.4l-0.5-0.2l0.4-0.5
	l-1-0.5l0.2-0.9h0.2l-0.1,0.6l1,0.5l-0.4,0.5l0.5,0.2l1.7-0.4l-0.1,2.1l1.4,1.3l-0.1,1.2l0.9,0.3l0.1,1.5l2.1,0.7l-0.2,0.3
	L707.1,805.8z M710,805.7l0.2-0.3l1.8,0.8l-0.3,0.3L710,805.7z M712.5,805.7l0.3-0.3l1.1,0.2l-0.3,0.3L712.5,805.7z M724.6,807.6
	l-0.7-1l-1.8,0.6l-1.2-1.1H719l-0.2-0.8l-0.1-0.5l-1.2,0.7l-1.1-0.6l-2.2,0.3l0.3-0.3l2.2-0.3l1.1,0.5l1.2-0.7l0.1,0.5l0.5,0.3
	l-0.2,0.5h1.9l1.2,1.1l1.8-0.6l0.7,1l0.5,0.2l-0.4,0.4L724.6,807.6z M744,760.3l-0.1-0.4l0.1-0.2l0.2,0.5L744,760.3z M748.7,756.8
	L748.7,756.8l0-0.6l-1.4-0.6l0.2-0.4l1.2,0.5V756.8z M764.3,751.9l-0.5-0.8l-1.1,0.3l-0.5-1.4l-1.1-0.7l0.3-0.3l0.8,0.5l0.5,1.4
	l1.1-0.3l0.7,1L764.3,751.9z"
            />
            <g
              id="region-se"
              onClick={selectRegion("se")}
              className={getClassNames(
                "se",
                selectedRegion,
                markers.find((marker) => marker.id === debugId)
              )}
            >
              <polygon
                points="590.1,932.9 589.4,932.2 589.8,932.9 589.5,932.7 589,931.3 587.9,930.3 588.5,930.2 587.4,929.8
		587.3,929.2 588,929 586.8,929.1 586.6,930.2 586.4,929.4 582.2,929.8 582.4,929.2 581.1,928.8 578.6,926.7 577.1,927.1
		576.1,926.5 574.1,928.4 573.6,929.5 570.6,930.4 570.1,931 568.7,930.8 565.3,932.8 563,932.8 561.4,935.6 560,936.1 560,936.9
		558.9,937.1 560,937.3 563.7,936.4 565.3,936.7 567.7,939 571.4,940.7 576.9,945.8 579.6,945.4 584.3,943.6 585.8,938.4
		587.5,937.2 589.6,936.9 591.8,935 589.9,933.3 	"
              />
              <path
                d="M748.4,870.9l1.3-5.2l-1.3-2l-0.7,0.5l-2.4-0.2l-1.1,0.7l-1.9-0.1l-1.2,0.6l-1.3-0.3
		l-2.6,0.8l-2.3-0.5L731,866l-4.5-0.1l-2.8,0.8l-0.2-0.6l0.1,0.6l-2,1l-2,0.1l0.2-0.4l-0.6,0.4l0.4-1.3l-1.5-2.8l-3.1-2.2l-4.3-0.8
		l-1.7-1.2l-3.8-0.8l-0.6-0.5l0.1-2.1l-3.5-1.5v-1.7l-11.8,0.4l-1.5,0.6l-1.1,2.1l-0.8,1.8l-1.9,0.6h-3.5l-1.2-0.4l-1.2-1.8
		l-2.5,1.2l-3-2.3l0.2-0.1l-0.8-0.4l0.6-1.2v-4l1.9-1.9l2.5-0.3l0.2-0.6l-1.5-3.5l-1-0.2l0.6-0.7l-2.5-2.3l-1.5,0.7l-2.3-1.6
		l-2.2-0.7l-7.3-1.3v-0.6h-1.5l-0.5-3.3l-1.2-0.7l-2.4,0.1l-1.5,0.4l-1.2,1.3l-2,0.3l-4.7-0.4l-4,1.1l-2.4,1l-1.7,1.8l-1.7,0.9
		l-7.2,0.7l0.1,0.4l-0.2,0.1l0.1-1.5l-1.6-5.8l-2.2-2.2l-0.5-1.3l-0.9,0.1l-0.8-1.6l-0.8,0.1l-0.5-0.9l-1.7-0.5l-0.5-0.9v-0.9
		l1.2-0.8l0.2-1.2l-1-0.9l0.1-0.8l0.3-1.4l2.2-2.1l0.2-1.7l-1.1-0.4l-0.7-1l-0.9,0.3l-1.4-0.8l-3.9-0.1l-0.9-1.5l-0.3-3.7l1.4-2
		l3.9-1.9l-0.5-2l1.2-1.1l-0.5-0.8l0.7-0.5l-2.2-2.1l-0.1-3.1l2.7-5.1l-0.2-0.9l-1.8-1.4l-0.2-5l-2.4,0.4l-3.8,3.1l-0.2-0.8
		l-1.1,0.5l-0.5,2.1l-2.3,2.5l-4.9,2.7l-0.6,0.9l0.3,2.3l-0.4,0.7l-2.2,0.4l-2.9-2.2l-2.8-0.1l1,1l-1.4,0.2l-0.1,1l-2.2,2.4
		l-2.1,1.4l-3.5,0.2l-1,0.9l-0.5,0.8l1.5,1.4l0.1,0l-1.4,0.6l-0.9-0.2l-5.4,2.2l-2.1-1.8l-2.2-5.9v-1.9l0.2-1.1l2.1-2.8l-0.3-0.8
		l-1.5-0.4l-0.7-1.7H571l0.8-1.2l-2.1,0l-1.7,3.6l-1.6-0.3l-0.2-1.2l-1.5-0.8l-0.8,2.2l-2.9,2.6l-1.5,5.2l-0.5,4.6l-1.4-0.3l0.4,0.9
		l-0.8-0.2l-1.8,1.3l-1.2-0.1l0.2-0.4l-0.6-0.2l-0.4-1.2l0.3-1l-0.4-0.8l3.3-4.1l-0.4-0.6l-2.5,0.8l-0.5-0.5v3l-1.8,1l-1,2.7v2.2
		l1.1,2l0.4,2.8l-0.5,3.4l-2.7,5.5l0.2,2.9l-0.8,4.3l0.9,5.1l-0.5,1.4l0.3,1.3l0.2,1.1l1.1,1.1l-1,4.5l0.8,3.9l4.1,2.8l1,2.1
		l1.2-0.1l1.2-0.9l0.4,0.1l-1.2,0.9h-0.1l-0.6,3.2l2.6,3.7l-0.1,1l-1.5,1.9v1l3.5,4.7l0.5,1l-1.5,1.1l-0.1,5.6l-5.7,2.8l-1.7,2.4
		l-2.2,1.8l-0.5,2.3l0.7,5.3l2.5,1.4l1.1,2.9l0.5,3.9v6.1l-0.5,2.1l-1.6,0.4l-0.9-1l-2.2-0.1l-2.9-1.3l-1,0.6l-1.3-2.3l-1.1,0.5
		l-1.3,2v-2.2l-0.8,0.7l-2.6,0.2l-1.4,1.5l-0.4-0.8l0.1,1.2l0.8,0.4l-0.1,2.1l0.8,0.9l2.1,0.7l1.7,2l-0.3,1l0.5,3.4l2.3,3.3l0.5,5
		l0.6,1.4l2,1.1l2.2-1.5l0.6,1.6l-0.3,1.6l3.9-0.4l-0.7,1.5l6.1,1.5l2.8,1.6l-0.4-0.9l2.5-2.7l2.9-1.6l3.5-0.9l2.5-1.8l1.8,0.1
		l1.4-2l1.3-0.6l0.1-0.6l-0.8-0.7l-0.8,0.6l0.6-0.7l-1.4-2.1l0.8-0.8l1.7,1.9l5.4,2.4l1.5,2l1.8,0.5l1.2,1.1l2.5-1.2l0.8,0.8
		l3.1-0.8l0.3,0.4l-0.1-0.4l0.8-0.2l0.7,1.6l0.3-0.6l-0.8-0.7l5.5,0.7l-0.1-0.3l0.9,0.1l3.3,1.6l4.7,3.8l2.7-4.1l2.7-1.3l8.8-2.1
		l11.1-0.7l5.3-1.7l3.5-0.5l7.8,1.2l0.8,0.3l-0.2,1.2l1.3,0.3v-1.2l9.4,3.4l-0.3-0.5l0.9-0.1l3.3,2.4l1.1,0.3l0.9-0.4l3.4,2.1
		l3.1,0.3l2.7-3.7l1.9-1.3l1.3-2.3l1.5-1l2.7-1.2l11.1-2.3l3.7-1.3l1.3-0.7l3.7-3.8l2.8-1.4l2-0.2l2.8,0.6l0-0.1l5.6,1.1l2.1-0.1
		l0.4-2l-0.7-4.4l0.2-2l1.5-2.7l2-2.2l4-2.2l6.2-0.8l-0.4-0.3l1.1-0.3l0.2-1.1l1.2-0.6l2.6-0.3l2.2-1.1l1.5,0.2L741,892l0.6,0.1
		l-0.6-0.5l0.2-0.4l1.3-0.4l0.3,0.6v-0.9l2.3-1l1.7-3.4l-0.1-5.6l-1.3-5.9l0.2-2.9L748.4,870.9z M619.2,864.8l0.2,0.2l-2.3-0.4
		l0.3-0.1L619.2,864.8z M608,873.3l-1.8,0.2l0-0.1L608,873.3L608,873.3z M606.3,888.8h0.6l0.8-0.8l0.3,0.3l-0.6,0.6L606.3,888.8
		L606.3,888.8z M662,874.4L662,874.4l-0.6-0.2l0,0L662,874.4z M655.6,871.8l2.7,3.1l3,0.2l0,0.1l-3.2-0.2l-2.7-3.1l-1.3-0.5l0.2-0.1
		L655.6,871.8z M640.9,869.3l2.6,3.2l1.9,0.3l2.1,1.8l3.6,1.8l0.9-0.2l0,0l-1.1,0.3l-3.6-1.8l-2.1-1.8l-1.9-0.3l-2.6-3.2h-1l0.1-0.1
		H640.9z M633.2,862.8l1.3,1.8l0.8,5.3l2.9,0.5l-0.2,0.1l-2.9-0.5l-0.8-5.3l-1.3-1.8l-1.9-0.7h-4.2l-0.6-0.6l0.1-1.4l-3.8-1.9
		l-0.1,0l0.2-0.2l-0.1-1.3l1.3-2.7l0.1,0l-1.3,2.7l0.1,1.3l3.8,1.9l-0.1,1.4l0.6,0.6h4.2L633.2,862.8z M627.1,840.3l0.2-0.1v3.7
		l1.4,3.3l-1.2,2.1l0.8,3.2l-0.4,1.2l-0.2,0l0.4-1.1l-0.8-3.2l1.2-2.1l-1.4-3.3V840.3z M623.3,853.7l-0.2,0.4l-0.1,0l0.2-0.5
		L623.3,853.7z M619,852.9L619,852.9l-0.9,0.6l0.7,0.5l-1.1,0.7l0,0l0.9-0.6l-0.7-0.5L619,852.9z M607.3,846.1L607.3,846.1l-2.3,1.3
		l-0.5,1l-0.1-0.1l0.4-0.8L607.3,846.1z M602.7,847.3l0.2,0l-0.9,1.9l0.2,0.6l-0.2,0.1l-0.2-0.6L602.7,847.3z M600.8,850.2l-0.6,2.2
		l0.5,0.4l0.1,2.1l-3.4,2.3l-2,0.4l-4.2-0.2l-0.4-0.1l4.2,0.2l2-0.4l3.4-2.3l-0.1-2.1l-0.3-0.3l0.6-2.1L600.8,850.2z M580.5,851.4
		L580.5,851.4l-9.5-2.1l0,0L580.5,851.4z M570,848.5l0.2,0.3l-0.9,1l-0.1-0.4L570,848.5z M559.2,850.5l-0.7,0.9l-0.4-0.1l0.7-0.9
		l4.2,1l1.7-0.3l0.9-1l0.8,0.1l-0.1-0.8l1.1,0.2l1.3-0.9l0.1,0.3l-1,0.7l-1.1-0.2l0.1,0.8l-0.8-0.1l-0.9,1l-1.7,0.3L559.2,850.5z
		 M568.7,869.6l-0.9-1.1v-0.1L568.7,869.6L568.7,869.6z M595.7,867.1l-1.2,0.9l-7.3,0.5l-1.9-0.1L584,867l1.3,1.3l1.9,0.1l7.3-0.5
		L595.7,867.1l1.8,0.2l3.1-0.6l2.9,1.3h3.1l0.9,1.1h0.6l1.5,0.8l-0.2,0.3l-1.4-0.8l0.1-0.2h-0.6l-0.9-1.1h-3.1l-2.9-1.3l-3.1,0.6
		L595.7,867.1z M606.6,896.9l0.1,0l0.4,0.4l-0.1,0.1L606.6,896.9z M663.5,889.4l-1.5-0.2l0,0l0-0.1l1.3,0.2l3.8-0.7l0.2,0.1
		L663.5,889.4z M669.3,891.8l-0.2-0.1l3.4-0.9l0.1,0.1L669.3,891.8z M675.9,892.1l-2-0.2l-0.1-0.1l1.9,0.2l-0.1-0.8l3.2-0.3v0.1
		l-3,0.3L675.9,892.1z M679.5,892.7l1.4,0.1l-0.1,0.1L679.5,892.7L679.5,892.7z M680.6,895v-0.1l1.7-0.4l0.1,0.1L680.6,895z
		 M683,895.2h0.7l0,0.1L683,895.2L683,895.2z M684.3,896.7l0.3-0.2v0.2l-0.2,0.1L684.3,896.7z M684.8,897.1l3.1,1.2l0,0L684.8,897.1
		L684.8,897.1z M688.1,900.2l4,0.9l0,0.1L688.1,900.2L688.1,900.2z M694.4,903l-1.8-0.7l0,0l1.6,0.6l3.6-1.4l0.1,0.1L694.4,903z
		 M698.6,902.2l-0.1-0.1l1.4-0.6l0.1,0.1L698.6,902.2z M703.3,903.5L703,904l-0.2-0.1l0.3-0.5l1.7-0.2l2.5,1l0,0l-2.3-0.9
		L703.3,903.5z M707.4,905.7l0.3-0.1l0.1,0.1l-0.4,0.2L707.4,905.7z M709.7,909.2V909l0.9-0.3l0.7-1.2l0.1,0.2l-0.6,1.1L709.7,909.2
		z M711.7,908.1l0.5-0.1l0,0.1l-0.5,0.1L711.7,908.1z"
              />
            </g>
            <path
              id="region-wales"
              onClick={selectRegion("wales")}
              className={getClassNames(
                "wales",
                selectedRegion,
                markers.find((marker) => marker.id === debugId)
              )}
              d="M452.4,665l0.9,1.7l1.4,0.5l3.9,2.9l1.7,2l1.1,0.4l3,2.7l0.8,0.4l0.5-0.4l0.4-2l0,0l0,0l0,0
	l1.4,0.1l0,0l5.4,4.2l1.3,1.8l0.5,1.2l-0.5,1.2l-3.9,1.5l-0.1,0.5l0.8,0.3l1,1.9l2.9,1.8l0.8,0.3l1.4-1l-1.3,3.1l1.6,1l-0.1,1.2l1,1
	l0.1,0.8l-0.7,0.9l0.8,0.8v1.9h0.7l0.7,2.3l2,0.7l0,0l0.7-0.2l0,0l1.2-0.2l0,0l0.6,0.1l0.3,0.7l0,0l1.6,0.2l0,0l0.5,0.2l-0.1,0.3
	l0,0l-0.5,1l0.7,1.3l-0.2,3.1l-1.8,0.3l-2.8,3l-0.8-1.9l-1.7-2v-0.8l-3.2-0.9l0,0l-0.8,0.7l0,0l-1.5,0.5l-1.2-0.7l-1.7-1.5l-0.6,0.1
	l-1.7,0.3l-1.6,2.8l-2.6,0.4l-1.2-0.5l-0.9,1.2l-0.3,2.5l-1.5-0.1l-0.6,0.5v0.7v0.8l1.2,0.8l-2.2,2.1l0.7,0.5l-1,2.5v1.2l0.5,0.1
	l0,0l0.3,0.4l0,0l-0.5,0.4l0.2,0.5l2.5,1l0.7-0.9l1.3-0.3l-0.1,1.9l1,0.8l1.6,0.2h0.9h1l0.4,0.9l-0.6,0.8l1.9,0.9l-0.4,0.5l0.4,0.4
	l1.7,0.2l0.1,1.8h-0.9l0.1-0.9l0,0l-0.7,0.2l0,0h-2l0,0l-0.2,0.2l0,0l-0.8,2.8l-0.8,0.2l0.1,1.6l0.4,0.1l-0.8,2.8l-2,0.7l1.4,1
	l-0.8,0.3l-1.3,2l0.2,1l-1.8,1.4l0,0h-0.7l0,0h-0.6l0.1,1.2l1.5,3.1l-1.4,0.9v0.7l2.3-0.4l0,0l1.1-0.7l0,0l-0.8-0.6l0.4-0.6l1.7-0.4
	l1.6-1.7l1.7-0.3l0.9,2.2l-0.6,3.2l0,0l-1.8-0.1l0,0l-0.3,1.1l0.4,1l-0.7,0.2l0,0l-0.3,0.1l0,0h-3.9l-2.1,0.8l-2.5,1.8l-1.1-0.2
	l-2.4,2.8l0,0l0.3,1l0,0l0.6,1.8l2.6,1.2l1.1,1l0.4,1.2l2.8,1.1l3.1,2.9l1.2,0.4l4.2-1.1l1.2,0.6l0,0l-0.8,2l-2.1,0.8v2.2l-0.6,1.2
	l0,0v1l0.5,0.2l0,0l3.4,0.6l-0.9,0.3l0,0l-0.8,0.6l0,0l-1.8-0.5l-1.9,0.8l-0.8,0.7l0.2,1.2l-1.8,0.3l-0.3,1.9l0.4,0.2l-1.8,1.3
	l0.4,1.9l-1.7,1.9l1.5,0.9l1.1-0.8l0.5,0.7l-1.3,1.2l-2.6,0.6v0.5l0,0l-0.4,0.5l0,0l2.2,0.9l0.1,1.3l-1.1,0.4l-0.1,2.4l2.1,2.8
	l0.3,1.1l-0.7,2l1.9,3.8l3.4,3.9l0.3,1.8l0.6,0.4l1.4-0.4l0.6,2.3l4.8-2.2l0.2-0.8h0.8l1.9,1.1l-1.2,0.7l1.3-0.2l0.4,1.2l0.9,0.1
	l1.3,1.5l2.1,1l-0.6,1.6l2.2,2.7l0,0h0.3l0,0l1.1-1.1l0.4,0.9l1-0.5l0.2,1.1l1.7-0.2l1,1l0,0l-0.7,0.3l0.1,1.2l-1.2,0.9l0.5,0.8
	l-0.6,2.7l1.1,1.5l-0.6,1.7l-1,0.6l1.2,2.5l-0.1,0.5H489l0.9,0.8l-0.2,1.1l1,0.8l-1.7,0.6l-0.1,0.6l0.5,0.3l0.8-0.4l0,0l-0.9,1.5
	l0,0l0.7,0.3v1.1l0.8,1.2l0,0l-0.6,2.9l-2.1,1.1l-0.7,1.3l-0.8,0.1l-1.3,2.9l-2.2,1.5l-1,0.6L481,850l-4.1,1.3l-1.2-0.3l0.7-0.8
	l-0.4-0.3l-0.7-0.1l-1.9-0.4l-3.1,0.5l-7.4,4.1l-2.1,2.2l-0.4,1.3l-1.4,1.1l-0.3,3.7l-2.1,1l-1.5-0.8l-3.4,1.7l-1.9-0.7l-2.1,1.1
	l-1.5-0.3l-2.6,0.5l-1-0.7l-8.7-1.4l-2.1-3.4l-2.6-2.3l-1.2-1.8l-3,0.2l-1-0.4l-2.5-3.3l-1.8-5.2l-1.9-1.3l0.3-1.2l-2.6-2.1
	l-1.3,0.5l0.1-0.6l-1-0.2l0.2-0.4l-1.9,0.8l-1.8-0.4l-1.2,0.5l-1.4,3.2l1,0.8l-3.4,0.3l-0.8-0.5l-1.7,1.1l-3.9-1.1l-1.8,1l0.6,1.4
	l-0.5,0.4l-2.6-0.7l-0.7,0.4v0.8l-0.5-0.1l-4.4-2.5l-1.5,0.3l-0.2-0.4l0.9-1.2l-0.2-1.7l-1.1-1.8l3.3-2.2l0.5-2.3l1.6-0.8l-1.2-0.2
	l0.2-0.6l2.5-0.8l-0.8-0.4l-1.5,0.4l-2.7,1.9l-0.5-0.6l-1.6-0.1l-3.5-3.2l-0.7-0.6l-1.8-0.5h-1.4l0.2-1.4l2.5-1.1l-0.4-0.3l-0.4-1.1
	l-2.9,2.2l-1.5,0.1l-5.2-1.4l-6.8,1.1l-1.3,1.4v0.8l1,0.8l-1.2,1.3l-0.2,0.9l0.5,0.5l-1.2,1.2v0.7l-2.8-0.1l-0.1,0.9h-0.9l-0.7,0.9
	l-3.1-1.2l-1.8,0.1l-0.2,0.8l-2.1,1l-0.4,1.1l0.4,0.8l-1-0.1l-0.8,1.5l-1.4-0.1l-2.4-1.5l-1.2,0.4l-3.3-1.2l-0.2-0.8l0.6-0.7v-0.4
	l-1-2.3l-2.2-0.5l-0.6-0.6l-0.2-0.1l-0.5-0.8l1.3-1l1.6,0.2l0.3,0.9l1,0.1l0.5-1.5l1.5-0.1l2.9,0.6l-0.3,0.6l0.7,0.3l-0.2-1.4
	l1.1-0.5l1.5-0.5l1.1,0.4l0.7-0.8h0.9l0.4-0.9l0.1-0.2l0.1-1.5l-0.9-0.9l0.7-2.9l-0.6,0.4l-1.1-0.4l1.1,1l-0.5,2.1l0.8,1.1l-0.3,1.8
	l-0.8,0.1l-0.8,0.6l-1-0.4l-3.1,0.9l-3.1-1.2l-1.8,0.3l-1.4,0.1l-0.8-1.4l-1.1,1l-0.7-0.6l-0.5,0.4l-1.7-0.2l-0.3,0.8l0.6,0.6v1.4
	l-0.8,0.1l-0.4,0.8l-0.9-1v-1.9l-0.8-0.2l-0.7-1.1l-1.4,0.3l0.3-0.5l-1.5-1.5l2.6,0.4l0.6-2.2l1,0.1l1.7-1.8l2.9,0.1l0.6-3.6
	l-1.4-4.7l-0.7-1l-2.6,0.3v-0.6h-1.9l-0.6-0.7l-1.6,0.1l-0.2,0.5l-1.1-0.3l-1.9,1.4l-0.1-0.7l-1.4,0.3l0.8-1.4l-0.5-1l1.1-0.9
	l-0.3-0.9l-0.7,0.1l0.6-0.6l1.1-0.6l1.9-0.1l1.4-1.4l1.6-0.3l-0.2-0.6l0.8-0.3l0.3-1l2.7,0.2l0.9-1.7l0.8,0.5l1.3-0.2l0.5-1l0.8,0.2
	l-0.1-1.2l0.5-0.5l-1-0.9l1.5-1.1l-1.1-1.1l1.1-1.1l-0.2-0.5l3.2,0.6l0.3,0.5l1.7-0.5l0.3,0.9l0.3,0.2l-0.6,1l0.4,0.4l0.9,0.4
	l0.1-0.5l3.2-0.8l0.6-2.5l1,0.4v0.8l0.5,0.3l2.2-0.2l0.7-0.9l-0.5-0.9l0.6-1.1l2.9-0.8l2-2l1-2.1l-0.4-0.6l1.1-1.4l1.5,1l1.1-0.8
	l0.1-1.4l2.6-0.4l0.2-0.5h3.7l1.1-0.7l1.2,0.9h1.7l2.9-2.6l2.7-1.7l3.2-3.8l0.8-0.4l0.8,0.9l1.1-0.6l0.6,0.3l4-3.4l1.7-0.5l1.9-1.5
	l2.1-3l1.9-2.8l1.7-5.3l1.5-2.4l-0.2-1.6l0.8-1.9l-0.1-2l1.8-3l-0.6-4.9l-0.8-1l-1.1-3.5l-2.4-4.1l2.1-6l2.4-2l-0.3-3.4l-1.1-2.3
	l-4.6-6.5v-0.9l1.6-1.2l0.5-2.1l-2.7-5.4l-2.2-1.6l-1.8-0.5l-2.1,1h-3.2l-0.7,0.5l-0.2,1.2l-4.2-0.2l-0.2,1.2l-3,0.8l-1.7,1.6
	l0.2,1.2l-1.5,1.1l-0.4,1.8l1,1.5l-0.2,1.1l-1.8,0.2l-0.8,1.5l-0.9-0.9l0.3-1.3l-2.2-1.8l-2-0.7L366,720l-1.4-0.5l-1.4,1.2l-0.7-0.8
	l-1.2-0.2l-0.9,2.2l-1.6-0.7l-0.6-1l0.9-0.7l0.1-1.2l1.8-2.2v-1.8l1.8-0.6l0.3-1.4l1.3-0.6l1.2-2.8h1.1l1.1-1.8l1.3-0.3l1.5-1.2
	l3.2-0.2l2.4-2.6l0.7-0.2l2.1-3.2h1.6l3.5-3.6l0.8-1.6l0.4-3.2l-0.7-3.2V687l4.1-2.5l2.4-3.3l1.9-1.2l0.3-2.2l0.8-1l2.2-0.8l1.6-1.7
	l1.8-0.8l1.1-1.6l1.6-2.2l5.8,1.8l3.3-1.2l2.2-1.9l1.2-2.2v-1.5l-0.8-0.6l0.7-1l2.3,0.5l1,1.5l2.5-0.5l1,1l1.7,0.5l0.6,1.7l3.7,0.7
	l3.9,0.1l3.8-1.7l3.1-2.7l2.7-0.9l6.6-1.9l2.9,0.3l1.1,1.2h0.1L452.4,665z"
            />
            <g
              id="region-sw"
              onClick={selectRegion("sw")}
              className={getClassNames(
                "sw",
                selectedRegion,
                markers.find((marker) => marker.id === debugId)
              )}
            >
              <polygon points="422.3,977.4 422.3,977.4 422.2,977.4 422.1,977.5 	" />
              <path
                d="M560.3,867.2l-3.5-4.7v-1l1.5-1.9l0.1-1l-2.6-3.7l0.6-3.2l-1.1,0.1l-1-2.1l-4.1-2.8
		l-0.8-3.9l1-4.5l-1.1-1.1l-0.2-1.1l-0.8,0.3l-1.5-0.7l-0.7,0.2L546,836l0.6-0.2l1.5,0.7l0.8-0.3l-0.3-1.3l0.5-1.4l-0.9-5.1l0.8-4.3
		l-0.2-2.9l2.7-5.5l0.5-3.4l-0.4-2.8l-1.1-2v-2.2l1-2.7l1.8-1v-3.1l-1.4-0.4l0.4-1.2l-1-0.8l0.2-1.3l-0.5-0.1l-0.2,0.7l-1.1-0.7
		l0.1-2.7l-0.6,0.9l-0.5-0.2l-1.3-1.6l-1.7,1.2l-1.1,2.2l-0.4-0.7l-0.8,1.3l-0.9-0.7l-0.4,0.7l-1.3,0.2l0.7,0.7v1l2.1,1.5l0.3,0.8
		l-0.7,0.9l-0.2,1.6l-1.4-0.6l-3.3-3.2l-1.1,1.5l-1-0.2l-0.2-0.6l-2.1,0.2l-2.7,2.6l-0.7,0.1v0.5l-0.7,0.1l-0.7-1.1l-1.5,0.4
		l-1.7-0.5l-1.8,1.6l0.1-0.5l-0.7-0.3l0.7-2.1l1.5-1.1l-1.1-0.9l-1.8-0.4l-1.2,0.8l0.2,2l-0.7,0.4l1.5,1.5v0.8l-1-0.4l-0.3,0.9
		l-2.2-0.5l-1.9,3l-1.9-0.4l-1.2,0.4l-0.8-1l-0.9,0.1l0.1-2.8l-1.8-1l0.1-0.8l-2.6,0.9l-0.4,1.7l-0.9,0.2l-1.5-0.3V801h-1.7v-0.9
		l-0.9,0.1l-0.8,1.5l1.3,1.3l-1.5,1.4l0.2,1.7l-0.6,0.4l2.2,0.9v2.4l1.1,0.9l0.6,2.2l-1.7,0.5l-1.3,1.2l-0.4-0.4l-0.4,0.2l-0.1-0.4
		l-1.9,2.6l-3.1-0.1l-0.1,1.2l-1.2-0.7l-1.5,1.7l-0.7-0.4L492,820l-0.7,0.3l0.1,1.2l-1.2,0.9l0.6,0.8l-0.6,2.7l1.1,1.5l-0.6,1.7
		l-1,0.6l1.2,2.5l-0.2,0.5h-0.8l0.9,0.8l-0.2,1.1l1,0.8l-1.7,0.6l-0.1,0.6l0.5,0.3l0.8-0.4l-0.9,1.5l0.7,0.3v1.1l0.8,1.2l1.5-3
		l3.5-4.2l7-7l2.9-1.9l-0.2-1.1l-1.7-1.1l0.1-1.2l1.2-1.1l2.1-0.1l0.7,0.8l0.2,1.5l-1.1,3.4l-1.1,1.5l-3.6,1.6l-2.4,4l-0.2,1.2
		l-2.3,1.9l-2.1,4.3l-5.1,5.4l-0.3,1.6l-1,1.5l0.5,0.2l0.4,0.6v0.7l0.6,0.6l0.6,0.5l0.6,0.4l1.1,0.1h1l0.9-0.5l-0.1,0.3l-0.2,1
		l0.5,0.4l0.5,0.1l0.6,0.2l0.7,0.2l0.5-0.2h0.5l0.3,0.4h0.3l0.5,0.1l-0.1,0.4l-0.1,0.3l0.2,0.7l0.1,0.5l-0.1,0.3v0.5l-0.2,0.3
		l-0.2,0.5l-0.2,0.4l-0.3,0.2L498,858l0.4,0.1l0.3,0.4v0.5l0.2,0.7l-0.2,0.3l-0.2,0.5l-0.2,0.3l-0.4,0.8l0.8,0.3l1.1-1.5l0.1,0.4
		l-1.3,1.8l-1.1-0.4l-0.6,1.2l-1.4,0.2l-1.7-0.7l-0.7,0.2l-0.3-0.5l0.4-0.9l-0.5-0.7l0.5-1.4l-0.7-0.3l0.9-0.8l-0.5-1.8l-1.1-0.7
		l-0.7-1.3l-1.4,0.6l-2-2H487l-0.2,0.7l-2.5-0.2l-1.8,0.9l-5.3,5.7l-1.8,2.9l-4.4,1.2v1.2l-2.4,4.7l-0.5,0.6l-0.9,3.4l-2.2,2.7
		l-0.1,0.5l1.2,0.7l1.4-0.1l0.2,0.5l-1.9,1.2l-2.1,1.9l-6.3,1.2l-5,2.1h-4.5l-3.3-0.3l-3.2-2.9l-0.9,0.4l-1.9-1.4l-2.9-0.8l-2.2,0.1
		l-0.7,0.8l-2,0.7l-0.9-0.8l-5.3-0.8l-0.8,1.8l0-0.1l0.8-1.8l-3-0.4l-1.1-0.9l-1.6,1.6l-2.5-0.3l-2.8,1.1l-2.1-0.8l-2,1.2l-4.3,0.3
		l-1,0.9l-3-1.1l-0.5,0.7h-2l-2.9,1.3l-1.8-0.1l-0.5,1l-1.4,0.3l1.1,0.6l-0.3,2.9l-0.5,0.7l-2.1,0.2l1.1,0.9l-0.1,1.1l1,0.6
		l-0.6,3.1l2,1.6l0.6,1.2l-2.3-1.5l-0.5,3.3L390,900l-2.8,4l-2.1,0.6l-3-0.5l-2.3-2l-2.7-0.7l-3.5-0.1l-0.6,1.5l0.4,2.2l-1,3.3
		l0.2,0.8l-0.5,0.3l0.2,1.2l0.2,0l0.3,1l-0.9,2.2l0.8,3.8l-0.3,8.2l-0.8,1.1l-2.4,1l-0.4,0.9l-1.3,0.6l-0.8,0.6l-0.2,2.4l-1.5,1v0.9
		l-1.1,0.2l-0.5,0.8l-1.3,0.2l-0.2,1l-0.9-0.3l-1.3,0.7l-0.2,1.3l0.4,0.5l-1.9,4.8l-2.2,0.8l-1.6-0.5l-0.6,0.6l-0.6,0.3l-1.2,0.7
		l-1.2-0.2l-1.1-0.9l-0.7,0.7l0.9,0.8L349,947l-0.1,0.7l-0.8-0.2l0.2-0.9l-1.7,1.1l-0.8,1.4l-2-0.2l-0.5-0.6l-0.8,0.2l0.7,2.2
		l-0.2,1.6l-1.1,1.2l0.7,0.9l-0.8,4.7l-1.9,2.2l-1.5-0.7l-0.4,1.2l-2.5,0.8v1l-0.7,0.6l0.5,1.2l-0.8,3.1l-1.2,0.5l0.1,0.6l-1.3,1.3
		l-2.2,0.3l0.1,1.2l-1.1,2.3l-3.3,2.1l-1.6,1.8l-2.5,0.7l-0.6-0.8l-1.1,0.3l-0.5,2.2l-2,2.2l-0.1-0.4h-2l-0.6-1.8l-3.9,0.3l-3.2,2.7
		l-0.6-0.4l-1.8,2.4l-2.8,0.2l-2.4,3.8l1.2,3.8l-1.1,1.1l-0.4,1.4l0.6,0.8l0.8,0.1l0.1,0.9l0.8,0.9l1.6-0.3l0.5-0.6l0.5,0.4l1.1-1.1
		l2.6-0.1l2.2-1.8l0.6-1.8l-1-1.7l0.2-0.3l1-0.5v-0.9l2.8-0.4l0.3,1.1l1-0.7l0.8,0.9l-0.3,0.4l1.1-0.1l0.6,1.3l1.2-0.7l1.1,0.1
		l1.9,1.3l1-0.3l1.6,1.1l0.3-0.3l2.3,2.8l-0.1,1.4l1.1,0.8l0.3,1.9l-0.6,1l1.5,1.8l-0.2,0.9l1.7,0.6l0.7,1.5l0.8-0.8h0.7v-1.7
		l1.2-2.2l2.7-0.6l1.4,0.6l0.7-1.2l-0.3-1.2l1.8-0.7l0.1-1.4l0.6-0.4l-0.8-1.5l-0.7-0.2l0.3-1.7l-1.9-0.1l0.7-0.3l-1.5-0.7l-3.6,0.6
		l0.6-0.5l1.1-0.1l0.4-0.9l0.5,0.7l2.7-0.4l0.6-0.7l-0.6-0.5l-0.1-1.1l1.2-0.8l-0.2-0.6l1.4-0.7l0.8,0.4l-0.5-1.2l-1,0.3l-0.8-1.4
		l1.3,0.6l0.7-0.8l0.2-0.6l-0.8-0.4l-0.3-1.3l1-1.3l1.1-0.5l0.2,0.1l-0.7,0.6l0.6,2.1l0.5-0.1l-0.6,2.6l1.4,0.1l-0.8,0.7l0.5,1
		l1.8-1.2l-0.1-0.6l0.7-0.7l-0.1-1.9l1.1-1.8l1.5-0.4l0.9,1l1-1.6l2.5-2.2l2.8,0.4l1.3,1.1v-1.1l1.1-0.8l-0.3-0.7l1.2-1.1l-1.2-0.5
		l-0.2-0.8l0.8-2.4l0.7-0.6l0.7,0.2l-0.9-2.1l0.6-1.2l3.5-1.2l1.2,0.6l-0.2,1.8l0.8,0.6l0.4-0.9l0.9-0.1l0.8-0.6l0.3,0.6l1.7-0.4
		l0.5,0.5l1.2-0.8l2.6,0.8l2.2-0.6l0.3-0.6l1.5,0.5l0.1-0.7l1.5-0.4l-0.1-0.9l1.4-0.9l7.1-0.1l2.2,0.7l3.1,2l0.6,0.8l-0.4,0.7
		l0.4,0.8l0.8-0.7l1.4,0.1l-0.8-1.5l2.2-1.6l-0.6-1.1l-0.8,0.3l-0.7-1.1l-0.2-2l-1-0.6l0.4-0.5l0,0l0,0.2l1,0.9l0.3,2.1l1.2,0.1
		l0.1,0.7l1.3-0.5l1,0.6l0.3,3.9l1,0.5l1.4-0.5l0.7,0.8l-0.2,0.7l2.3,1.1l3-1.5l1.8,0.4l1-0.4l0.2,1.1l2.1,0.2l0.8,0.7l-0.2,0.9
		l0.9-0.2l1,1.6l0.7,0.2v1.8l-0.7,0.1l0.2,0.4l2.8,2.1l2.1,0.8l0.5-0.6l-0.1-1.1l1-1.4l0.5,0.1l-0.3,0.1l-0.7,1.2l2.3,0.9l0.9,1.3
		l1.1-0.4l1.5-1.6l1.6,0.4l1-0.3l-1.1-1.7l1.1-5.2l1.9-2.7l2.5-0.7l0.5-0.9l-0.6-1.2l1.8,1.2l1.6-1.1L437,967l0.8-2.1l3.2-2
		l-0.7-6.4l-1.8-5.2h-0.8l1-2.8l1.6-1.8l0.1-1.4l2.7-3.1l-0.1-0.5l-0.7,0.3l-0.5-0.6l-0.4-2.5l0.3-1.1l0.2,0.4l-0.2-0.8l0.4,0.6
		l-0.3,1.2l0.5,0.6l0.1-1.3l0.1,0.9l0.3-0.1l-0.4,0.6l0.4-0.1l-0.5,0.4l0.6,0.3l-0.4,0.1l-0.1,1.3l0.4-0.8l2.8,1.7l1.6-0.1v-0.8
		l0.8-0.6l1.8-0.8l0.8,0.2l1.6-3l1.6-1.5l4-1.1l6-0.1l1-1.7l4,0.3l4.4-1.9l-0.3-1.2l1-2.3l-1.2-1.1l0.3-0.7l1.2-1.1l2.1-0.4l0.7-0.9
		l-0.4-1l-4.3-1.1l0.4-0.5l0,0.1l1.5-2l0,0l-1.4,1.9l-0.4,0.5l4.3,1.1l0.4,1l-0.7,0.9l-2.1,0.4l-1.2,1.1l-0.3,0.7l1.2,1.1l-1,2.2
		l0.3,1.2l2.4-1.5l7.3,1.5l9.8,5.3l6.7,5.1l5,5l-0.4,4.8l2.6-3.6l-0.8-1.7l0.4-0.3l-2.4-0.2l-0.6-1.2l1.3-1.7l1.1,0.5l-1-0.5
		l0.5-0.8l-0.6-0.5l1.6-2l5.5,0.3l1,0.7l5.2,0.8l2.7-0.7l0.7,0.8h1.8l0.7,0.8l0.8-0.5l0.5,1l1.2,0.7l2.6,0.2l0.6,1.7l1.8-1.2
		l4.8-0.6l0.5-1.3l-0.8-0.1l-0.1-0.9l2.1-2.5l-1.1,0.2l-0.6-0.5l-0.2-1l0.7-1.7l-0.6-0.7l2.2-2.3l2-1.1l3.4-0.6l5.1,0.9l1.2-2.1
		l2.3-0.5l0.7-1.5l-3.9,0.4l0.3-1.6l-0.6-1.6l-2.2,1.5l-2-1.1l-0.6-1.3l-0.5-5l-2.3-3.3l-0.5-3.4l0.3-1l-1.7-2l-2.1-0.7l-0.8-0.9
		l0.1-2.1l-0.8-0.4l-0.1-1.2l-0.7,0.3l-1.2,0.7l-0.6,0.9l-2.7,0.4l-1.3,0.8l0.2-0.5l1.4-0.8l2.7-0.4l0.6-0.9l1.2-0.7l0.5,0.2
		l0.4,0.8l1.4-1.5l2.6-0.2l0.8-0.7v2.2l1.3-2l1.1-0.5l1.3,2.3l1-0.6l2.9,1.3l2.2,0.1l0.9,1l1.6-0.4l0.5-2.1V898l-0.5-3.9l-1.1-2.9
		l-2.5-1.4l-0.7-5.3l0.5-2.3l2.2-1.8l1.7-2.4l5.7-2.8l0.1-5.6l1.8-1.3L560.3,867.2z M394.5,953.7l-0.2,0.2l-0.8-0.4v-0.3
		L394.5,953.7z M395.3,952.3l-0.8,0.4l0,0L395.3,952.3L395.3,952.3z M380.4,916.9l-0.6,0.1l0-0.1L380.4,916.9L380.4,916.9z
		 M381.1,927l-0.3-1l0.2-0.1l0.3,1.2L381.1,927z M387.7,945.2l0.4-0.3l0.1,0.1L387.7,945.2L387.7,945.2z M388.9,945.4v-0.5l0.3,0.1
		v0.7L388.9,945.4z M390.3,949.4l1-0.6l0,0l-1.1,0.7L390.3,949.4z M392.7,957.2l0.4-0.9l0.3,0.3l-0.4,0.8L392.7,957.2z M467.5,918.5
		l0.1-1.2l0,0L467.5,918.5L467.5,918.5z M466.3,916.1l0.2-3.3v0.1L466.3,916.1L466.3,916.1z M458,912.5l1-1.5l0.4-2.1l0,0l-0.4,2.2
		L458,912.5L458,912.5z M431.2,902.1l1.2-4l0,0L431.2,902.1L431.2,902.1z M424.1,883.1L424.1,883.1l-0.4,1.1l0,0L424.1,883.1z
		 M420.3,886.2l3.9-0.9l0,0.1L420.3,886.2l-3.3-0.4l0-0.1L420.3,886.2z M422.1,978.8l-0.5-2.1l0.4,0.8l0.2-0.1l0.4-0.3l-0.3,0.3
		l0.2-0.1l-0.2,0.1l-0.2,0.2l0.1,1.1l0.8-0.2L422.1,978.8z M433.1,966.8l0.2-1.3l-0.9-0.9l1,0.7l0.3,2.6L433.1,966.8z M437.2,902
		l-0.1-1l0,0L437.2,902L437.2,902z M540.6,834.6l-1.1,0.5l-0.2-0.1l1.2-0.6L540.6,834.6z M515.7,844.6h1.5l0.2-1.1l2.2-1.1l0,0.3
		l-2,1l-0.2,1.1h-1.5l-0.3,0.9l-0.2-0.1L515.7,844.6z M511.3,890.7l-0.5,0.2l-0.1-0.6l0.5-0.2L511.3,890.7z M513.9,864l0.4-0.1
		l0.3,2.3l-0.4,0.3L513.9,864z M511.5,868.6l1.1-0.2l0.9,0.8l0.8-0.3l-0.3,1l1.7,0.9l0.1,0.9l-0.4,0.3l-0.1-0.7l-1.7-0.9l0.3-1
		l-0.8,0.2l-0.9-0.8l-1.3,0.2L511.5,868.6z M511.6,889.1l2.1-3l3.3-7.8l-0.5-3.6l-0.7-0.6l-0.9,0.4l0.2-1.2l-0.5-0.6l0.5-0.3
		l0.4,0.4l-0.2,1.2l0.9-0.4l0.7,0.6l0.5,3.6l-3.3,7.8l-2.1,3v0.8l-0.4,0.2V889.1z M515.9,849.2l0.3-0.9l-0.4-1.5l0.4-0.9l0.3,0.1
		l-0.5,1l0.4,1.5l-0.3,0.9L515.9,849.2z M516.8,850.1l0.2,0.2l-4.5,3l0.6,0.8l0,0l-0.8-1L516.8,850.1z M514.1,857l0.3-2.6l0.2,0
		l-0.3,2.6L514.1,857z M499.8,859.2l1.1,1l1-0.4l-0.1,1.1l0.7-0.3l0.7,0.6l7.9-2.4l1.2,0.3l0.8,1.5l1.1-0.6l0.6-2.7l0.2,0l-0.6,2.9
		l0.6,0.6l0.4,0.8l-0.8,1l0.6,0.8l-0.6,0.1l-0.4-0.5l0.8-1l-0.4-0.8l-0.6-0.5l-1.1,0.6l-0.8-1.5l-1.1-0.3l-7.9,2.4l-0.7-0.6
		l-0.7,0.3l0.1-1.1l-1,0.4l-0.8-0.8l0,0L499.8,859.2z M480.7,918.6l1-1.4l0,0L480.7,918.6L480.7,918.6z M483,918.2l0.6-0.6h0
		L483,918.2L483,918.2z M484.8,917.4l0.2-0.5l0.1,0L484.8,917.4L484.8,917.4z M487.7,917.1v-0.6l0.1,0L487.7,917.1L487.7,917.1z
		 M495,908.8l-1.1,0.8l0.8,1.4l-0.7,0.3l-0.6,1.5l0.9,1.6l-0.4,1l-0.1,0l0.4-1l-0.9-1.6l0.6-1.5l0.7-0.3l-0.8-1.4L495,908.8l0.1-1.4
		l-0.5-1.4l0.5-0.5l0.1,0l-0.5,0.5l0.5,1.4L495,908.8z M495.7,905.9l0.7-1l0.1,0.1L495.7,905.9L495.7,905.9z M497.7,905.6l-0.4,0.2
		l0,0L497.7,905.6l0.6-0.6l-0.1-1.4h0.8l0,0h-0.7l0.1,1.4L497.7,905.6z M499.6,904.3l1.4-0.2l0,0L499.6,904.3L499.6,904.3z
		 M504,908.5L504,908.5l0.7-1l-0.3-0.8l1.2-0.3l0,0l-1.1,0.3l0.3,0.8L504,908.5z M506.5,907.5L506.5,907.5l4.3-1.8l-0.6-1.2h0.1
		l0.6,1.2L506.5,907.5z M526.2,909.3l-1.2-1.7l0.1-1.7l-1.8-0.8l-1.5-1.5l-0.2-1.2l-0.8-0.6l-0.3-1.9l-3.1-3.3l-1.7,0.2l-1.4-0.8
		l-2.2-0.3l-1.5,1.1l1.2,2.7l-1.9,1.6l-1.3,2l0.3,1.4h-0.1l-0.3-1.4l1.3-2l1.9-1.6l-1.3-2.7l1.5-1.1l-2.2-1.9l-0.2-2l0.5-0.2
		l0.2,1.7l2.2,1.9l2.2,0.3l1.4,0.8l1.7-0.2l3.1,3.3l0.3,1.9l0.8,0.6l0.2,1.2l1.5,1.5l1.8,0.8l-0.1,1.7l1.2,1.7l2-0.6l-0.2,0.5
		L526.2,909.3z M530.7,839.4l-3.9-0.5l-3.5,5l-1.7-1l-0.7,0.8l-1-0.3l0-0.1l0.8,0.2l0.7-0.8l1.7,1l3.5-5l3.9,0.5l3.3-0.9l0.1,0.2
		L530.7,839.4z M534.3,839.1l-0.1-0.2l1.6,0.1l-0.8-2.2l1.3-1.2l0.2,0.2l-1.3,1.2l0.8,2.2L534.3,839.1z M539.8,837.9l-2-0.8
		l-0.2-0.2l2,0.9l0.7-0.3l-1.4-2.8l0.2,0.2l1.4,2.7L539.8,837.9z M543.9,835.5l0.5,1l-2.2,0.7l-0.1-0.1l2.1-0.7l-0.5-1l0.7-1.3
		l-0.4-1.6l0.4-0.1l0,0l-0.2,0.1l0.4,1.7L543.9,835.5z"
              />
              <path
                id="GB-BST"
                className="st0"
                d="M489.5,849.2l-0.7,1l-1.1,3l2,2l1.4-0.6l0.7,1.3l1.1,0.7l0.5,1.8l-0.9,0.8l0.7,0.3l-0.5,1.4
		l0.5,0.7l-0.4,0.9l0.2,0.3l0.1,0.2l0.3-0.1l0.4-0.1l1.2,0.5l0.5,0.2l1.4-0.2l0.4-0.9l0.2-0.3l0.3,0.1l0.4-0.8l0.2-0.3l0.2-0.5
		l0.2-0.3l-0.2-0.7v-0.5l-0.3-0.4l-0.4-0.1l0.7-0.2l0.3-0.2l0.2-0.4l0.2-0.5l0.2-0.3v-0.5l0.1-0.3l-0.1-0.5l-0.2-0.7l0.1-0.3
		l0.1-0.4l-0.5-0.1h-0.3l-0.3-0.4h-0.5l-0.5,0.2l-0.7-0.2l-0.6-0.2l-0.5-0.1l-0.5-0.4l0.2-1l0.1-0.3l-0.9,0.5h-1l-1.1-0.1l-0.6-0.4
		l-0.6-0.5l-0.6-0.6V850l-0.4-0.6L489.5,849.2L489.5,849.2z"
              />
            </g>
            {/* @note this region is disabled */}
            <path
              id="region-york"
              className={getClassNames(
                "york",
                selectedRegion,
                markers.find((marker) => marker.id === debugId)
              )}
              style={{ pointerEvents: "none", cursor: "default" }}
              d="M587.1,524.7l-0.1,0.5l2.5,1.7l1.7,0.3l0.7-0.4l-0.5,0.2l0.7,0.5l1.1-0.2l-0.9,0.3h0.8
	l-0.5,0.1l0.8,1l3.4,2l1.3,0.5l0.6-0.5l1.5,0.2l2,1.8h2.6l2.8,1.5l1.8-0.3l0.9,1.6l1,0.4l0.3,1.5l2.4-0.6l0.9,1.5l0.9,0.1l0.9,2.1
	l4.7,1.1l0.2,0.6l0.8,0.2l1,1.9l2.3,2l-0.7,1.5l0.5,1.7l1.2,1l0.8-0.3l1.8,2.3l2,5.2h0.5l0.5,0.9v2.2l0.8,0.8l-0.2,1.1l1.4,0.6
	l-0.5,1.6l1.8,1.9v1l1.1,0.6l0.7-0.2l1.2,0.6l0.4,1l3.5,1.4l-1.2-0.1l-0.4,1.5l0.6,1.8l1.6,1.8l1.7,1l3.3,0.9l5.3,3.1l0.1,1.3
	l-5.3,1.2l-1.4,2l-0.4-0.1l-1.1,2.9v4.9l4.1,12.3l7.3,11.5l9.2,12.6l0.8,1.6l1.1,5.2l-2.5,3l0.6-2.3l-0.7-0.8l-0.4,0.5l-0.2-0.7
	l-0.3,0.8l-1.3-1.2l0.1-0.6l-0.2,0.4l-4.4-2l-6.4-0.4l-0.8-0.2h0.5l-1.3-1.3l0.9,1l-0.7,0.1l-5.5-5.8l-2.6-4.5l-0.3,0.3l-1.2-0.7
	h-1.5l-5.6,2.4l-0.6,0.1l-0.3-1l-1.2,0.3l-1.2,0.4l0.1,0.6l0,0l-1.9,0.3l-2.1,1.2l-2.3,0.1l-2.2-1.2l-1.5-1.8l-1.5-0.3l-4.7,2.5
	l-4,4.3l-1.8,1.1l-3.6,1l-0.7,2.8l-2.6,3.2l-0.2,1.5l0.6,0.2l-0.7,2l0.6,1.1l-0.3,0.5l-3,0.6l-0.2,1.4l1.1,0.3l-0.2,0.9l0,0
	l-1.1,1.9l-2,1.3v1.1l0,0l-0.6,2.6l0,0l-1.1,0.4l-0.6,1.5l-3.2,1.2l-1.4,1.2l-1,2.3l-1.4,8.8l-1,2.6l-1.6-0.1l0,0v0.1l0,0l-7-3.3
	l-1.6-0.4l-2,0.5l-0.4-0.9l-1.8-0.8l-2.3,0.1l-3.3,2.1l-4.3,0.4l-5.1-1.4l0.1-1.9l-0.8,0.4l-0.3-0.5l0.9-1.6l-0.4-1.2l-1.5-0.2
	l-1.9-3.5l-3-2.3l-1-0.2l-0.2-2.3l0,0l0,0l0,0l-0.1-1.3l-2.5-1.9l-1.7-3.8l-4.2-2.6l0,0l0,0l0,0l0.5-2l-1.4,0.7l-1-0.5l-0.2-1.4
	l-1.9-1l-0.3-1.1l-2-1.5l-2-3.2l-0.9,0.1l-0.4-2.1l-0.7-0.5l0.3-1.9l-1.1-1.3l0.2-1.3l-2,0.6l-0.2,0.8h-2l-1-1.5l-0.6,0.8l0.2-1.9
	l-1.1-0.7l-0.7-2.5l1.1-1.3l0.7-0.1l0.9-1.7l-0.3-3l0.7-0.8l-0.2-1.2l5.1-3.2v-2.2l-2.2-2.1l-0.5,0.2l0.5-0.8l-1.4-1.6l-0.6-2.6
	l-1.2-1.2l-1.9-0.1l-0.4-1l-0.8,0.2l0.2-1.1l-1-2.5l-1.9,0.9l-0.4-1.1l0.7-0.7l-0.6-0.4l-4,0.8l-1.5-2.1l-1.3,0.5l-0.8-0.6v-1.7
	l0.8,0.1l-0.2-0.8l-0.9-0.2l-0.3-2.3l-0.8-0.9l-3.2,1.2l-2.1-0.3l-0.7-1.1l0.4-2.6L501,584l-0.9,0.1v-0.8l-0.8-0.2l-0.5-1.4
	l-1.2-0.6l0.2-3.2l1.7-0.5l0.3-1.3l3.1-3.5l1.2-2.6l0,0l3.5,0.2l0.5-1.6l1.5-0.1v-0.9l0.6-0.2l0.9,1.3l1.4-0.4l0.6-3.1l-0.7-1
	l0.5-0.8l-0.4-2.6l0.8-1.4l-3.6-3.4l1.5-0.4l1.7-1.8l1.2-0.1l0.3-1.5l-0.9-0.7l-0.2-2.4l1-1.3l-0.1-0.8l1.3-0.2l0.2-0.7l1.2-0.5
	l3.8,0.3l0.7-1.4l0.5,0.4l0,0l0.7,0.3l2.7-0.7l4.6-2.3l-0.1,0.9l3,0.9l1.3,0.8l0.4,0.9l1.7-0.2l5.2-3.1l0.1-2.3l1.2-0.5l1.1,1.4
	l1.8,1.2l0.4-1.8l0.7-0.6l-0.2-2.7l2.9,0.5l0.8-1.6l2.3,1l1.6-0.1l0.2,1.1l1.3-0.1l0.1,0.7l-0.5,0.2l0.4,0.3l1.4-0.6l0.7,0.2
	l-0.1,0.8l1.4-0.2l-0.7,0.9l0,0l0.8-0.1l-0.2,0.9l0,0l1.8,1.3l0.6,1.5l0.8,0.2l0.8-0.2l-0.6-0.5l0.1-1l0.9,0.1l0.2,1l1.2-1.2
	l-0.4,1.2l1.5,0.2l0.5,1.3l-0.3,0.7l0.7,0.5l0.3-0.4l-0.5-0.8l0.6-0.4l-0.7-0.8l0.4-0.8l-0.3-1.6l0,0l-0.8-0.4l1-0.5l0,0l1.5,1.8
	l0.8-3.5l-1.8-1.4l0.5-1.1l-0.3-1.4l2.2-1l-0.3-1.2l0.6-2l-1-1l-0.9,0.9l-0.8-1.7l2.4-0.7l1.5-1.6l-0.1-0.8l0.6-0.5l2.6,0.8l0.3,0.5
	l0,0l0.4-1.4l0,0l0.8-0.1l1.2,1v0.8l2.7,0.5l1.4-0.6l1.5,0.8l1.4-1.1l1.8,0.2l0.7-2l0,0L587.1,524.7z"
            />
            <path
              id="region-ie"
              onClick={selectRegion("ie")}
              className={getClassNames(
                "ie",
                selectedRegion,
                markers.find((marker) => marker.id === debugId)
              )}
              d="M66.9,854.1l0.5,0.1l0.3,1.8l-0.3,0.3l-2.2,1.6l-0.2-1l1-0.5l-0.7-0.9l0.8-0.3l0.3-0.7
	L66.9,854.1L66.9,854.1z M37.6,839l2.8-0.2l0.4,0.6l0.7-0.3l1.9-0.6l-1.2,1l0.2,0.5l-1.4,0.3l-0.7,0.8l-0.7-0.3l-2.2,0.8l-0.4-0.1
	h-2.1l0.6-1.7C35.4,839.6,37.6,839,37.6,839z M7.9,809.5l0.9,0.5l0.6-0.7l0.3,1l1.8-0.2l-0.1,1.1l-4,2.5l-1.6,0.4l-0.7-0.5l-2.3,1.2
	l1.2-3.2l2-0.3l1.6-1.2l-0.1-1C7.5,809.2,7.9,809.5,7.9,809.5z M51.4,669.8l0.3,1L52,672l-0.2,3.4l-1.2,0.5l-1-0.2l-1.2,0.1l-1-1.8
	l0.9-0.9l-1-0.2l-0.3-1l2.5-0.8v-1L51.4,669.8L51.4,669.8z M50.2,667.3l1.2,0.7l1-0.5l0.4,0.8l-1.5,1l-2.2,0.8l-0.9-0.4l-0.3-0.6
	l-0.2-0.8l0.4-0.7l1.2-0.6L50.2,667.3L50.2,667.3z M25.9,591.2l1.1,0.2l0.8,1.2l0.9-0.5l1.6,0.3l0.9-1.8l2.8,3.5l-0.3,0.7l-1.5,0.1
	l0.5,0.9l-0.5,0.9l0.4,0.9l0.5-0.1l1.9,0.2l-0.8,1.1l0.1,1.5l-0.8,0.5v0.4l-0.1,1l-0.1,1.3l0.3,1.1l-0.6,2.6h-1.1l-1.6-1.4l-0.8-0.9
	l0.5-0.7l-1.3-0.9l-0.2-0.8l-1.1,0.6l-1.1-0.3l0.2-3.9l0.5-0.5l-1.6-1.5l-3,1.2l-0.7-1.3l-1.7,0.8l-2.3-0.4l0.1,1l-3-1.9V596
	l1.8,0.2l1.4-1.8v-1.9l1.1,0.8l1.5-0.1l0.8,1.1l2-0.2l1.6-2.2L25.9,591.2L25.9,591.2z M29.6,561.1l1.1,0.5l-0.2,1.2l1.1,0.2l0.6,1.7
	l1.9,0.1l0.7,0.8l0.6,0.2l1.3-0.7l-0.2,1.5l-0.6,1.6h-0.8l-0.5,0.6l0.6,0.6l-2.1,0.1l-1.1,0.4l-3.3-0.1l0.7,1.1L28,571l1.3,1.3
	l-0.4,2.2l-1.4-1.1l-1,0.1l0.8,1.6l-0.4,0.9l-1.3-0.3l-0.6,0.8l-1.2,0.3l0.1,0.6l2.1,1l-1.2,0.6l0.6,1.7l-1.1-0.3l-0.5,1.3l1.6,0.5
	l0.4,1.1l-0.7,1l-1.2,0.2l-2.1-1l-0.4-1.3l0.7-0.3l-0.2-2.2l2.3-5l-0.6-2.2l0.9-1.1l1.2-0.1l0.5-1.7l-3.4-1.7h1.5v-0.7l0.1-2.2
	l2.1-1l0.1-0.7l1.3,0.3l0.4-1.1l0.9,0.2l-0.3-1.2L29.6,561.1L29.6,561.1z M122.1,484.4l2.2,1.2l-0.3,0.9l0.7,2.2h-2l-0.2,0.5
	l-0.4-0.5l-2.5-0.2v-0.7l0.5-0.2l-0.3-1.2l1-0.7l-0.9-1.4l1,0.2l0.3-1C121.4,483.6,122.1,484.4,122.1,484.4z M186,476.7l1.4-0.1
	l1.5,1.2l1,2.4l-2.8,0.4l-0.7-0.9l-1.2-0.3l-0.6-1.5L186,476.7L186,476.7z M195.4,444.2l1.6,0.5l0.4,0.8l2.5-0.1l1.4,0.9l1.6,0.1
	l1.3,1l1.9,0.3l1.4,1.1l-0.8,0.4l0.9,0.4l-0.4,1l1.2,1l0.1,1.6l1.4,0.5l0.7-0.3l3.4,2.4l1.9-0.2l0.2,0.6l1.7,0.4l0.3,1.1l3.3,0.3
	l1.9,1.9l-0.1,1.1l-1.8,1.7L217,465l-1.7,0.4l-7,4.2l-2.9,3.5l-0.4,1.4l-2.9,4.1l0,0l-0.9,0.3l-0.8,1.5l-0.9-0.6l-1.2,1.1l-1.8-0.5
	l-2.8,3.2l-0.7,1.6l0.7,1.2l-1,1.5l0.4,0.8l-0.3,2.2l0.6,1.2l-2.5,0.4l-0.8,0.8l-0.3,4.2l0.6,2.4l-2.4,5.3l-2.8,1.8l-1.3,1.9
	l-0.1,3.5l0.7,0.7l-0.6,1l-1.9-0.2l-0.8-0.1l-1.7,0.1l-1.4-0.8l-2.4,2l-3.7,1.7l-0.6,1l-3.5-1.4l-1-1.6l-0.8-0.2l-1.7,1.5l-1.3,2.2
	l-2.5,0.1l0.5,1.2l0.8,0.2l-0.9,1.4l0.4,1.3l0.4,0.6l2.6,0.9l0.4,2.2l1.5-0.3l1.1-1.2l2.6,2.4l0.8-0.3l1.3,0.6l2-1.5l0.4,0.7
	l-0.3,0.8l0.6,0.3l-0.4,0.9l-2.9,1.1l-0.9,1.3l-2,0.6l-0.1,0.9l-2.1,1.8l0.4,1.3l-1.4,1l-0.6-0.4l-6,0.3l-1.1-1.6l-1.1,0.5l-1.2-0.2
	l-2.3,4.3l-0.1,2l-2.4-0.1l-2.4,2l-3.4,0.3l2.1,1.5l-1.1,1.3l4.5,4.1l1.5,3.2l2,1.6h2.2l-0.1,0.8l3.2,4.5l1.7,0.3l0.8,1l0.6-0.7
	l0.7,0.2l0.4,0.8l1.4-0.1L164,563l-0.2,1.4l0.8,1.3l0.3,4.8l2.7,1.6l0.6-0.5l3.4-0.5l0.9,0.9l3.1-0.4l0.7,2.6l1.1-0.5l2,1.8l0.4,2
	l1.3,1.1l1.5,0.1l0.8,1.5l1.2,0.7l1.1-1.4l3.1,1l0.8-1.9l0,0l1.5-1l1.1-0.1l0,0l1.2-0.1l-1.2,1.2l0.3,0.6l3-0.3l-1.1,1.2l0.1,0.7
	l1.8-1.1l0.9,1.8l1.4-1l0.4,0.9l0.6-0.9l-0.7-1.5l-0.6,0.1l-0.6-0.8l1.9-2.5l1.1-0.4l0.7,0.5l-0.4,1.5l-0.9,0.8l0.9,0.9l-1.4,0.6
	l0.6,1.1h1.1l0.1-0.7l1-0.8l-0.2-1l0.6-1.5l1-0.9L203,575l-0.1-1.8l1.4-0.4l-1-0.8l1.2-0.2l0.2-0.8l1.5-0.4l0.8-0.9l1.1,0.7l1.5-0.5
	v-1.7l-0.6-0.6l0.9-0.9l-1.2-2.2h-1.1l0.3-1.2l-2.2-1l0.3-1.3l0.6-0.6l0.9,0.1l0.2-1.4l-0.6-1.7l2,0.4l2.2-1.9l1.1-0.2l-0.5-1.3
	l1.5-1.2l2-3.4l1.8-0.9l1.3,0.7l0.2,0.7l1-0.3l1.4,1l2.2,2.5l0.6-0.2l0.7,0.8l0.3,2l1.2,0.3l1.7,4.1l0.1,2l-0.5,0.4l0.1,0.7
	l-0.8-0.4v0.8l1.5,1.6l0.6-0.2l0.6,0.5l0.8,4l0.8,0.2l0.1,0.9l1.6,1.6l1.3,0.1l0.9,1.6l1.2,0.1l0.4-1.4l1.4-0.6l0.5,0.6l1.4,0.3
	l1.1,1.7l0.9,2.1l-0.1,1l-1.2,2.7l-0.6-0.1l1.1,2.8l-1,0.2l0.2,1.9l-0.6,0.7l0.2,0.8l1,0.1l1.5,2.4l1.8-0.8l0.3-1l2.1,0.5l1.6-0.7
	l1.2,0.5l0.8-1l1.4-0.3l-0.5-0.6l0.3-0.7l1.3,0.6l1,1.5l1.8-0.5l1.6,0.3l0.4-0.8l1.1-0.4v-4.4h1.5l0.3,2l0.8,0.3l1.5-1.9l0.7-0.1
	l1.2,1.1l0,0l1.5,2.5l3,2l1,1.9l2,1.4l1.2-0.7l0.2,1.8l1.5,1.8l-2.3,2.2l-3.6,0.1l-3.3-0.9l-4.3-2.7l-1.9,0.6l0.5,1.1l-1.4,1.5v2.2
	l-0.9,0.9l0.1,3.6l0.5,2.2l2.2,2.5h1.7l4.1,1.8l-0.8,1.2l-0.6,2.9l0.3,2l2.4,0.9l-1.3,1.2l-1.1,5.6l0.6,1.2l0.8,5.8l1.2,3.5l1.6,1.4
	l1.5,1.3l1.1,2.1l2.1,0.5l1,1.7l0.9,0.9l0.3,1.8l-0.6,1.2l0.8,1.1l-2.1,1v1.6l0.7,0.3l-1.4,1.7l-0.8,0.9l-1.1-0.8l-0.5,0.7l-2.9,0.3
	l5,2.3l0.5,4.3l-0.1,1.1h3.6l1,1.2l-0.5,2h-2.1l-1.3-2.4l-1.3-0.2l-1.8,0.8l-2.2,2.4l-0.6,0.4l0.2,0.7l1.2,0.5l-0.1,0.5l-2.2,0.3
	l0.5,2l3.1,2l0.8-0.2l0.9,0.8l1.5,0.7l0.7,1.3l-1.2,1.3l0.4,4.4l0.8,2.1l0.9,0.7l0.1,2.7l2.1,4.6l0.4,2.8l0.2,2.6l-0.9,3.9v3.7
	l0.4,1.1l1.4,1.3h0.7l0.5,0.8l-0.9,1.3l-0.4,2.7l-1,1l-0.1,1.8l-1.1,1.5l-0.4,1.9l0.4,0.8l-2.7,1.7l-2.4,4.3l-0.5,2l0.1,1.9l-0.1,2
	l0.4,1.1l-2.9,3.5l-2.1,4.6l-0.7,0.5l0.2,3.3l1,1.7l-0.1,1.5l1,2.5l-1.1,3l-1.3,1.2l-5.5,9.2l-1.9,2.2l-0.9,3l0.6,3.6l-1.1-0.2
	l-0.7-0.7l-3.2-0.1l-1.7,1.5l1,1.4v2l2.1,0.9l0.4,1.2l0.5-0.1l0.1-1.4l0.7-0.8l-0.2,2.6l1.2,2.9l0.8,0.4l1.6-0.3l1.2,1.5l-1.8,2.8
	l-0.5,1.5l0.3,1.1l-1,1.5l-3.6-1.3l-4.8-0.5l-6.3,2.2l-0.9-1.6l-2.3-1.7l-3.9-1.6l-5,1.4l-0.5-0.7l0.6-1.7l2-2l-0.4-1.5l-1,1.8
	l-1.6-0.1l-1.1,1.8l-0.7,0.7l0.3,0.7l0.4,0.4l-1.2,2l0.3,0.9l0.4,0.8h-1.2l-2.2,1.2l-1.4,1.5l-0.4,1.6l-1.2,0.8l-0.4-0.9l2.1-3v-1.4
	l-0.8-1.8l0.6-0.5l-1.2-2.1l-0.9-0.3l-1-2.2l-0.9-0.2l-0.5,3.2l0.3,0.7l-0.8,1.9l0.7,1l0.2,0.6l-0.5,1.2l-1,0.6l0.1,1l-2.8,1.3
	l-1.4-0.8l-3.2,1.2l1.4-2.5l0.2-0.8l-1.3-0.9h-1l0.4,1.5l-2.1-0.5l-1.4,2.3l-0.9,0.2l-2.1,0.2l-4.5-1l-1.9,0.3l-2.5-0.1l-2,1l-3,0.1
	l-1.2,0.4l-2,2l-1.5-0.2l-1.5,0.8l-0.9,2.1l-2.4-2l-2,1.6l-0.7-0.1l0.4,1.7l1.4,0.2l1.1,1.2l3.3-0.3l-2.4,2l-0.8,4.5l-1.9,1.2
	l-5,0.8l-1.6,2l0.8,0.7l-0.2,0.6l-2.1,0.2l-1.9-1.2l-2.6,1.3l-0.8-0.3h-0.7l-1.5,1.1l-1.1,1.9l-1.4,0.5l0.1,1.2l2,0.7l0.4,0.8
	l-6.3,1.2l-2.2,1.1l-1.4,0.9l0.1,0.5l1.1,1.6l-0.2,0.4l-2.6,0.7l-0.9,0.8l-4.3,0.4l-1.9,1.9l-0.8,0.2l-0.2-1l-1-0.2l-3.7,0.5
	l0.3-1.3l-0.9-0.9l0.9-1l0.6-1l1.2-0.7l1.8-0.4l0.1-0.6l1.4-1l-5.9,0.4l-3.1,1.6l-1-0.7l-0.7,0.9l1.4,0.6h1.1l-0.7,1.2l-0.9,0.1
	l0.1,0.4l1.8,0.6l-0.1,1l1.3-0.6l0.1,1l-0.6,1l-1.9,1.6l1,0.5l-0.7,3.5l-1.3-0.1l-0.8,1.9l-2.6,0.1l-0.5,1.3l-1.7-0.2l-0.5,1.5
	l-0.8,0.3l-0.8-0.7l-1.5,1.7l-1.1-0.1l0.1-1.4l-0.8-1.5l-0.4,0.8l0.6,1.1l-0.4,0.6l-0.6-0.1l-0.6,2.4l-1.2,1.4l1.4,1.6l-0.7,0.8
	l0.7,1.1l-0.2,0.5l-0.7-0.5l-1.1-1.4l-0.1-1.4l-1.1-0.6l-4.9,0.9l-1.4-1l-1.2-0.6l-0.7,1.1l1.7,0.4l-1.1,1.9l-0.1,2.1l0.2,1.7
	l-0.9,0.9l-2-0.7l-0.4-0.5l-0.6-1l-1.1,0.9l-1.8-1.2l-2.7,0.2l-0.8-0.2l-0.4,0.3l-0.1,1.6l-1.4,0.6l0.2,1.9l-0.6,0.4l-1.5,0.8
	l-1.1-0.7l-1-0.8l-2.8-1.3l-1,0.9l-2.4,0.8l-1.8,0.3l-1.7,0.7l-0.7,0.3l-0.6,0.9l-1.6,0.6l-1.2,0.5l-0.9-0.3l-0.1,1.1l-1.1,0.1
	l-0.5,0.9l0.3,1.1l-1.1,0.7l-0.9-0.2l0.7-0.8l-0.3-0.6l-2.2,1.2l0.4-1.7l-0.8,0.3l-0.8,0.7l-0.6,0.5l-2.8,1.8l-1.2-0.7l-1.1,0.6
	l-0.5-0.6l1.9-1.1l0.3-2l-1.1,0.3l0.4,0.8l-1.1,0.7l-0.4-0.2l-0.4-1.2l-1.2,0.5l-0.4-0.5l-0.4-0.3l-0.4-0.7l1.5-1.2l0.8-3.2
	l-1.3,0.2h-0.7l-2-1.1l0.2,1.2l0.5,0.8l0.7,0.1l-1.3,1.8l-1.2-0.5l-1,0.6l-2.3,1.1l0.2-1.6l-0.9,0.1l-0.4,1.9l-2.6,0.6v0.7l-2.8,0.6
	l-0.2-1.2l-0.5-0.9l-0.1-0.9l-1.1,0.3l0.3,0.8l-0.7,0.4l-2.5,1.1l0.4,2.6l-2.5,0.8l-1.8,1.3l-0.2-0.4l0.8-0.8l-1.2-1.3l-1.1,2.3
	l-1.4,0.1l0.1-1.4l0.7-1l-1.9-0.9l2.6-1.3l2.8-2.7l1.1-0.5l1.9,0.1l2.2-1.9l1.5-0.8v-1.2l3.9-2.4l0.5-1.2l2.1-0.4l0.6-0.6l-0.4-0.8
	l-1.4,1l-1.4-0.2l-2.1,1.6l-1.1-0.2l-1.3,1.7l-0.8-0.2l-1.4,0.4l-0.8,0.9l-1.3,0.1l-0.8,0.6l-1.3,0.4l-0.9,0.9l-1.3,0.1l-1.4,0.9
	h-2.2l4.2-2.8l-0.4-0.5l0.5-0.6l4.1-2l1.3-1.3l6.6-2.9l1.3-1.1l3.8-1l1.4-1.6l1.5-0.1l0.3-0.9l-0.1-0.8l1.3-2.7l-0.6-0.3l-0.4-0.3
	l-1.6,1.6l-1.2-0.2l-0.6-1.1l-1.2-0.2l0.2-1.2l-0.7-1l-0.9,0.2l0.4,0.9l-0.4,0.7l0.5,1l-0.3,1l-1.5,1l-0.9,0.5l-2.3,1.9l-3.2,1.1
	l-0.2-0.8l-2.5,0.2l-0.6-1.7l-0.6,0.7l0.6,1.1l-0.4,0.7l-0.8-0.3l-1.2,0.9l-3,0.8l-0.5-0.5l-2.5,0.6l-1.3,0.4l-1.5-0.5l-0.7,1.7
	l-0.5,0.6v1l-0.7,0.8l-1.7-0.1l-0.2-0.5l-4.6,3.2l-0.9-0.5l-3.5-0.4l-0.5-0.3l-1.6,0.8v-1.2l-0.9-0.4l0.1-0.5l0.9-0.6l1.3,0.8l2-1.2
	l0.7,0.5l1.6-1.3l-0.4-0.7v-1.4l-0.8,0.2l-1.8-0.8l-0.3-0.5l0.8-1.1l1,0.3l0.4-0.6l1.1,0.8l1.5-0.9l1.3,0.5l0.8-1.2l1.7-0.2l0.5-1.8
	l1.1-1l-0.7-0.2l-1.5,0.6l-1.5-0.6l2-1.9l2.9-0.5v-0.4l2.9-1.9L38,828l-0.9,1.8l0.8-0.1l2.3-1.7l-0.6-0.8l0.7-1.2l0.7,0.6l0.8,0.9
	l1.1,0.2l1.5-0.4l-0.5-0.8l-2-0.8l-0.3-0.6l3-1.3l-0.1-1l2-1.2l0.8-1.4l5.5-1.1l1.2-1.4l3.9-1.6l1-1.3l-2.2,1.1l-1.3,0.2l-1.7,0.7
	l-1.5-0.4l-5.5,2.4l-6,2l-0.1,1l-1.3,0.7l-0.5-0.1l0.1-0.8l-1.2-0.6l-1.5,1.3l-0.4,0.4l-2.6,0.3l-4,3l-1.5-0.4l-1.2,0.9l-0.1,0.6
	l-2.7,0.4l-0.1,0.8l-1.3,0.4l-0.5,0.7l-0.6-0.4l1.1-1.5l-1.4-0.2l-0.4-0.7l-0.8,0.4l-2.3-1.5l-0.9,0.2l-0.5-0.4v-0.4l1.3,0.1
	l1.3-0.6l0.6-1.8l-0.4-0.3l0.3-1.9l-2-2l-2.6,0.4l-1.6,2.1l-0.1,1.2l-0.9,0.8l-1.2,0.8l-2.2,1.2l-0.8-1.5l1.2-0.7l-0.3-1.2l0.1-0.9
	l0.4-1.6l-0.3-0.6L5,818.7l0.8-1.6l-0.5-0.4l-0.4-1.5l0.3-0.8h2.7l2.5-1.3l1,0.2l1.6-1.9l1.2,1.5v-1.2l-0.2-0.8l-1.7-1l3.1-2.1
	l-2.1-0.1l-0.7,1.2l-0.6-0.7l0.1-0.6l-2.5-0.1l0.2-0.6l2.3-1.2l1,0.5l0.5-2.8l1.9-0.5l0.8,0.8l0.5-1.4l1.5-1.1l1.7-0.4l1.2-1.2
	l1.2,0.2h1.2l2.1-1.5l2.5,0.2l3.2-1.7l2.8-1.3l1,0.2l-1.2-2l2.4-2.5l2.6-0.7l1.1-1l1,0.2l0.5-0.7l1.6,0.6l-0.7-1.5l0.4-1.4l-0.5-1
	l-0.8,1.1l-0.5-0.8l-9.4,1.9l1.2,0.9l0.5,1.8l0.7,0.4l-0.4,0.7l-1.1-0.7l-1.9-3.2l-3.9,0.6l-2.8,1.5l-0.8-0.3l-2.3,0.8l-1.1-0.2
	l-2.1,1.4l-1-1.5l-0.8,0.5h-1.7l-1.4-2.2l-0.9-0.3l-1.6,1.2l2.9,0.5v0.9l-1.6,0.3l-2-0.4l-0.2-0.7l-2.2-1l-1,1.3l1.9,1.1l-2.4,0.1
	l-4.1,1.1l-0.3-1L0,791.3l1.1-1.3l-0.6-1l0.2-1.3l0.2-1v-0.9l0.3-1.1l-0.6,0.1l-0.1-0.4l4-2.8l-0.4,2.6l1.8,0.2l1,1l0.4-1.5L6.4,783
	l1.5-1.9l-1.2-0.5l0.6-1.4l3.7-1l0.8-1.5l1.6-0.2l0.9-1.3l1.7-0.6l1.1,0.5l0.2-1.1l1.9-0.8l1.3,0.2l-0.4,2.4l0.8,0.4l-2,3.2l1.4-0.8
	l1.3,0.3L22,778l3-0.5l3.1-3.4v-1.3l-1.4-1.4l1.4-0.2l1.2,0.4v0.6l-0.4,3.5l1.4,1.7l2.8,1.9l2-0.2l1.7,0.5l2.1-0.7l1.5-1.3l0.8,0.6
	h1.4l1.4-0.9l1.3,0.7l0.6-0.8l0.8-0.4l-2.3-1.6l-2.3,0.3l-2.3-0.9h-1.4l0.8-1l1.3-0.3l0.6-0.9l-2.2,0.3l-0.3-0.4l1.2-1l1-2.7
	l0.1-4.6l-1.4-0.9l-3.7-0.3l-2.1-1.6l0.4-1.1l2.1-1.1l2.9,0.6l2.3-0.8l3.6-1.7l2.4-2.5l2.5-0.8l0.8-3.2l-0.4-1l0.7-0.8l0.2-0.8
	l-0.6-0.9l3-2.7l0.9-0.4l4.1,0.7h1.1l1.1-0.1l1.5,1.3l1.5-0.1l0.9-1.5h3.5l0.5-0.9l1.5,0.5l0.1,0.9l2.3-0.4l2,0.7l6.1-3.3l2.9-0.7
	l0.5-0.6l2.4-0.1l3.2-2l0.4,1.3l0.6,0.1l0.5-0.8l1.2,0.1l0.4,0.9l1.3-1l0.1-1.2l0.5-0.2l0.7-2l9.2-1.3l1.2,1.6l0.7-1.2l1.7-0.5
	l-0.3-0.6l-6-2.3l-1.5-0.1l-2.6,0.9l-0.3,0.7l-2.1-0.2l-0.3-0.8l2.7-1.7l-1.3-2.2l0.1-1.3l-0.8-0.5l0.7-1.3l-0.9-0.7h-1.1l-3.4,1.9
	l-0.3,0.5l-0.2,2.1l-2.4,3.9l-0.9,0.3l-0.5,1.7l-1.7,1.2l-0.6,1.5l-1.4,1.4l-6.7,0.9l-1,2.2l-0.7,0.3l-2.6,0.2l-0.4-0.6l0.1-0.7
	l3-2.3l0.7-1.5h-0.9l-0.2,0.9l-1.3,0.7l-7.4,1.4l-1-0.7l-2.4-0.1l-1.7-1.2h-1l-0.9-1.1l-0.1-2.5l-0.3-0.5h-0.7l-1,0.7l-0.1,0.8v0.9
	l0.9-0.1l-0.1,1.3l-1.5,0.6l-0.7,0.7l0.1,0.7l-2.4-0.3l-2,0.9l-1.8,0.1l-1.2,2.7l-1.7-0.6l-0.4,0.9l-3.8,1.4l-2.2-0.6l-1.1,0.1
	l-0.4,0.9l-1.5,0.7l-3-0.4l1.1-0.6l0.6-1.2l1-0.5l1.2-1l3.8-1l1.6-2.6l3.5-1.8l0.8-1.6l1.7-0.7l1.3-1.6h1.8l-0.7-1.3l0.7-1.4l1.9-1
	l-1.1-1.2l0.5-0.4l1.2-0.2l0.2-1.1l3.1-0.8l1.7,0.7l1.8-0.4l0.5-1.2l-0.6-0.9l1.2-0.7l-0.6-3.3l2.2-0.9l0.1-1.5l1.5-0.6l-0.2-0.6
	l-1-0.5l0.6-1.8l0.5-1.7l2.3-1.2l1.7-2.1l0.9-0.3l0.3-1.4l-0.9-1.3l-2.4,1.1l-2.3-0.4l-1.8,0.7l-0.6-0.2l0.1-1l2.6-2.5l0.2-2
	l2.4-2.4l-1-1l2.7-3.4l0.5-2.3l3.1-2.2l1.4-2.7l-0.2-1.3l0.6-1.3l1.3-1.1l2.6,1.5l2.4,1l1.7,1l0.7-0.1l0.9-0.9l2.4-1l0.9-0.1
	l-0.9-0.8l-2,0.2l-0.9-0.6l2.2-0.9l1.8,0.4l1.1-0.3l3,1.8v-1l-2.1-0.9l-0.3-0.6l1.5-0.5l2,0.2l1.4-0.8l0.1,1l-1.5,0.6l2.1,0.8
	l1.5,0.8l-0.3-2l-0.9-0.8l-0.1-0.5v-1.5l3.5-2.9l-0.7-0.2l-1.1,1l-1.3,0.3l-2.8-0.1l-1.6-0.4l1.1-0.7l2.1-2l-1.6-0.7l0.9-1l2.4-0.3
	l0.4-1.2l-0.9-0.2l-1.7,0.3v0.8h-1.4v-0.9l-1.4,0.4l-2.4,0.4l-4.1,1.3l-2.8,0.5l-3.4,0.1l-7.3,0.7l-1.9-0.4l-2.4,0.9h-3.2l-0.9,0.8
	l-1.6,0.1l-1.9,0.3l-2-1.8l-0.2-1.3l-0.6-1.3l1.8-1.5l-0.9-1.4l-0.8,0.5l-0.6,0.8l-0.5,0.8l0.6,1.1l-0.7,2.4l-0.3,0.3l-1.4,0.2
	l-0.8-0.7l-0.3-1.3l2.1-3l-0.3-1l-0.4-0.6v-1.7l-0.9-0.9l0.3-0.7l1.9-0.3l1.4,0.5l1.2-2.3l-1.5-0.7h-0.7l-0.6-2.2l-0.5-0.4l-0.2-0.7
	l-0.4,0.4l0.8,1.3v2.2l0.1,1l-2.8,0.5l-1.4-0.9l0.9-0.4l0.5-0.5l-0.2-0.6l1.2-0.8l-0.6-1.3l0.5-0.8l-0.2-0.7l-1.5,0.6l-0.5,1.2
	l-1,0.5l-1,0.9l-0.2,0.6l-1.4,2.6l-1,0.6l0.2,1.5l-2.5,0.7l-1.1-0.5l-1.3-0.3l-0.3-0.1l-1,0.3l-0.6,0.2l-0.5,0.5l-1.5-0.5l-0.4-0.8
	l-0.9-0.7l-0.6-0.7l0.5-1.6l0.7-0.9l0.5-1l-0.5-0.2l-0.1-0.9h1.5l2-1.2l0.7,0.2l1-0.8l1.4-0.6l-0.8-0.1l-0.1-0.5l1-1l-1.8,0.5
	l-0.6-0.6l0.2-0.5l1.3-0.1l-0.1-0.4h-1.6l-1.3,1.2l-0.8,0.3l-0.4,1.6l-1-0.6l-0.3-2l-0.7-0.9l-1.2,0.7l-0.2,2.8l-1.5,1l-0.7-0.4
	l-0.7,0.2l-0.3-0.5l-2.6,0.4l-1.2-0.5l-0.5-2.5l-1.6-0.7l-1-1.2l-0.5,1.2h-0.8l-0.4,0.6l-0.9,0.9l-1.9-2.1l-2.1,1.2l1.8-3.5l1.2-1.5
	l2.3,0.7l0.5,0.4l1,0.8l1-2.1l-0.5-0.6l-1-1.1l-1.2-0.7l1-0.8l-0.4-0.9l-1.1,0.1l-1.2-0.7l-0.6-0.7l-0.2-0.6l0.3-0.8l-0.7-2l-2,0.3
	l-1.4-0.5l0.6-0.6l-0.1-0.6l1.7-0.7l0.9-0.4l1,0.8l0.6-0.3l-0.3-1.2v-0.4l1.6-0.1l0.5,0.5l1.2-0.2l0.7,0.8l1.1-0.5l2,0.5l0.2,0.5
	l-0.6,0.4l0.9,1.3l1.5-0.1l0.7-0.3l1-0.3l-1.5-0.8v-0.8l-2.9-1.1l-1.4-1.7l-0.3-1.7l1.8,0.4l1.5-0.5l1.9,1.1l2.1,0.3l1.5-0.4
	l0.3-0.6l0.4-0.8l1.6-0.2l-0.3-0.9l0.7-0.6l-0.5-2l-2.2-0.4l-0.2-0.5l0.5-2.1l-0.8-2l1.6-4.4l-0.3-1.2l-0.6-0.4l0.2-1.1l5.2-0.7
	l2.5-1.2h0.7l0.3,0.7l3.1-0.1l3.5-1.3l0.3,0.7h1l0.3-0.7l1.5,0.5l1.9,0.2v-0.5l0.3-0.7l2.2-1l-1.4-0.1h-2l-1.8-1.3l-0.2-0.8l0.5-1
	l0.5-0.5l-0.4-0.9l1-0.7l1.5-1.2l-1.5-0.5l1.2-0.5l-0.2-0.3l-0.6-0.4l0.5-0.2l2-1l-1.4-0.4l-0.7,0.5h-0.4l-0.8-0.4l-0.8-0.4h-0.5
	h-1.5l-0.9-0.1h-1l-2.5,0.1v-0.5l-1.5-0.2v1l-2.6-1.1l-0.5,0.6l-1.2,1.8l-3.3,1.4l-2.3,0.4l-1.3-0.9l-0.4-0.8l0.3-1.3l-0.3-2.4
	l0.9-0.5l0.9-1.7l-1-1.1l0.5-1.4l0.9,0.7l0.7-0.8l2.4,0.1l0.9,1l0.9,0.3l0.8-0.5l0.1-1.1l0.3-1.5l-1-0.1l0.1,1.2l-0.6,1.5l-0.7-1.9
	l-1.3,0.2l0.2-2.9l-0.5-0.8l-1.6-1.1l0.2-0.7l-1-0.8l-0.8-2.2l1-0.8l-0.3-2.1l-2.1,1.1l-1.3-0.5l-1.4,0.5l0.3-2.1l-1.4-2.2l3.1-0.8
	l0.6-1.1l0.6,0.3l0.5-0.5l-0.2-1l-0.6-0.3l-0.5,0.9l-1.6-1.7l0.1-1.1l1.8,0.7l0.4-0.7l-0.9-0.5l-1-1.2l1.1-1.2l0.7-1.2l0.5-0.6
	l-2-0.3l-0.3,0.4l-2.1-1.9l-0.3-1l4,1.2l3.2-0.6v-0.8l-1.1-0.3l0.5-0.5l0.3-1.2l0.7-3l3.2-1.2l0.8,0.5l0.2-1.6l-2.4-0.8l-1.4-0.3
	v-1.7l0.4-0.7h1.2l1.8-1.8l1.7-0.1l1.3,0.4l1.2,1.2l2.2,0.4l1.1,0.6l1.7,0.4l1.4-1.2l3.9,0.2l0.9,0.3v0.8l2.7,0.4l0.9-0.2l0.3-0.9
	l1.3,0.2l1.7,1.1l1.3-0.5l2.4,0.3l0.1,1.3l0.6,0.2l2.3-2.7v-0.9l1.4,1.2l3.7,0.8l0.9,1.3l0.2,1.9h0.9l0.8-1l0.6,1.3l-0.9,3.2h1.6
	l-0.2,1.8l-0.9,0.7l-0.7,0.7l4,1.7l-0.4,1.1l1.3,0.7l0.5,0.7l0.8-0.3l-0.2-1.7l-0.5-1l2.5-1l-0.1-1.1l2.7-5.2l-0.3-0.8l0.5-1.5
	l7.6,0.2l1.5,0.8l1.1,1.4l1.8,1.1l0.4-0.3l1.9,1.3l1.9-0.9l2-2l0.5,0.3v1l1.6,0.5l2.7-1h1.3l2.4,2.6l1.6,2.7l1.3,1.6h0.5l0.1-1
	l3.4-0.3l-1.6-1l-1.7-0.4v-0.8l-2-2.2l-1.3,0.6l1.1-2.2l-0.3-0.9h1.4l0.1,0.7l1.2,0.2l1.5-0.5l1.7,0.3l0.8-0.6l1,0.5l0.7-0.5
	l-2.8-2.1l-3.2-0.4v-3.2l1.2,1.7l2.2,0.7l1-0.5l0.1-1.1l-1.9-0.3l-0.6-0.5l-3.2-0.6l-1.6,0.9l-1.8,0.4l-0.5-1.2l-1.4-0.7l0.2-0.2
	l0.2-0.6l2.2-1l1.2,0.2l0.5-1.2l2.9-1.3l1.3-0.7l3.7-2.2l0.1-0.9l1.2-1.3l-0.1-1l-0.9-0.5l1.2-1l-0.1-0.9l0.9-0.3l0.2,1.6l1.3,0.3
	l1.9-0.9l4-0.7l1.4-0.9l1.8,0.1l-0.1-1l1.1-0.5l0.6-2.2l-0.7-1.6l3.5-2.4l-0.7-2.3l0.2-0.4l0.9,0.1l2.5-2.5v-3.1l1,0.4l0.1,2.1
	l0.8-0.2l0.2-2l1.4-1.1l-0.9-0.4l0.8-1.9l-1.5,0.4l-1.9,0.7l-1.2,0.3l-3,2.4l-4,1.8l0.4-2.2l1.2-1.5l-1-1.2l-3.4,1.8l-0.6,1.6
	l-2.5,2.3l-2.4,0.8l-0.7,0.7l0.1,0.7l-1.4,0.8l1-1.5v-0.8l3-1l1.3-1.6l-0.7-1.8l-0.4-0.2l-1.8,1.4l-1.1,0.2l1.2-3.6l-0.5-0.1
	l-1.2,1.7l0.4,0.7l-0.3,0.8l-1.8,1.9l-0.6-0.1l0.2-1l-0.4-0.3l0.6-0.8l-0.2-1.2l-3.5,1.9l-2.7,0.6l-0.5-0.3l-2.2-0.2l-0.7-0.5
	l-3.1,0.4l-0.3-0.9l0.5-0.5l-1-1.1l-5.1-1.6v-0.9l-1.2,0.5l0.6-3.2l-0.6,0.1l-0.7-0.6v-0.9l0.5-0.4l1,0.5l1.2-0.8l1,0.2l0.9-0.5
	h-1.1v-1.7l0.6-0.7l-0.3-0.4l2.5-0.6l0.2-1l0.9-0.9l1,0.2l0.4-1.1l4.6-0.9l3.7,1.5l1.3-0.6l0.2,0.7l-0.6,0.1l0.4,0.3l1.1-0.3
	l1.8,0.7l2.3-0.1l-2.1-0.2l-0.3-0.8l-1.1-0.6l-2.4-0.2l-1.4-1l0.1-0.7l1.9,0.6l2.7-0.7l0.2,1h0.6l0.5,1.2l1.8,0.8l0.6-1.3l-1.7-0.3
	l-0.4-1.4l-0.3-0.4l-0.4-0.6l-2.6-0.1l0.1-0.9l-2-0.8l0.3-0.9l-2.5-0.3l1.5-1l1.9,0.8l0.4-0.5l-0.3-0.9l1-0.9l2.8,0.9l1.4-1.3l1,0.5
	l0.7,0.3l0.9,0.3l0.3-1.1l-0.6-3l1.1,0.3l1.2-0.8l1.8,1l-0.2-0.7l1-0.8l-2.1-0.4l-0.3-1.8l-1.1,0.9l-1.1-0.6l0.4,1.7l-0.6,0.5
	l-1.6-1.6l-2.1-0.7l-0.9-0.8l-0.1-1.2l0.7-0.1l0.2-0.7l-0.9-0.6l0.2-0.9l1.2-0.1l0.1,0.6l-0.1,0.4l0.3,0.2l1.5-0.1l1.4-0.9l0.4,0.5
	l1-1l-3.2-1.2l-0.3-1l-1.7-1.7l0.5-0.4l-1.1-1l0.2-0.8l1.8-0.6l0.1,0.7l1.2,0.4l-0.5-1.4l1-1.1l-0.8-0.9l0.4-0.8l0.5-0.1l0.6,1
	l0.9-0.9l1.1,0.2l0.4-0.9l-0.5-1.3l1.6-1l0.4-1l-0.4-1.1l0.7-0.5l-0.1-2l1.3-1.5l-0.3-1.5l0.7-0.7l-0.4-0.6l0.7-0.9l3.4,1.1l0.8-0.4
	l0.5,0.7l2.9,0.2l0.9-0.6l0.3-1.4h0.8l0.3,0.8l-1.9,1.4l-0.7-0.1l0.9,0.5l-0.4,1.4l0.6,0.2l0.5-0.8l0.5,0.4l-0.1,0.8l0.4-0.5
	l-0.3-1.9l0.9,0.5l0.3-1.6l-0.4-0.7l3.4-0.6l1.3-1.3l0.9,0.4l0.7-0.3l0.5-1.1l-0.6-0.5l0.7-0.8l-0.7-0.2l0.4-1.7l1.6-0.3l0.7-1
	l1,1.5l1.6-0.2l0.2,0.6l-0.3,1.3l-1,0.7h-0.9l-0.5,0.9l1.6-0.6l1.6,1.1l1.2-1.4l1.1,0.4l-0.3,1.3l1.4,0.3l-2.2,2l2.5-1.4l1.2,0.5
	l-0.7,1.5l-1.6,0.4l-1,1.5l1.2-0.7l0.7,0.2l0.1-0.6l0.7,0.9l-0.2,0.9l0.8-0.1l-0.3-1.2l1.3-1.1l-0.6-0.5l1.1-1.1l0.4-1.5l-0.8-1.7
	l-0.8,0.5l-1.4-1.3l0.4-3.1l0.5,0.4l1-0.6l0.9,0.7l0.6-0.1l-0.2-1.3h0.9l0.2-1.4l0.9,0.4l0.1,2.4l-1.1,0.7l-0.1,1.5l-0.6,0.7l1,1.2
	l-0.3,1.1h0.6v-0.7l0.5,0.5l0.1-0.5l1.1,0.2l0.3-0.6l0.7,0.8l-0.2,0.5l0.8,0.4l0.1-0.9l0.8,0.6l1.1,1l1.4,2.6l-1.6,4.8l0.9-0.8
	l0.2,0.9l0.6-0.6l-0.3-0.8l1.6-5.2l-0.9-0.8v-2l-1,0.6l0.1-1.5l-0.4,0.4l-0.8-0.8l0.2-0.7l0.9,0.6l-0.7-2.2l0.4-0.9l-0.5-0.5l-0.9,1
	l0.2,1.7h0.5l-0.6,0.7l0.2,1.2l0.8,0.6l-0.4,1.4l-2.1-1.9l-0.2-0.6l0.7,0.3l0.2-0.7L171,465l-1.4,0.8l-0.9-1.5l-0.8-0.1l-0.2-1
	l2.5-2.5v-0.4l0.6-0.8h0.5l0.6-0.6l0.4,0.9l1.5-0.1l0.6-0.8l-0.1-1l0.8,0.2l1.5-1.5h1.5l-0.2,0.5l0.5,0.2l1.5,4l-0.3,1.7l-1.1,1.5
	l1.4,1.8l0.7-0.2l1.2,0.9l-0.2,1.2l2.2,3l1.2,0.5l0.2,1.6l-0.8,0.9l0.3,1.2l-0.5,1.1l-3.1,1.4l-0.8,0.9v1.3l-1.7,0.4l-1,1.2l0.1,0.8
	l0.9-0.8l1,0.3l1.9-1.2l0.6,0.2l0.5,1.2v0.9l-1.4,1.6l-3.6,2.5l-1.1,3l-1.6,1v0.9l0.4-0.6l2.2-0.6l0.4-0.8l0.8,0.8l1.7-3.3l3.6-3.3
	l0.8,0.4l0.6-0.5l0.2-1.8l1.1,0.1l0.9-0.7l0.5,0.4l1.2-0.2l0.6-0.7l-0.8-2.9l-1.4-1.5l0.1-1.3l1.3-2.1l-0.3-1.9l-0.8-0.1l-0.8-1.9
	l-1-0.6l-1.1-2.5l-2.2-1.2l0.9-0.7l-0.6-0.5l0.8-2.3l1.1-1.1l-0.9-1l1.2-0.8l0.4-1v-0.7l-1-1.1l0.3-0.6l1.1-0.4l2.2,1l0.2-1.6
	l0.9,0.6v0.8l1.4,0.3l-0.3-0.8l1-0.5h2.2l0.5-2.5l0.7-0.3l1.7,0.6l0.7,1.1l0.7-0.1l0.6,1.1l-0.6,0.6l-2.1-0.3l-0.1,0.7h2l1.8,1.2
	l1-0.8l0.4,0.6l1.2-0.2l-1.3-1.2l0.9-0.6l-1.5,0.2l-3.4-2.1l-0.3-1.5l-1.1-0.8l0.3-2.9l-1.5-0.2l-1.2-1.7l-0.5,0.1l1.7-0.9
	L195.4,444.2L195.4,444.2z"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}
