import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "./button";
import { AppContext } from "../config/context";

const GA_ID = "UA-72689622-61";
const script = document.createElement("script");
script.setAttribute(
  "src",
  `https://www.googletagmanager.com/gtag/js?id=${GA_ID}`
);
let scriptAppended = false;

function deleteAllCookies() {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;path=/;domain=${window.location.host};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  }
}

export default function CookieNotice() {
  const { openCookieSettings, setOpenCookieSettings } = useContext(AppContext);
  const [cookie, setCookie] = useState(() => {
    return JSON.parse(localStorage.getItem("cookie"));
  });

  const accept = useCallback(
    (accepted) => {
      return () => {
        const cookie = { accepted };
        localStorage.setItem("cookie", JSON.stringify(cookie));
        setCookie(cookie);
        setOpenCookieSettings(false);
      };
    },
    [setCookie, setOpenCookieSettings]
  );

  useEffect(() => {
    const accepted = cookie?.accepted ?? false;
    if (accepted) {
      if (!scriptAppended) {
        document.head.appendChild(script);
        scriptAppended = true;
      }
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", GA_ID);
    }
    window[`ga-disable-${GA_ID}`] = !accepted;
    if (!accepted) deleteAllCookies();
  }, [cookie]);

  return (
    <div
      className={`cookie-notice ${
        cookie === null || openCookieSettings ? "open" : "closed"
      }`}
    >
      <h5>Your Data. Your Choice.</h5>
      <p>
        We use Google Analytics cookies to measure and analyse use so that we
        can improve our website and services. Learn more about this in our{" "}
        <Link to="/cookies-policy">Cookie Policy</Link> and about the related
        use of your personal data in our{" "}
        <a
          href="https://www.mcdonalds.com/gb/en-gb/privacy-policy.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Statement
        </a>
        . Click “Accept Cookies” to agree to the use of these technologies and
        your personal data, or "Reject All" if you do not agree to such use.
        Change or withdraw your consent at any time via the Cookie Settings link
        at the bottom of the page.
      </p>
      <div className="buttons">
        <Button onClick={accept(false)}>Reject All</Button> &nbsp;&nbsp;&nbsp;{" "}
        <Button onClick={accept(true)}>Accept Cookies</Button>
      </div>
    </div>
  );
}
